import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from "../../component/shared/LoaderComponent";
import {Link, useParams} from 'react-router-dom'
import Network_B2B from "../../library/Network_B2B";
import {Button, Container, Item, Segment} from "semantic-ui-react";

const BookCategoryGroupDetailContainer = (props) => {
  const {bookCategoryGroupId} = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [state, setState] = useState({});
  const [bookCategoryGroup, setBookCategoryGroup] = useState({});
  const [books, setBooks] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    _load();
    window.scrollTo(0, 0)
  }, [bookCategoryGroupId])

  const _load = async () => {
    const res = await Network_B2B.getBookCategoryGroup(bookCategoryGroupId);
    if (!res.err) {
      setIsLoaded(true);
      setBookCategoryGroup(res.bookCategoryGroup);
      setRefreshing(false);

      if (res.bookCategoryGroup.bookCategories?.length > 0) {
        _loadBooks(res.bookCategoryGroup.bookCategories[0].id);
      }
    }
  };

  const _loadBooks = async (bookCategoryId) => {
    setLoading(true);
    const res = await Network_B2B.getBookListBookCategoryBestSeller(bookCategoryId);
    if (!res.err) {
      setBooks(res.books);
      setLoading(false);
    }
  };

  const _handleChangeTab = async (tabIndex) => {
    if (tabIndex === selectedTabIndex) return;
    setBooks([]);
    setSelectedTabIndex(tabIndex);
    _loadBooks(bookCategoryGroup?.bookCategories[tabIndex].id);
  };

  if (!isLoaded) return <LoaderComponent/>

  return (
    <>
      <Container as={Segment} vertical basic text style={{paddingTop: '3em'}}>
        {
          bookCategoryGroup?.bookCategories?.map((bc, i) => {
            return (
              <Button
                onClick={()=>_handleChangeTab(i)}
                key={i} basic={i !== selectedTabIndex} primary>
                {bc.aladinCategoryName}
              </Button>
            )
          })
        }
      </Container>
      <Container as={Segment} vertical basic text>
        <Item.Group>
          {
            books.map((b, i) => {
              return (
                <Item key={i}>
                  <Item.Image src={b.coverImageUrl} size={'tiny'}/>
                  <Item.Content>
                    <Item.Header>{b.title}</Item.Header>
                    <Item.Meta>{b.author}</Item.Meta>
                    <Item.Meta>{b.publisher} ({b.pubDate})</Item.Meta>
                    <Item.Extra>
                      <Button floated={'right'} content={'보기'} primary basic as={Link} to={`/book/${b.id}`}/>
                    </Item.Extra>
                  </Item.Content>
                </Item>
              )
            })
          }
        </Item.Group>
      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BookCategoryGroupDetailContainer);
