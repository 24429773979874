import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import ChannelService from "../../library/ChannelService";

const EventHashTagDetailContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});

  useEffect(() => {
    ChannelService.shutdown();
  }, []);

  return (
    <>
      <div style={{
        backgroundColor: "#FFF3D6",
      }}>
        <div style={{
          maxWidth: "500px",
          margin: "auto",
        }}>
          <img
            width={"100%"}
            src={"https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/temp%2Fbiz-image%2FeventHashTag.jpg?alt=media&token=2422bd70-443b-4219-80d1-3a9dbf93131d"}/>
        </div>

      </div>

    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(EventHashTagDetailContainer);
