import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { authActionCreators } from "../../redux/auth/authStore";
import { useQuery } from "../../library/useQuery";
import Network_B2B from "../../library/Network_B2B";
import { Button, Card, Container, Divider, Form, Grid, Header, Image, Item, List, Loader, Message, Modal, Radio, Rating, Segment, Table } from "semantic-ui-react";
import numeral from "numeral";

const BookForRequestContainer = (props) => {


  const { bookId } = useQuery(props);

  const [isLoaded, setIsLoaded] = useState(false);
  const [userAddressSearchModalOpen, setUserAddressSearchModalOpen] = useState(false);
  const [userAddressSelectModalOpen, setUserAddressSelectModalOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [book, setBook] = useState({});
  const [searching, setSearching] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [userAddress, setUserAddress] = useState({});
  const [userAddressList, setUserAddressList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [showDetailInput, setShowDetailInput] = useState(false);
  const [newUserAddress, setNewUserAddress] = useState({});

  const [selectedBusinessBookRequestTypeId, setSelectedBusinessBookRequestTypeId] = useState(null);

  useEffect(() => {
    _load();
  }, [bookId]);

  const _load = async () => {
    const res = await Network_B2B.getBookForBusinessBookRequest(bookId);
    if (!res.err) {
      setIsLoaded(true);
      setBook(res.book);
      setUserAddress(res.userAddress);
    }
    _loadUserAddressList();
  };
  const _handleOrder = async () => {

    if (!selectedBusinessBookRequestTypeId) return window.alert("신청 방법을 선택해 주세요.");
    if (!userAddress.id) return window.alert("주소를 입력해 주세요");

    setFetching(true);

    const res = await Network_B2B.postBusinessBookRequest({
      selectedBusinessBookRequestTypeId,
      bookId,
      userAddress,
      productId: book?.product?.id,
    });
    if (!res.err) {
      window.alert("신청이 완료되었습니다.");
      props.history.replace(`/book-request/${res.businessBookRequest?.id}`);
    } else {
      setFetching(false);
    }
  };

  const _loadUserAddressList = async () => {
    const res = await Network_B2B.getUserAddressList();
    if (!res.err) {
      setUserAddressList(res.userAddresses);
    }
  };
  const _validatePhone = (phoneNumber) => {
    return /^[0-9]*$/.test(phoneNumber);
  };
  const _handleSearch = async (e) => {
    e.preventDefault();
    setSearching(true);
    setNoResult(false);
    setAddressList([]);

    const { err, addressList } = await Network_B2B.getUserAddressSearch(searchKeyword);

    setSearching(false);

    if (!err) {
      setAddressList(addressList);
      if (addressList.length < 1) {
        setNoResult(true);
      }
    }
  };
  const _handleUserAddressMakeOrModify = async () => {
    if (!_validatePhone(newUserAddress.phoneNumber)) return window.alert("전화번호는 숫자로만 입력해 주세요");

    if (!newUserAddress.zoneNo) return window.alert("주소를 입력해 주세요");
    if (!newUserAddress.name) return window.alert("이름을 입력해 주세요");
    if (!newUserAddress.phoneNumber) return window.alert("전화번호를 입력해 주세요. 숫자로만 입력해 주세요");

    setFetching(true);
    if (!newUserAddress.id) {
      // 기존의 주소일 때,
      const res = await Network_B2B.postUserAddress(newUserAddress);
      if (!res.err) {
        setFetching(false);
        window.alert("주소가 등록 되었습니다.");
        setUserAddressSearchModalOpen(false);
        setShowDetailInput(false);
        setNewUserAddress({});
        setSearchKeyword("");
        _loadUserAddressList();
      } else {
        setFetching(false);
      }
    } else {
      // 새로운 주소일 때,
      const res = await Network_B2B.putUserAddress(newUserAddress);
      if (!res.err) {
        setFetching(false);
        window.alert("주소가 수정되었습니다");
      } else {
        setFetching(false);
      }
    }
  };

  const _handleOpenUserAddressModal = () => setUserAddressSelectModalOpen(true);
  const _handleCloseUserAddressModal = () => {
    setUserAddressSelectModalOpen(false);
    setShowDetailInput(false);
    setNewUserAddress({});
    setSearchKeyword("");
  };
  const _handleSelectUserAddress = (ua) => {
    setUserAddress(ua);
    _handleCloseUserAddressModal();
  };


  if (!isLoaded) return <Loader active/>;

  const _renderUserAddressAddModal = () => {
    return (
      <Modal
        size={"small"}
        dimmer={"blurring"}
        open={userAddressSearchModalOpen}>
        <Modal.Header>주소 추가하기</Modal.Header>
        <Modal.Content style={{ minHeight: 400 }} scrolling>
          {
            !showDetailInput && (
              <>
                <Form>
                  <Form.Input
                    value={searchKeyword}
                    placeholder={""}
                    action
                    onChange={(e, { value }) => setSearchKeyword(value)}>
                    <input/>
                    <Button content={"검색"} onClick={_handleSearch}/>
                  </Form.Input>
                </Form>
                <Divider/>
              </>
            )
          }

          {
            ( searchKeyword.trim() === "" ) && ( !showDetailInput ) && ( addressList.length < 1 ) && (
              <p>
                주소 검색 tip
                <br/>
                - 도로명 + 건물번호 (예, 역삼로 180)
                <br/>
                - 동/읍/면/리 + 번지 (예 삼성동 111-1)
                <br/>
                - 건물명, 아파트명 (예 하이브로 빌딩)
              </p>
            )
          }
          <List bulleted>
            {
              addressList.map((item, index) => {
                return (
                  <List.Item key={index}
                             onClick={() => {
                               setNewUserAddress(item);
                               setShowDetailInput(true);
                               setAddressList([]);
                             }}
                             style={{ cursor: "pointer" }}>
                    [{item.zoneNo}]<br/>
                    도로명<br/>
                    {item.roadAddressName}<br/>
                    지번<br/>
                    {item.addressName}
                  </List.Item>
                );
              })
            }
          </List>
          {
            !!showDetailInput && (
              <>
                <Form>
                  <Form.Input
                    disabled
                    label={"우편번호"}
                    value={newUserAddress.zoneNo}
                  />
                  <Form.Input
                    disabled
                    label={"도로명주소"}
                    value={newUserAddress.roadAddressName}
                  />
                  <Form.Input
                    disabled
                    label={"지번주소"}
                    value={newUserAddress.addressName}
                  />
                  <Form.Input
                    label={"상세주소"}
                    value={newUserAddress.addressDetail}
                    onChange={(e, { value }) => {
                      setNewUserAddress(prevState => ( { ...prevState, addressDetail: value } ));
                    }}/>
                  <Form.Input
                    label={"받는사람 이름"}
                    value={newUserAddress.name}
                    onChange={(e, { value }) => {
                      setNewUserAddress(prevState => ( { ...prevState, name: value } ));
                    }}/>
                  <Form.Input
                    label={"전화번호"}
                    value={newUserAddress.phoneNumber}
                    type={"number"}
                    onChange={(e, { value }) => {
                      setNewUserAddress(prevState => ( { ...prevState, phoneNumber: value } ));
                    }}/>
                </Form>
              </>
            )
          }
        </Modal.Content>
        <Modal.Actions>
          {
            !!showDetailInput && (
              <Button content={"추가"} onClick={() => _handleUserAddressMakeOrModify()}/>
            )
          }
          <Button content={"닫기"} onClick={() => setUserAddressSearchModalOpen(false)}/>
        </Modal.Actions>
      </Modal>
    );
  };
  const _renderUserAddressSelectModal = () => {
    return (
      <Modal
        dimmer={"blurring"}
        open={userAddressSelectModalOpen}>
        <Modal.Header>
          주소 선택
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{ minHeight: 300 }}>
          <Card.Group itemsPerRow={3}>
            {
              userAddressList.map((ua, i) => {
                return (
                  <Card key={i}>
                    <Card.Content>
                      {ua.name}
                      <Card.Meta>
                        {ua.phoneNumber}
                      </Card.Meta>
                      <Card.Meta>
                        {ua.roadAddressName} {ua.addressDetail}
                      </Card.Meta>
                    </Card.Content>
                    <Card.Content extra>
                      <Button content={"선택"} basic onClick={() => _handleSelectUserAddress(ua)}/>
                    </Card.Content>
                  </Card>
                );
              })
            }

          </Card.Group>

        </Modal.Content>
        <Modal.Actions>
          <Button content={"주소 추가하기"} icon={"plus"} floated={"left"} onClick={() => setUserAddressSearchModalOpen(true)}/>
          <Button content={"닫기"} basic onClick={_handleCloseUserAddressModal}/>
        </Modal.Actions>
        {_renderUserAddressAddModal()}
      </Modal>
    );
  };

  const pointPossible = props.authReducer?.user?.businessPoint?.left >= book.product?.price;
  const voucherPossible = ( props.authReducer?.user?.businessBookVoucher?.numberOfLeft > 0 ) && ( book.product?.price < 30000 );

  return (
    <>
      {_renderUserAddressSelectModal()}
      <Segment vertical basic>
        <Container>
          <Grid>
            <Grid.Row style={{ minHeight: "100px" }}>
              <Grid.Column width={4} style={{}}>
                <Image src={book.coverImageUrl} size={"tiny"} style={{}} floated={"right"}/>
              </Grid.Column>
              <Grid.Column width={12}>
                <Header>
                  {book.title}
                </Header>
                <p>
                  {book.author} <br/>
                  {book.publisher} {book.pubDate}

                </p>
                <p>
                  {numeral(book.product?.price).format("0,0")}원
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
      <Segment vertical basic>
        <Container text>
          <Divider/>
          <Grid>
            <Grid.Row textAlgi>
              <Grid.Column width={4}>
                <Header content={"신청 방법"}/>
              </Grid.Column>
              <Grid.Column width={12}>
                <Form>
                  <Form.Field>
                    <Radio
                      disabled={!voucherPossible}
                      label={`도서 신청권 사용 - 남은 바우처 수 : ${props.authReducer?.user?.businessBookVoucher?.numberOfLeft}개`}
                      name='radioGroup'
                      checked={selectedBusinessBookRequestTypeId === 1}
                      onChange={(e, { value }) => {
                        setSelectedBusinessBookRequestTypeId(1);
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      disabled={!pointPossible}
                      label={`포인트 사용 - 보유 ${numeral(props.authReducer?.user?.businessPoint?.left).format("0,0")}P`}
                      name='radioGroup'
                      value={2}
                      checked={selectedBusinessBookRequestTypeId === 2}
                      onChange={(e, { value }) => {
                        setSelectedBusinessBookRequestTypeId(2);
                      }}
                    />
                  </Form.Field>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
      <Segment vertical basic>
        <Container text>
          <Divider/>
          <Grid>
            <Grid.Row textAlgi>
              <Grid.Column width={4}>
                <Header content={"배송 정보"}/>
              </Grid.Column>
              <Grid.Column width={12}>
                {
                  !userAddress?.id ? (
                    <>
                      <p>
                        배송 받을 주소를 선택해 주세요.
                      </p>
                      <Button
                        onClick={_handleOpenUserAddressModal}
                        primary
                        basic
                        content={"주소 검색"}
                      />
                    </>
                  ) : (
                    <>
                      <p>
                        {userAddress.name}
                      </p>
                      <p>
                        {userAddress.phoneNumber}
                      </p>
                      <p>
                        {userAddress.roadAddressName} {userAddress.addressDetail}
                      </p>
                      <Button
                        onClick={_handleOpenUserAddressModal}
                        primary
                        basic
                        content={"주소 변경"}
                      />
                    </>
                  )
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid textAlign={"center"} columns={1} style={{ marginTop: 100 }}>
            <Grid.Row style={{}}>
              <Grid.Column style={{
                alignItems: "center",
                maxWidth: "500px",
              }}>
                <Button
                  onClick={_handleOrder}
                  disabled={fetching}
                  loading={fetching}
                  size={"large"} fluid primary>
                  신청하기
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

      </Segment>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {
    ...authActionCreators,
  },
);

export default enhance(BookForRequestContainer);
