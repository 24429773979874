import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import UserComponent from "../user/UserComponent";
import ReadMoreComponent from "../shared/ReadMoreComponent";
import Colors from "../../constant/Colors";

const CommentComponent = (props) => {


  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});

  const { comment } = props;

  const _handleGoToUserDetailScreen = () => {
    // props.navigation.push("UserDetailScreen", {
    //   userId: null,
    // });
    history.push(`/user/${comment?.user?.id}`);
  };

  const _deleteComment = (commentId) => {
    props?.deleteComment?.(commentId);
  };
  const _reportComment = (commentId) => {
    props?.reportComment?.(commentId);
  };
  const _handleReply = (commentId) => {
    props?.handleReply?.(commentId);
  };

  if (!comment) return null;
  switch (props.viewType) {
    case "INLINE" :
      return (
        <div style={{ marginBottom: "1rem" }}>
          <UserComponent user={comment.user}>
            <div style={{
              display: "flex", flexDirection: "row", alignItems: "center",
              cursor: "pointer",
            }}>
              <div style={{
                width: "30px",
                height: "30px",
                backgroundImage: `url(${comment.user?.photoUrl})`,
                backgroundColor: "grey",
                backgroundRepeat: "no-repeat",
                backgroundSize: "30px 30px",
                backgroundPosition: "center",
                borderRadius: "15px",
                marginRight: "1rem",
              }}/>
              <div>
                <p style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                  {comment?.user?.name}{" | "}
                  <span style={{ color: "grey", fontWeight: "400" }}>{comment?.user?.businessDepartment?.title}</span>
                  <p style={{ color: "grey", fontWeight: "400" }}>
                    {moment(comment.createdAt).format("YYYY.MM.DD(ddd) HH:mm")}
                  </p>
                </p>
              </div>
            </div>
          </UserComponent>
          <ReadMoreComponent
            lines={2}
            showLess={false}
            style={{ marginTop: "0.5rem" }}
            children={comment.comment}
          />
          <div style={{ display: "flex" }}>
            <div
              onClick={()=>_handleReply(comment.id)}
              style={{ cursor: "pointer", color: "grey", fontSize: "0.8rem" }}>
              댓글
            </div>
            {
              comment?.userId === props.authReducer?.user?.id ? (
                <div
                  onClick={_deleteComment}
                  style={{ cursor: "pointer", color: "grey", fontSize: "0.8rem", marginLeft: "1rem" }}>
                  삭제
                </div>
              ) : (
                <div
                  onClick={_reportComment}
                  style={{ cursor: "pointer", color: "grey", fontSize: "0.8rem", marginLeft: "1rem" }}>
                  신고
                </div>
              )
            }

          </div>
          {
            comment.cocomments?.map((cocomment, i) => {
              return (
                <div key={i} style={{ paddingLeft: "2rem", marginTop: "1rem" }}>
                  <UserComponent user={cocomment.user}>
                    <div style={{
                      display: "flex", flexDirection: "row", alignItems: "center",
                      cursor: "pointer",
                    }}>
                      <div style={{
                        width: "30px",
                        height: "30px",
                        backgroundImage: `url(${cocomment.user?.photoUrl})`,
                        backgroundColor: "grey",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "30px 30px",
                        backgroundPosition: "center",
                        borderRadius: "15px",
                        marginRight: "1rem",
                      }}/>
                      <div>
                        <p style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                          {cocomment?.user?.name}{" | "}
                          <span style={{ color: "grey", fontWeight: "400" }}>{cocomment?.user?.businessDepartment?.title}</span>
                          <p style={{ color: "grey", fontWeight: "400" }}>
                            {moment(cocomment.createdAt).format("YYYY.MM.DD(ddd) HH:mm")}
                          </p>
                        </p>
                      </div>
                    </div>
                  </UserComponent>
                  <ReadMoreComponent
                    lines={2}
                    showLess={false}
                    style={{ marginTop: "0.5rem" }}
                    children={cocomment.comment}
                  />
                </div>
              );
            })
          }
        </div>
      );
    default:
      return (
        <div style={{ marginBottom: "1.5rem" }}>
          <UserComponent user={comment.user}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "1rem" }}>
              <div style={{
                width: "40px",
                height: "40px",
                backgroundImage: `url(${comment.user?.photoUrl})`,
                backgroundColor: "grey",
                backgroundRepeat: "no-repeat",
                backgroundSize: "40px 40px",
                backgroundPosition: "center",
                borderRadius: "20px",
                marginRight: "1rem",
              }}/>
              <div>
                <p style={{ fontWeight: "bold" }}>
                  {comment?.user?.name}{" | "}
                  <span style={{ color: "grey", fontWeight: "400" }}>{comment?.user?.businessDepartment?.title}</span>
                  <p style={{ color: "grey", fontWeight: "400" }}>
                    {moment(comment.createdAt).format("YYYY.MM.DD(ddd) HH:mm")}
                  </p>
                </p>
              </div>
            </div>
          </UserComponent>
          <div style={{ paddingLeft: "1rem" }}>
            <p style={{ fontSize: "1rem" }}>
              {comment.comment}
            </p>
          </div>
          {
            comment.cocomments?.map((cocomment, i) => {
              return (
                <div key={i} style={{ paddingLeft: "2rem", marginTop: "1rem" }}>
                  <UserComponent user={cocomment.user}>
                    <div style={{
                      display: "flex", flexDirection: "row", alignItems: "center",
                      cursor: "pointer",
                    }}>
                      <div style={{
                        width: "40px",
                        height: "40px",
                        backgroundImage: `url(${cocomment.user?.photoUrl})`,
                        backgroundColor: "grey",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "40px 40px",
                        backgroundPosition: "center",
                        borderRadius: "20px",
                        marginRight: "1rem",
                      }}/>
                      <div>
                        <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                          {cocomment?.user?.name}{" | "}
                          <span style={{ color: "grey", fontWeight: "400" }}>{cocomment?.user?.businessDepartment?.title}</span>
                          <p style={{ color: "grey", fontWeight: "400" }}>
                            {moment(cocomment.createdAt).format("YYYY.MM.DD(ddd) HH:mm")}
                          </p>
                        </p>
                      </div>
                    </div>
                  </UserComponent>
                  <ReadMoreComponent
                    lines={2}
                    showLess={false}
                    style={{ marginTop: "1rem" }}
                    children={cocomment.comment}
                  />
                </div>
              );
            })
          }
        </div>
      );
  }

};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(CommentComponent);
