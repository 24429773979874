import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Icon, Image, Popup } from "semantic-ui-react";
import BookComponent from "../book/BookComponent";

const ScrapImageComponent = (props) => {

  const slideRef = React.useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrap, setScrap] = useState(props.scrap);


  const _nextSlide = () => {
    if (props.scrap?.images?.length < 2) return;
    if (selectedIndex >= props.scrap?.images?.length - 1) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const _preSlide = () => {
    if (props.scrap?.images?.length < 2) return;
    if (selectedIndex === 0) {
      setSelectedIndex(props.scrap?.images?.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  useEffect(() => {
    // setSelectedIndex(0);
  }, [props.scrap?.id]);

  useEffect(() => {
    // slideRef.current.style.transition = "all 0.5s ease-in-out";
    // slideRef.current.style.transform = `translateX(-${selectedIndex}00%)`;
  }, [selectedIndex]);

  useEffect(() => {
    setScrap(props.scrap);
  }, [props.scrap, props.scrap?.id]);

  if (props.showInGrid) {
    return (
      <div
        style={{
          width: "100%",
          paddingTop: "100%",
          backgroundColor: "lightgrey",
          backgroundImage: `url(${props.scrap?.images?.[selectedIndex]?.url})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          position: "relative",
          borderRadius: "0.5rem",
        }}>
        {
          scrap?.images?.length > 1 && (
            <>
              <div style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.3)",
                padding: "1rem",
                top: "1rem",
                left: "1rem",
                borderRadius: "0.5rem",
                fontSize: "1rem",
                fontWeight: "900",
                color: "white",
              }}>
                {selectedIndex + 1} / {scrap?.images?.length}
              </div>
            </>
          )
        }

      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          paddingTop: "100%",
          backgroundColor: "lightgrey",
          backgroundImage: `url(${props.scrap?.images?.[selectedIndex]?.url})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          position: "relative",
          borderRadius: "0.5rem",
        }}>
        {
          scrap?.images?.length > 1 && (
            <>
              <div style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,0.3)",
                padding: "1rem",
                top: "1rem",
                left: "1rem",
                borderRadius: "0.5rem",
                fontSize: "1rem",
                fontWeight: "900",
                color: "white",
              }}>
                {selectedIndex + 1} / {scrap?.images?.length}
              </div>
              <div
                onClick={_preSlide}
                style={{
                  position: "absolute",
                  top: "calc(50% - 1rem)",
                  left: "1rem",
                  width: "40px",
                  height: "40px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.3)",
                  cursor: "pointer",
                }}>
                <Icon name={"chevron left"}
                      size={"large"}
                      style={{ color: "white" }}
                />
              </div>
              <div
                onClick={_nextSlide}
                style={{
                  position: "absolute",
                  top: "calc(50% - 1rem)",
                  right: "1rem",
                  // padding: "1rem",
                  width: "40px",
                  height: "40px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.3)",
                  cursor: "pointer",
                }}>
                <Icon name={"chevron right"}
                      size={"large"}
                      style={{ color: "white" }}
                />
              </div>
            </>
          )
        }
        {
          props.showsBookInfo && (
            <div style={{
              position: "absolute",
              bottom: "1rem",
              left: "1rem",
              width: "7%",
              paddingTop: "11%",
            }}>
              <BookComponent
                viewType={'THUMBNAIL'}
                book={scrap?.book}/>
            </div>
          )
        }

      </div>
    );

  }

};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(ScrapImageComponent);
