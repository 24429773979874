import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Modal } from "semantic-ui-react";

const MyFollowerListModalComponent = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {

  }, []);


  return (
    <Modal
      open={modalOpen}
    >
      <Modal.Header>

      </Modal.Header>
      <Modal.Content style={{height: '50vh'}} scrolling>

      </Modal.Content>
      <Modal.Actions>
        <Button content={'닫기'}/>
      </Modal.Actions>
    </Modal>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(MyFollowerListModalComponent);
