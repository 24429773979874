import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useQuery } from "../../library/useQuery";
import Network_B2B from "../../library/Network_B2B";
import { Button, Container, Divider, Form, Grid, Header, Icon, Image, Item, List, Loader, Message, Modal, Segment } from "semantic-ui-react";
import { authActionCreators } from "../../redux/auth/authStore";
import moment from "moment";
import numeral from "numeral";
import { Link, useParams } from "react-router-dom";
import ScrapComponent from "../../component/scrap/ScrapComponent";

const BookRequestContainer = (props) => {

  const { bookRequestId: businessBookRequestId } = useParams();

  const [refreshing, setRefreshing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [businessBookRequest, setBusinessBookRequest] = useState({});
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [missionModalOpen, setMissionModalOpen] = useState(false);
  const [page, setPage] = useState(null);
  const [content, setContent] = useState("");


  useEffect(() => {
    _load();
  }, []);

  const _load = async () => {
    const res = await Network_B2B.getBusinessBookRequest(businessBookRequestId);
    if (!res.err) {
      setBusinessBookRequest(res.businessBookRequest);
      setIsLoaded(true);
      setFetching(false);
      setRefreshing(false);
    }
  };

  const _handleConfirmCancel = async () => {

    setFetching(true);
    const res = await Network_B2B.deleteBusinessBookRequest(businessBookRequestId);
    setFetching(false);
    if (!res.err) {
      props.history.replace("/book-request");
    }
  };

  const _handleRequestRefund = async () => {
    setFetching(true);
    const res = await Network_B2B.postBusinessBookRequestReturnRequest(businessBookRequestId);
    if (!res.err) {
      _load();
    }
  };

  const _confirmStart = () => {
    const a = window.confirm("책이 잘 도착했나요? 도서 수령 확인을 누르시면, 스크랩 미션을 올리실 수 있습니다.");
    if (!a) return;
    _handleRequestBusinessBookRequestOrderReceived();
  };

  const _handleRequestBusinessBookRequestOrderReceived = async () => {
    if (!businessBookRequest) return null;
    setFetching(true);
    const res = await Network_B2B.postBusinessBookRequestBookReceived(businessBookRequest.id);
    if (!res.err) {
      _load();
      props.fetchMyInfo(() => {
        setFetching(false);
      });
    } else {
      setFetching(false);
    }
  };

  const _handleRequestCancelRefund = async () => {
    setFetching(true);
    const res = await Network_B2B.deleteBusinessBookRequestReturnRequest(businessBookRequestId);
    if (!res.err) {
      await _load();
    }
    setFetching(false);
  };

  const _handleGoToTrackingWebView = () => {
    window.open(
      businessBookRequest?.order?.orderDelivery?.url,
      "_blank");
  };

  const _openMissionModal = () => {
    setMissionModalOpen(true);
  };
  const _closeMissionModal = () => {
    if (content.length > 0) {
      if (!window.confirm("스크랩 작성을 취소하시겠습니까?")) {
        return;
      }
    }
    setMissionModalOpen(false);
    setContent("");
    setPage(null);
  };

  const _uploadScrapMission = async () => {
    if (content?.trim().length < 100) return window.alert("100자 이상으로 작성해 주세요.");
    if (!window.confirm("스크랩을 제출하시겠습니까?")) return;
    const data = {
      content: content.trim(),
      page: null,
      status: "SCRAP_STATUS_PUBLIC",
      bookId: businessBookRequest?.bookId,
    };
    setUploading(true);

    const res = await Network_B2B.postScrap(data);
    if (!res.err) {
      window.alert("완료되었습니다");
      setIsLoaded(false);
      setUploading(false);
      _load();
    }

  };

  if (!isLoaded) return <Loader active/>;


  const _renderUserAddress = () => {
    if (!businessBookRequest?.order?.id) return null;
    const { order } = businessBookRequest;
    const canSeeUrl = ( order?.orderDelivery?.orderDeliveryStatusId === 2 ) || ( order?.orderDelivery?.orderDeliveryStatusId === 3 );

    return (
      <>
        <p>
          배송상태 : {order.orderDelivery?.orderDeliveryStatus?.title}
        </p>
        <Message>
          <Message.Header content={"배송지"}/>
          <Message.List>
            <Message.Item>
              <b>받는 사람: </b> {order?.orderDelivery?.name}
            </Message.Item>
            <Message.Item>
              <b>주소: </b>{order?.orderDelivery?.address?.trim()} {order?.orderDelivery?.addressDetail?.trim()}
            </Message.Item>
            <Message.Item>
              <b>우편번호: </b>{order?.orderDelivery?.zoneNo}
            </Message.Item>
            <Message.Item>
              <b>전화번호: </b>{order?.orderDelivery?.phoneNumber}
            </Message.Item>
            <Message.Item>
              <b>도착 예상일: </b>{moment(businessBookRequest.expectedDeliveryDate).format("YYYY.MM.DD(ddd)")}{"\n"}
              <br/>도서산간, 공휴일과 주말의 경우 지체될 수 있습니다.
            </Message.Item>
          </Message.List>
          {!!canSeeUrl && (
            <>
              <Divider/>
              <Button
                size={"large"}
                onClick={() => _handleGoToTrackingWebView()}>
                <Icon name={"shipping"}/>
                실시간 배송현황
              </Button>
            </>
          )}
        </Message>

        {
          !!businessBookRequest.startPossible && (
            <>

              <Message info>
                <Message.Content>
                  <Message.Header content={"도서 수령 확인 안내"}/>
                  <Message.List>
                    <Message.Item>
                      도서수령 후, 도서 수령 버튼을 클릭해 주세요.
                    </Message.Item>
                    <Message.Item>
                      상단 스크랩 미션 버튼이이 활성화 됩니다.
                    </Message.Item>
                    <Message.Item>
                      도서 수령을 확인 하시면, 취소할 수 없습니다.
                    </Message.Item>
                  </Message.List>
                  <Divider/>
                  <Button content={"도서수령 확인하기"} size={"large"} icon={"check"} primary onClick={_confirmStart}/>
                </Message.Content>
              </Message>
            </>
          )
        }
      </>

    );
  };
  const _renderCancelButton = () => {
    if (businessBookRequest?.cancelPossible) {
      return (
        <>
          <Button basic negative content={"취소하기"} onClick={() => setCancelModalOpen(true)}/>
          <Modal
            size={"mini"}
            onClose={() => setCancelModalOpen(false)}
            open={cancelModalOpen}
          >
            <Modal.Header>취소</Modal.Header>
            <Modal.Content>
              도서 신청을 취소하시겠습니까?
            </Modal.Content>
            <Modal.Actions>
              <Button content={"취소하기"}
                      disabled={fetching}
                      negative
                      onClick={_handleConfirmCancel}/>
              <Button content={"닫기"}
                      disabled={fetching}
                      basic
                      onClick={() => setCancelModalOpen(false)}/>
            </Modal.Actions>
          </Modal>
        </>
      );
    } else if (businessBookRequest.refundPossible) {

    } else if (businessBookRequest?.cancelRefundPossible) {

    } else {
      return null;
    }

  };
  const _renderMissionInfo = () => {
    switch (businessBookRequest.businessBookRequestStatusId) {
      case 1: // 신청 완료
        return (
          <>
            <List bulleted>
              <List.Item>
                미션 상태: 시작 전입니다.
              </List.Item>
              <List.Item>
                미션 조건: 스크랩 100자 이상
              </List.Item>
              <List.Item>
                배송이 완료되면, 스크랩 미션을 활성화 시킬 수 있습니다.
              </List.Item>
            </List>
            <Button content={"스크랩 미션 하기"}
                    disabled
              // icon={"camera"}
                    fluid
                    size={"large"}
                    primary/>
          </>
        );
      case 2: // 취소완료
        return (
          <List>
            <List.Item>
              도서 신청 취소가 완료되었습니다.
            </List.Item>
          </List>
        );
      case 3: // 종료
        return (
          <div>
            <Button content={"완료 미션 보기"}
                    fluid
                    as={Link}
                    positive
                    icon={"check"}
                    size={"large"}
                    to={`/scrap/${businessBookRequest?.scrap?.id}`}/>
          </div>
        );
      case 4: // 반송 요청
      case 5: // 반송 진행 중
        return (
          <List>
            <List.Item>
              반송이 요청되었습니다.
            </List.Item>
          </List>
        );
      case 6: // 진행중
        return (
          <>
            <List bulleted>
              <List.Item>
                미션 상태
                진행중
              </List.Item>
              <List.Item>
                미션 조건: 스크랩 100자 이상 + 전체 공개
              </List.Item>
            </List>
            <Modal open={missionModalOpen}>
              <Modal.Header content={"스크랩 미션 수행"}/>
              <Modal.Content style={{ minHeight: "400px" }}>
                <Message>
                  <Message.List bulleted>
                    <Message.Item content={"100자 이상 작성해 주세요."}/>
                    <Message.Item content={"북스타그랩 앱으로 사진과 함게 올리기를 권장드립니다."}/>
                    <Message.Item content={"좋아요, 포인트 등으로 더 많은 혜택이 주어집니다.\n(현재, 스크랩 미션 완료시 앱과 동일하게 3,000P 부여되나, 추후 변경될 수 있습니다.)"}/>
                  </Message.List>
                </Message>
                <Form>
                  <Form.TextArea
                    placeholder={"내용을 100자 이상 작성해 주세요."}
                    value={content}
                    onChange={(e, { value }) => {
                      setContent(value.replace("\n\n\n", "\n\n"));
                    }}
                    style={{ height: "200px" }}/>
                </Form>
                <p style={{ color: content?.trim().length > 100 ? "green" : "red" }}>
                  길이: {content?.trim().length}자 {content?.trim().length < 100 && "(100자 이상으로 작성해 주세요.)"}
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={_uploadScrapMission}
                        primary
                        content={"업로드 하기"}/>
                <Button onClick={_closeMissionModal}
                        content={"취소"}/>
              </Modal.Actions>
            </Modal>
            <Button
              // icon={"camera"}
              fluid
              primary
              onClick={_openMissionModal}
              content={"스크랩 미션 하기"}
              size={"large"}
            />
          </>
        );
      default:
        return (
          <div>

          </div>
        );
    }
  };

  const
    { book } = businessBookRequest;

  return (
    <>
      {/* 책 정보*/}
      <>
        <Container style={{ maxWidth: "700px" }} as={Segment} vertical basic text>
          <Item.Group>
            <Item>
              <Item.Image src={book.coverImageUrl} size={"tiny"}/>
              <Item.Content>
                <Item.Header>
                  {book.title}
                </Item.Header>
                <Item.Meta>
                  <p>
                    {book.author} <br/>
                    {book.publisher} {book.pubDate}
                  </p>
                  <b>
                    신청일: {moment(businessBookRequest?.createdAt).format("YYYY.MM.DD(ddd) HH:mm")}
                  </b>
                </Item.Meta>
              </Item.Content>
            </Item>
          </Item.Group>
        </Container>
      </>
      <>
        <Container style={{ maxWidth: "800px", marginTop: "40px" }} as={Segment} vertical basic text>
          <Header content={"미션"}/>
          {_renderMissionInfo()}
        </Container>
      </>

      <>
        <Container style={{ maxWidth: "800px", marginTop: "40px" }} as={Segment} vertical basic text>
          <Header content={"배송정보"}/>
          {_renderUserAddress()}
        </Container>
      </>
      <>
        <Container style={{ maxWidth: "800px", marginTop: "40px" }} as={Segment} vertical basic text>
          {_renderCancelButton()}
        </Container>
      </>
    </>
  );
};

const enhance = connect(
  state => (
    {
      ...
        state,
    }
  ),
  {
    ...
      authActionCreators,
  },
);

export default enhance(BookRequestContainer);
