import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import Network_B2B from "../../library/Network_B2B";
import moment from "moment";
import { useQuery } from "../../library/useQuery";
import LoaderComponent from "../../component/shared/LoaderComponent";
import { Button, Container, Divider, Grid, Loader, Segment } from "semantic-ui-react";
import Colors from "../../constant/Colors";
import { withLineEnter } from "../../library/TextHandler";
import ScrapImageComponent from "../../component/scrap/ScrapImageComponent";
import ScrapComponent from "../../component/scrap/ScrapComponent";
import { feedActionCreators } from "../../redux/feed/feedStore";

const FeedMainContainer = (props) => {

  const { type } = useQuery(props);
  const feedTypes = [
    {
      text: "팔로워",
      value: "follow",
    },
    {
      text: "전체",
      value: "recommend",
    },
  ];

  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});
  const [perPage, setPerPage] = useState(20);
  const [feeds, setFeeds] = useState([]);


  const { feedsFollow, feedsRecommend, isFeedsFollowLoaded, isFeedsRecommendLoaded } = props.feedReducer;

  const _load = async () => {
    let res;
    switch (type) {
      case "follow":
        props.fetchFeedsFollow(perPage, () => {
          console.log(2);
        });
        break;
        // res = await Network_B2B.getFeedFollow(moment().toISOString(), perPage);
        // if (!res.err) {
        //   setFeeds(res.feedsFollow);
        //   setIsLoaded(true);
        // }
        // break;
      case "recommend":
        props.fetchFeedsRecommend(perPage, () => {
          console.log(1);
        });
        break;
        // res = await Network_B2B.getFeedRecommend(moment().toISOString(), perPage);
        // if (!res.err) {
        //   setFeeds(res.feedsRecommend);
        //   setIsLoaded(true);
        // }
        // break;
      default:
    }

  };


  useEffect(() => {
    if (!type) {
      props.history.replace(`/feed?type=follow`);
    } else {
      window.scrollTo(0, 0);
      setFeeds([]);
      setIsLoaded(false);
      _load();
    }
  }, [type]);


  const _renderTab = () => {
    return (
      <>
        <Container as={Segment} vertical basic>
          <div style={{ display: "flex" }}>
            {
              feedTypes.map((t, i) => {
                const selected = t.value === type;
                return (
                  <div key={i}
                       onMouseOver={() => {
                         // console.log('마우스가 들어옴')
                       }}
                       onMouseOut={() => {
                         // console.log('마우스가 나감')
                       }}
                       onClick={() => {
                         props.history.push(`/feed?type=${t.value}`);
                       }}
                       style={{
                         padding: "1rem",
                         // backgroundColor: selected ? Colors.buttonColor : 'white',
                         fontSize: "2rem",
                         fontWeight: selected ? "900" : "400",
                         cursor: "pointer",
                       }}>
                    {t.text}
                  </div>
                );
              })
            }
          </div>
        </Container>
      </>
    );
  };

  const _renderFeedsFollow = () => {
    if (!isFeedsFollowLoaded) return <Loader active/>;
    return (
      <Container>
        {feedsFollow?.map((feed, i) => {
          return (
            <ScrapComponent scrap={feed} key={feed.id}/>
          );
        })}
      </Container>
    );
  };

  const _renderFeedRecommend = () => {
    if (!isFeedsRecommendLoaded) return <Loader active/>;
    return (
      <Container>
        {feedsRecommend?.map((feed, i) => {
          return (
            <ScrapComponent scrap={feed} key={feed.id}/>
          );
        })}
      </Container>
    );
  };

  return (
    <>
      {_renderTab()}
      {type === "follow" && _renderFeedsFollow()}
      {type === "recommend" && _renderFeedRecommend()}
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {
    ...feedActionCreators,
  },
);

export default enhance(FeedMainContainer);
