import { createLogic } from "redux-logic";
import { feedActions, feedActionCreators } from "./feedStore";
import Network_B2B from "../../library/Network_B2B";
import { auth } from "../../constant/firebase";
import moment from "moment";


const fetchFeedsFollow = createLogic({
  type: feedActions.FETCH_FEEDS_FOLLOW,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const res = await Network_B2B.getFeedFollow(moment().toISOString(), action.perPage || 30);
    if (!res.err) {
      const { feedsFollow } = getState().feedReducer;
      dispatch(feedActionCreators.fetchFeedsFollowSuccess(res.feedsFollow || []));
    }
    action.cb?.();
    done();
  },
});

const fetchFeedsFollowMore = createLogic({
  type: feedActions.FETCH_FEEDS_FOLLOW_MORE,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const res = await Network_B2B.getFeedFollow(action.lastItemCreatedAt, action.perPage || 30);
    if (!res.err) {
      const { feedsFollow } = getState().feedReducer;
      dispatch(feedActionCreators.fetchFeedsFollowSuccess([...feedsFollow, ...res.feedsFollow]));
    }
    action.cb?.();
    done();
  },
});


const fetchFeedsRecommend = createLogic({
  type: feedActions.FETCH_FEEDS_RECOMMEND,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const res = await Network_B2B.getFeedRecommend(moment().toISOString(), action.perPage || 30);
    if (!res.err) {
      dispatch(feedActionCreators.fetchFeedsRecommendSuccess(res.feedsRecommend || []));
    }
    action.cb?.();
    done();
  },
});

const fetchFeedsRecommendMore = createLogic({
  type: feedActions.FETCH_FEEDS_RECOMMEND_MORE,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const res = await Network_B2B.getFeedRecommend(action.lastItemCreatedAt, action.perPage || 30);
    if (!res.err) {
      const { feedsRecommend } = getState().feedReducer;
      dispatch(feedActionCreators.fetchFeedsRecommendSuccess([...feedsRecommend, ...res.feedsRecommend]));
    }
    action.cb?.();
    done();
  },
});

export default [
  fetchFeedsFollow,
  fetchFeedsFollowMore,

  fetchFeedsRecommend,
  fetchFeedsRecommendMore,
];
