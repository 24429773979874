import React from 'react';
import {connect} from 'react-redux';
import Lottie from 'react-lottie';
import animationData from '../../asset/lottie/loading.json';
import { authActionCreators } from "../../redux/auth/authStore";
import { Button } from "semantic-ui-react";

const LoaderComponent = (props) => {

  return (
    <div style={{position: 'fixed', width: '100vw', height: '100vh', display: 'flex', alignItems: 'center'}}>
      <Lottie
        options={{
          loop: true,
          autoPlay: true,
          animationData: animationData,
          rendererSettings: {
            // preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        style={{}}
        height={'200px'}
        width={'100px'}
        isStopped={false}
        isPaused={false}
      />
      {/*<Button content={'로그아웃'}onClick={props.signOut}/>*/}
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators
  }
);

export default enhance(LoaderComponent);
