import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoaderComponent from "./component/shared/LoaderComponent";
import HomeContainer from "./container/home/HomeContainer";
import LayoutComponent from "./component/layout/LayoutComponent";
import { connect } from "react-redux";
import { authActionCreators } from "./redux/auth/authStore";
import { privateRouterList } from "./route/privetRouterList";
import { auth } from "./constant/firebase";
import Network_B2B from "./library/Network_B2B";
import { PrivateRoute } from "./component/shared/PrivateRoute";
import SignInContainer from "./container/auth/SignInContainer";
import ChannelService from "./library/ChannelService";
import "moment/locale/ko";
import { createMedia } from "@artsy/fresnel";
import SmallScreenInfo from "./container/x-responsive/SmallScreenInfo";
import NoPageContainer from "./container/404/NoPageContainer";
import HelpManualMobile from "./container/help/HelpManualMobile";
import EventOpenDetailContainer from "./container/event/EventOpenDetailContainer";
import EventHashTagDetailContainer from "./container/event/EventHashTagDetailContainer";
import EventStoryDetailContainer from "./container/event/EventStoryDetailContainer";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    sm: 0,
    md: 765,
  },
});


function App (props) {

  const [isLoaded, setIsLoaded] = useState(false);

  const { isLoggedIn } = props.authReducer;

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (!!user) {
        Network_B2B.setFirebaseUid(user.uid);
        if (!isLoggedIn) {
          props.fetchMyInfoInit((user) => {
            setIsLoaded(true);
            props.fetchMyBook();

            ChannelService.boot(
              {
                pluginKey: "1bd70eba-bde3-4e2c-bba8-dcfd3eba148f",
                memberId: user.id,
                profile : {
                  name: user.name,
                  mobileNumber : user.phoneNumber,
                  email: user.email,
                  avatarUrl : user.photoUrl,
                  companyIdNo : user.businessMember?.companyIdNo,
                }
              });
          });
        }
      } else {
        setIsLoaded(true);
        ChannelService.boot({ "pluginKey": "1bd70eba-bde3-4e2c-bba8-dcfd3eba148f" });
      }
    });

  }, []);


  // return (
  //   <ComingSoonContainer/>
  // )

  if (!isLoaded) {
    return <LoaderComponent/>;
  }

  if (!props.authReducer.isLoggedIn) {
    return (
      <MediaContextProvider>
        <Media at="sm">
          <BrowserRouter>
            {/*<Route exact path={'/'} component={HomeContainer}/>*/}
            <Route path={"/help/manual"} component={HelpManualMobile}/>
            <Route path={"/event/hash-tag"} component={EventHashTagDetailContainer}/>
            <Route path={"/event/story"} component={EventStoryDetailContainer}/>
            <Route path={"/event/:eventId/:userId"} component={EventOpenDetailContainer}/>
            <Route path={"*"} component={SmallScreenInfo}/>
          </BrowserRouter>
        </Media>
        <Media greaterThanOrEqual="md">
          <SignInContainer/>
        </Media>
      </MediaContextProvider>
    );
  }


  return (
    <MediaContextProvider>
      <Media at="sm">
        <BrowserRouter>
          {/*<Route exact path={'/'} component={HomeContainer}/>*/}
          <Route path={"/help/manual"} component={HelpManualMobile}/>
          <Route path={"/event/hash-tag"} component={EventHashTagDetailContainer}/>
          <Route path={"/event/story"} component={EventStoryDetailContainer}/>
          <Route path={"/event/:eventId/:userId"} component={EventOpenDetailContainer}/>
          <Route path={"*"} component={SmallScreenInfo}/>
        </BrowserRouter>
      </Media>
      <Media greaterThanOrEqual="md">
        <BrowserRouter>
          <LayoutComponent>
            <Switch>
              <Route exact path={"/"} component={HomeContainer}/>
              <Route path={"/event/hash-tag"} component={EventHashTagDetailContainer}/>
              <Route path={"/event/story"} component={EventStoryDetailContainer}/>
              {
                privateRouterList.map((route, i) => {
                  return (
                    <PrivateRoute
                      key={i}
                      exact
                      isLoggedIn={props.authReducer?.isLoggedIn}
                      path={route.path}
                      component={route.component}
                    />
                  );
                })
              }
              <Route path={"*"} component={NoPageContainer}/>
            </Switch>
          </LayoutComponent>
        </BrowserRouter>
      </Media>

    </MediaContextProvider>

  );


}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {
    ...authActionCreators,
  },
);

export default enhance(App);
