import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from "../../component/shared/LoaderComponent";
import Network_B2B from "../../library/Network_B2B";
import {Container, Icon, Image, Item, Label, Message, Segment} from "semantic-ui-react";
import numeral from 'numeral';
import moment from 'moment';

const MyPageVoucherContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [refreshing, setRefreshing] = useState(false);
  const [businessBookVouchers, setBusinessBookVouchers] = useState([]);
  const [businessBookVoucherStat, setBusinessBookVoucherStat] = useState({});

  const _load = async () => {
    const res = await Network_B2B.getBusinessBookVoucherListMy(0, 100);
    if (!res.err) {
      setIsLoaded(true);
      setRefreshing(false);
      setBusinessBookVouchers(res.businessBookVouchers);
      setBusinessBookVoucherStat(res.businessBookVoucherStat);
    }
  };

  useEffect(() => {
    _load();
  }, [])

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <div>
      <Container text vertical as={Segment}>
        <Message info icon style={{alignItems: 'flex-start'}}>
          {/*<Image src={'/logo192.png'} size={'mini'} avatar style={{marginRight: '1rem'}}/>*/}
          <Icon name={'info circle'}/>
          <Message.Content>
            <Message.List>
              <Message.Header content={'도서 신청권'}/>
              <Message.Item>
                남은 도서 신청권: {businessBookVoucherStat.left}개
              </Message.Item>
              <Message.Item>
                기한 내에 사용하지 않은 도서 신청권은 소멸됩니다. <br/>
                연 8권의 도서를 신청할 수 있도록, 8개의 도서 신청권이 지급됩니다 (2021년 기준)
              </Message.Item>
            </Message.List>
          </Message.Content>

        </Message>
      </Container>
      <Container text vertical as={Segment}>
        <Item.Group>
          {
            businessBookVouchers?.map((item, i) => {
              if (item.isUsed) {
                return (
                  <Segment key={i}>
                    <Item style={{}}>
                      <Item.Content style={{}}>
                        <Label content={'사용완료'}/>
                        <br/>
                        사용일: {moment(item.usedAt).format('YYYY.MM.DD(ddd) HH:mm')}
                      </Item.Content>
                    </Item>
                  </Segment>
                )
              } else {
                return (
                  <Segment key={i}>
                    <Item style={{}}>
                      <Item.Content style={{}}>
                        <Label content={'사용가능'} color={'blue'}/>
                        <br/>
                        {/*{item.id} <br/>*/}
                        사용기한: {moment(item.expiredAt).format('YYYY.MM.DD(ddd) HH:mm')}
                      </Item.Content>
                    </Item>
                  </Segment>
                )
              }

            })
          }

        </Item.Group>
      </Container>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(MyPageVoucherContainer);
