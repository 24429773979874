import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {Link, useHistory} from 'react-router-dom';
import {Header, Image, Item} from "semantic-ui-react";

const BookRequestIngComponent = (props) => {

  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})
  const businessBookRequestIng = props.authReducer.businessBookRequestIng || null;

  useEffect(() => {

  }, [])

  if (!businessBookRequestIng?.id) {
    return (
      <>
        <Header size={'small'} content={'진행 중인 도서 신청 내역이 없습니다.'}/>
        <p>
          책을 검색해서 도서를 신청해 주세요.
        </p>
      </>
    )
  }
  const {book} = businessBookRequestIng;

  return (
    <div>
      <Item.Group>
        <Item as={Link} to={`/book-request/${businessBookRequestIng?.id}`}>
          <Item.Image src={book.coverImageUrl} size={'tiny'}/>
          <Item.Content>
            <Item.Meta>
              <p>
                {businessBookRequestIng?.businessBookRequestStatus?.text}
              </p>
              <p>
                <b>
                  {book.title}
                </b>
              </p>
              {
                !!businessBookRequestIng?.order?.orderDelivery?.id && (
                  <p>
                    배송상태: {businessBookRequestIng?.order?.orderDelivery?.orderDeliveryStatus?.title}
                  </p>
                )
              }
            </Item.Meta>
          </Item.Content>
        </Item>
      </Item.Group>

    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);

export default enhance(BookRequestIngComponent);
