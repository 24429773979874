import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import Network_B2B from "../../library/Network_B2B";
import LoaderComponent from "../../component/shared/LoaderComponent";
import JSONPretty from "react-json-prettify";
import {Card, Container, Divider, Header, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";

const RecommendationUserContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [sections, setSections] = useState([]);


  useEffect(() => {
    window.scrollTo(0, 0);
    _load();
  }, []);

  const _load = async () => {
    const res = await Network_B2B.getRecommendationUserList();
    if (!res.err) {
      setIsLoaded(true);
      setRefreshing(false);
      setSections(res.sections);
    }
  };

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic>

      </Container>
      <Container>
        {
          sections?.map((section, i) => {
            return (
              <Segment key={i} vertical style={{marginTop: "3rem"}} basic>
                <Header>
                  {section.headerTitle}
                </Header>
                <Divider/>
                <Card.Group itemsPerRow={6}>
                  {
                    section.data?.[0]?.items?.map((o, j) => {
                      return (
                        <Card
                          key={j}
                          fluid as={Link} to={`/user/${o.id}`}>
                          <Card.Content>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                              <div style={{
                                backgroundImage: `url(${o.photoUrl})`,
                                backgroundSize: '70px 70px',
                                width: '70px',
                                height: '70px',
                                borderRadius: '35px',
                              }}/>
                            </div>
                          </Card.Content>
                          <Card.Content extra style={{textAlign: 'center',}}>
                            <p>
                              {o.name}
                              <p>
                                {o.businessDepartment?.title}
                              </p>
                            </p>
                          </Card.Content>
                        </Card>
                      )
                    })
                  }
                </Card.Group>
              </Segment>
            )
          })
        }
      </Container>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(RecommendationUserContainer);
