import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import Network_B2B from "../../library/Network_B2B";
import { Button, Card, Divider, Form, Grid, Header, Icon, Image, Loader, Ref, Message, Modal, Popup, Segment } from "semantic-ui-react";
import ScrapImageComponent from "./ScrapImageComponent";
import { withLineEnter } from "../../library/TextHandler";
import moment from "moment";
import UserComponent from "../user/UserComponent";
import LoaderComponent from "../shared/LoaderComponent";
import CommentComponent from "../comment/CommentComponent";
import ReadMoreComponent from "../shared/ReadMoreComponent";
import TextareaAutosize from "react-textarea-autosize";
import Colors from "../../constant/Colors";

const ScrapComponent = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [scrap, setScrap] = useState({});

  const [scrapLikeUsers, setScrapLikeUsers] = useState([]);
  const [isScrapLikeUserLoaded, setIsScrapLikeUserLoaded] = useState(false);
  const [scrapLikeUserModalOpen, setScrapLikeUserModalOpen] = useState(false);
  const [scrapCommentModalOpen, setScrapCommentModalOpen] = useState(false);

  const [isScrapCommentLoaded, setIsScrapCommentLoaded] = useState(false);
  const [scrapComments, setScrapComments] = useState([]);
  const [selectedScrapCommentId, setSelectedScrapCommentId] = useState(null);
  const [selectedScrapComment, setSelectedScrapComment] = useState({});
  const [uploading, setUploading] = useState(false);
  const [showScrapCommentsAll, setShowScrapCommentsAll] = useState(false);
  const [comment, setComment] = useState("");

  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [moreModalOpen, setMoreModalOpen] = useState(false);

  const textRef = React.useRef();

  const isMine = scrap.userId === props.authReducer?.user?.id;


  // 하단 버튼 관련
  const _handleToggleBookmark = async () => {
    if (scrap.bookmarked) {
      // cancel
      setScrap(prevState => ( { ...prevState, bookmarked: false } ));
      await Network_B2B.deleteBookmark(scrap.id, "SCRAP");
    } else {
      // add
      setScrap(prevState => ( { ...prevState, bookmarked: true } ));
      await Network_B2B.postBookmark(scrap.id, "SCRAP");
    }
  };
  const _handleToggleLike = async () => {
    if (scrap.amILiked) {
      // cancel
      setScrap(prevState => ( { ...prevState, amILiked: false, numberOfLikes: prevState.numberOfLikes - 1 } ));
      await Network_B2B.deleteScrapLike(scrap.id);

    } else {
      // add
      setScrap(prevState => ( { ...prevState, amILiked: true, numberOfLikes: prevState.numberOfLikes + 1 } ));
      await Network_B2B.postScrapLike(scrap.id);
    }
  };

  // 모달 여는 것
  const _handleOpenLikeUserListModal = () => setScrapLikeUserModalOpen(true);
  const _handleCloseLikeUserListModal = () => setScrapLikeUserModalOpen(false);
  const _handleOpenCommentModal = () => setScrapCommentModalOpen(true);
  const _handleCloseCommentModal = () => setScrapCommentModalOpen(false);
  const _handleOpenMoreModal = () => setMoreModalOpen(true);
  const _handleCloseMoreModal = () => setMoreModalOpen(false);

  const _handleOpenDetailModal = () => setDetailModalOpen(false);

  const _deleteScrap = async () => {
    const a = window.confirm("스크랩을 삭제하시겠습니까?");
    if (!a) return;

    const res = await Network_B2B.deleteScrap(scrap.id);
    if (!res.err) {
      setScrap({ isDeleted: true });
    }

  };
  const _reportScrap = async () => {
    const a = window.confirm("스크랩을 신고 하시겠습니까?\n운영자가 검토합니다.");
    if (!a) return;

    const res = await Network_B2B.postScrapReport(scrap.id);
    if (!res.err) {
      _handleCloseMoreModal();
      return window.alert("신고가 정상적으로 완료되었습니다.");
    }
  };

  // 스크랩 좋아요한 유저
  const _loadScrapLikeUserList = async () => {
    const res = await Network_B2B.getScrapLikeUserList(scrap.id);
    if (!res.err) {
      setScrapLikeUsers(res.users);
      setIsScrapLikeUserLoaded(true);
    }
  };

  // 댓글 관련 부분
  const _loadScrapComments = async () => {
    console.log(scrap.id);
    const res = await Network_B2B.getScrapCommentList(scrap.id);
    if (!res.err) {
      setIsScrapCommentLoaded(true);
      setScrapComments(res.scrapComments);
    }
  };
  const _uploadComment = async () => {
    if (uploading || !comment || comment.trim() === "") return;
    setUploading(true);
    const res = await Network_B2B.postScrapComment(comment.trim(), scrap.id, selectedScrapCommentId);
    if (!res.err) {
      await _loadScrapComments();
      setUploading(false);
      setComment("");
      setSelectedScrapCommentId(null);
      setSelectedScrapComment({});

    } else {
      setUploading(false);
    }
  };
  const _reportComment = async (scrapCommentId) => {
    const a = window.confirm("댓글에 문제가 있나요? 신고하시면, 관리자가 검토합니다.");
    if (!a) return;
    const res = await Network_B2B.postScrapCommentReport(scrapCommentId);
    if (!res.err) {
      window.alert("신고가 완료되었습니다.");
    }
  };
  const _deleteComment = async (scrapCommentId) => {
    const a = window.confirm("댓글을 삭제하시겠습니까?");
    if (!a) return;

    const res = await Network_B2B.deleteScrapComment(scrapCommentId);
    if (!res.err) {
      _loadScrapComments();
    }
  };
  const _handleReply = async (comment) => {
    setSelectedScrapComment(comment);
    setSelectedScrapCommentId(comment.id);
    // textRef.current.focus?.();
    console.log(textRef);
    textRef.current?.focus?.();

    // TODO userIdsMentioned 여기 부분 처리 해야 함
    // 동명 이인 어떻게 처리해야 할지 아직 모름.

  };


  useEffect(() => {
    if (scrapLikeUserModalOpen) {
      _loadScrapLikeUserList();
    }
  }, [scrapLikeUserModalOpen]);

  useEffect(() => {
    if (scrapCommentModalOpen) {
      _loadScrapComments();
    }
  }, [scrapCommentModalOpen]);

  useEffect(() => {
    setScrap(props.scrap);
    setScrapLikeUsers([]);
    setIsScrapLikeUserLoaded(false);
    setScrapComments(props.scrap?.scrapComments || []);
  }, [props.scrap, props.scrap?.id]);

  const { showsUserInfo, showsBookInfo, viewType } = props;
  if (!scrap) return null;


  if (scrap.isDeleted) {
    return (
      <div>
        <p>스크랩이 삭제되었습니다.</p>
      </div>
    );
  }

  const _renderRightPartOfDetail = () => {
    return (
      <div style={{
        width: "40%",
        flex: 1,
        paddingLeft: "1rem",
        display: "flex",
        flexDirection: "column",
        maxHeight: "700px",
      }}>
        <UserComponent user={scrap?.user}/>
        <Divider/>
        <div
          className={"noScrollBar"}
          style={{
            flex: 1,
            overflow: "scroll",
            overflowX: "hidden",
          }}>
          <p>
            {moment(scrap.createdAt).format("YYYY.MM.DD(ddd) HH:mm")}
          </p>
          {
            scrap.page > 0 && (
              <p style={{ fontWeight: "800", fontSize: "1rem" }}>
                [{scrap.page} p]
              </p>
            )
          }
          <ReadMoreComponent
            children={scrap.content}
            showLess={false}
            more={"더보기"}
            less={"줄이기"}
          />
          {_renderScrapComments()}
        </div>
        <Divider/>
        <div>
          <Button.Group size={"large"}>
            <Button basic icon onClick={_handleOpenCommentModal}>
              <Icon name={"comment outline"} color={"red"}/>
            </Button>
            <Button basic onClick={_handleOpenCommentModal}>
              {scrap.numberOfComments}
            </Button>
            <Button icon basic onClick={_handleToggleLike}>
              <Icon name={scrap.amILiked ? "heart" : "heart outline"} color={scrap.amILiked ? "red" : ""}/>
            </Button>
            <Button basic onClick={_handleOpenLikeUserListModal}>
              {scrap.numberOfLikes}
            </Button>
          </Button.Group>
          <Button.Group size={"large"} floated={"right"}>
            <Button icon={"ellipsis horizontal"} basic onClick={_handleOpenMoreModal}/>
          </Button.Group>
        </div>
        <Divider/>
        <div style={{ border: "lightgrey 1px solid", borderRadius: "0.3rem" }}>
          {/*<input placeholder={'헬로'} ref={textRef}/>*/}
          <Form>
            <Form.Group style={{ alignItems: "flex-end" }}>

              <Form.TextArea
                // innerRef={textRef}
                onChange={(e, { value }) => {
                  setComment(value);
                }}
                placeholder={"댓글 달기"}
                width={13}
                value={comment}
                style={{ width: "100%", minHeight: 80, borderColor: "transparent" }}
              />
              <Form.Button
                disabled={uploading || !comment || comment.trim() === ""}
                onClick={(e) => {
                  e.preventDefault();
                  _uploadComment();
                }}
                size={"tiny"}
                content={"게시"}/>
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  };

  const _renderScrapLikeUserListModal = () => {
    return (
      <Modal
        closeIcon
        open={scrapLikeUserModalOpen}
        onClose={_handleCloseLikeUserListModal}
        size={"small"}>
        <Modal.Header content={"좋아요"}/>
        <Modal.Content scrolling style={{ height: "700px" }}>
          {/*{
            !isScrapLikeUserLoaded && <Loader active/>
          }*/}
          {
            scrapLikeUsers.map((u, i) => {
              return (
                <UserComponent
                  showFollowButton
                  user={u} key={i}/>
              );
            })
          }
        </Modal.Content>
        {/*<Modal.Actions>
          <Button content={"닫기"} onClick={() => setScrapLikeUserModalOpen(false)}/>
        </Modal.Actions>*/}
      </Modal>
    );
  };

  const _renderScrapCommentModal = () => {
    return (
      <Modal
        closeIcon
        onClose={_handleCloseCommentModal}
        open={scrapCommentModalOpen} size={"small"}>
        <Modal.Header content={"댓글"}/>
        <Modal.Content scrolling style={{ height: "700px", display: "flex", flexDirection: "column" }}>
          <div style={{ height: "calc(700px - 90px)", overflow: "scroll", overflowX: "hidden" }}>
            {
              scrapComments.map((comment, i) => {
                return (
                  <CommentComponent
                    comment={comment}
                    reportComment={_reportComment}
                    handleReply={_handleReply}
                    deleteComment={_deleteComment}
                    key={i}/>
                );
              })
            }
          </div>
          <div style={{ border: "lightgrey 1px solid", borderRadius: "0.3rem", marginTop: "1rem" }}>
            <Form>
              <Form.Group style={{ alignItems: "flex-end" }}>
                <Form.TextArea
                  onChange={(e, { value }) => {
                    setComment(value);
                  }}
                  value={comment}
                  placeholder={"댓글 달기"}
                  width={14}
                  style={{ width: "100%", minHeight: "80px", borderColor: "transparent" }}
                />
                <Form.Button
                  loading={uploading}
                  disabled={uploading || !comment || comment.trim() === ""}
                  onClick={(e) => {
                    e.preventDefault();
                    _uploadComment();
                  }}
                  size={"tiny"}
                  content={"게시"}/>
              </Form.Group>
            </Form>
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  const _renderDetailModal = () => {
    return (
      <>
        <Modal open={detailModalOpen}
               onClose={() => {
                 setDetailModalOpen(false);
               }}
               size={"large"}>
          <Modal.Content style={{ display: "flex" }} scrolling>
            <div style={{ width: "60%" }}>
              <ScrapImageComponent
                showsBookInfo
                scrap={scrap}/>
            </div>
            {_renderRightPartOfDetail()}
          </Modal.Content>
        </Modal>
        {_renderScrapLikeUserListModal()}
        {_renderScrapCommentModal()}
        {_renderMoreModal()}
      </>
    );
  };

  const _renderScrapComments = () => {
    if (!scrapComments || scrapComments.length < 1) return null;
    const scrapCommentData = showScrapCommentsAll ? scrapComments : scrapComments.slice(0, 3);
    return (
      <div style={{ marginTop: "1rem" }}>
        {scrapCommentData.map((comment, i) => {
          return (
            <CommentComponent
              viewType={"INLINE"}
              comment={comment}
              reportComment={_reportComment}
              handleReply={_handleReply}
              deleteComment={_deleteComment}
              key={i}
            />
          );
        })}
        {
          scrapComments.length > 3 && !showScrapCommentsAll && (
            <p
              style={{ color: Colors.buttonColor }}
              onClick={() => setShowScrapCommentsAll(true)}>
              [{scrapComments.length}개 댓글 모두 보기]
            </p>
          )
        }
      </div>
    );
  };

  const _renderMoreModal = () => {

    return (
      <Modal open={moreModalOpen}
             closeIcon
             onClose={_handleCloseMoreModal}
             size={"mini"}
      >
        {
          isMine ? (
            <Modal.Content>
              <Button fluid>
                수정
              </Button>
              <Button fluid>
                수정
              </Button>
            </Modal.Content>
          ) : (
            <Modal.Content>
              <Message>
                <Message.Content>
                  부적절한 컨텐츠라고 생각되는 경우, 신고해주세요.
                </Message.Content>
              </Message>
              <Button fluid
                      basic
                      negative
                      onClick={_reportScrap}
              >
                신고
              </Button>
            </Modal.Content>
          )
        }

      </Modal>
    );
  };

  switch (viewType) {
    case "CARD_GRID":
      return (
        <Card style={{ cursor: "pointer" }}
              onClick={() => {
                setDetailModalOpen(true);
              }}>
          <ScrapImageComponent
            showsBookInfo
            scrap={scrap} showInGrid/>
          {_renderDetailModal()}
        </Card>
      );

    default:
      return (
        <Segment style={{ display: "flex" }} raised>
          <div style={{ width: "60%" }}>
            <ScrapImageComponent
              showsBookInfo
              scrap={scrap}/>
          </div>
          {_renderRightPartOfDetail()}

          {_renderScrapLikeUserListModal()}
          {_renderScrapCommentModal()}
          {_renderMoreModal()}
        </Segment>
      );
  }
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(ScrapComponent);
