import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Button, Divider, Grid, Header, Icon, Image} from "semantic-ui-react";
import {Link} from "react-router-dom";

const SmallScreenInfo = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  useEffect(() => {

  })

  return (
    <div style={{height: '100vh', position:'fixed', width:'100%'}}>
      <Grid style={{height: '100vh', backgroundColor: '#F7D887'}}>
        <Grid.Row style={{height: '100vh'}} verticalAlign={'middle'}>
          <Grid.Column textAlign={'center'}>
            <Image src={'/logo192.png'} size={'tiny'} avatar/>
            <Header style={{fontWeight: '900'}} size={'large'}>
              교보생명<br/>
              독서공방<br/>
              (讀書共房)
            </Header>
            <p style={{color: 'grey', fontSize: '0.8rem'}}>
              웹버전은 PC 또는 <br/>
              가로 765px 이상의 태블릿에서만 지원합니다.
            </p>
            <Divider hidden/>
            <Divider hidden/>
            <Button icon={'app store'} content={'iOS 다운 받기'} fluid size={'large'} as={'a'} href={'https://apps.apple.com/kr/app/id1549349494'} target={'_blank'} /> <br/>
            <Button icon={'google play'} content={'안드로이드 다운받기'} fluid size={'large'} as={'a'}  href={'https://play.google.com/store/apps/details?id=com.readersrnforbusiness'} target={'_blank'}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(SmallScreenInfo);
