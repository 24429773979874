import axios from "axios";
import moment from "moment";
import {auth} from "../constant/firebase";
import {currentBuildNo} from "../constant/buildNo";

const defaultFirebaseUid = "";
const defaultAuthorization = "";

let ENV;
// DEV DB 연결 (readers-stage)
// ENV = 'DEV_LOCAL';
// ENV = 'DEV_SERVER';

// PROD DB 연결
// ENV = 'STAGE_LOCAL';
// ENV = 'STAGE_SERVER'; // STAGE DB 연결
ENV = 'PROD_LOCAL';
ENV = 'PROD_SERVER';

let API_END_DEFAULT;
switch (ENV) {
  case 'DEV_LOCAL':
    API_END_DEFAULT = "http://localhost:5000/readers-dev/asia-northeast1";
    break;

  case 'DEV_SERVER':
    API_END_DEFAULT = "https://asia-northeast1-readers-dev.cloudfunctions.net";
    break;

  case 'STAGE_LOCAL':
    API_END_DEFAULT = "http://localhost:5000/readers-stage/asia-northeast1";
    break;

  case 'STAGE_SERVER':
    API_END_DEFAULT = "https://asia-northeast1-readers-stage.cloudfunctions.net";
    break;

  case 'PROD_LOCAL':
    API_END_DEFAULT = "http://localhost:5000/readers-prod/asia-northeast1";
    break;

  case 'PROD_SERVER':
    API_END_DEFAULT = "https://asia-northeast1-readers-prod.cloudfunctions.net";
    break;
}

let API_BIZ = API_END_DEFAULT + '/apiBiz/v1';

let devMode = false;
devMode = true;

class Network_B2B {

  constructor() {/**/
    this.headers = {
      firebaseUid: auth?.currentUser?.uid || "",//defaultFirebaseUid,
      Authorization: defaultAuthorization,

      currentBuildNo: currentBuildNo,
      timezone: 'Asia/Seoul',
      businessId: 1,
      from: 'web',
    };


  }

  setFirebaseUid(uid) {
    this.headers["firebaseUid"] = uid || defaultFirebaseUid;
    // console.log('firebaseUid :', this.headers['firebaseUid']);
  }

  getFirebaseUid(uid) {
    return this.headers["firebaseUid"];
  }

  onError(e, url, method, data) {
    let errorMessage = "오류가 발생하였습니다.\n고객센터에 문의해주세요";
    if (e.response) {
      // devMode && console.log(`[RESPONSE] ${url} ${e.response.status}`, e.response.data);
      if (e.response?.data?.userMessage) {
        errorMessage = e.response.data.userMessage;
      }
    } else {
      devMode && console.log("[RESPONSE]", url, e);
    }

    try {

    } catch (error) {

    }

    // try {
    //   // db.collection('error')
    //   //   .add({
    //   //     firebaseUid: this.headers['firebaseUid'],
    //   //     rawMessage: e.message,
    //   //     userMessage: errorMessage,
    //   //   })
    // } catch (e) {
    //   console.log(e);
    // }

    window.alert(errorMessage);
    return {
      err: true,
      errorMessage,
    };
  }

  _sendRequest(url, params, method, headers) {
    const a = moment();
    devMode && console.log("[Request]", method.toUpperCase(), url, params);
    return axios[method](API_BIZ + url, {headers: headers || this.headers, params}).then(response => {
      // devMode && console.log(moment().diff(a, "millisecond").toString(), "[Response]", method.toUpperCase(), url);
      devMode && console.log(moment().diff(a, 'millisecond').toString(), '[Response]', method.toUpperCase(), url, response.data);

      return response.data;
    }).catch(e => this.onError(e, url, method, params));
  }

  // post, put
  _sendRequestForData(url, data, method, headers) {
    const a = moment();
    devMode && console.log("[Request]", method.toUpperCase(), url, data);
    return axios[method](API_BIZ + url, data, {headers: {...this.headers, ...headers}}).then(response => {
      // devMode && console.log(moment().diff(a, "millisecond").toString(), "[Response]", method.toUpperCase(), url);
      devMode && console.log(moment().diff(a, 'millisecond').toString(), '[Response]', method.toUpperCase(), url, response.data);

      return response.data;
    }).catch(e => this.onError(e, url, method, data));
  }

  _get = (url, params, headers) => this._sendRequest(url, params, "get", headers);
  _post = (url, data) => this._sendRequestForData(url, data, "post");

  _delete = (url, params) => this._sendRequest(url, params, "delete");
  _put = (url, data, headers) => this._sendRequestForData(url, data, "put", headers);


  // auth
  signInWithSignInIdAndPassword = (signInId, password, businessId) => this._post("/auth/sign-in", {signInId, password, businessId});


  // book
  getBookById = (bookId) => this._get("/book", {bookId});
  getBookForBusinessBookRequest = (bookId) => this._get("/book/for-business-book-request", {bookId});
  getBookListBookCategoryBestSeller = (bookCategoryId) => this._get("/book/list/book-category/best-seller", {bookCategoryId});
  getBookListBookCategoryNew = (bookCategoryId) => this._get("/book/list/book-category/new", {bookCategoryId});

  getBookListMy = () => this._get("/book/list/my");
  getBookListUser = (userId) => this._get("/book/list/user", {userId});
  getBookListBookCategoryGroup = (bookCategoryGroupId, offset, limit) => this._get("/book/list/book-category-group", {bookCategoryGroupId, offset, limit});
  getBookListUserBookCategoryGroup = (userId, bookCategoryGroupId) => this._get("/book/list/user/book-category-group", {userId, bookCategoryGroupId});
  getBookListHasScrapUser = () => this._get("/book/list/has-scrap/user");

  // book-category
  getBookCategory = (bookCategoryId) => this._get("/book-category", {bookCategoryId});
  getBookCategoryList = () => this._get("/book-category/list", {});

  // book-category-group
  getBookCategoryGroup = (bookCategoryGroupId) => this._get("/book-category-group", {bookCategoryGroupId});

  // bookmark
  getBookmarkListMy = (lastVisibleItemCreatedAt, limit) => this._get("/bookmark/list/my", {lastVisibleItemCreatedAt, limit});
  postBookmark = (itemId, itemType) => this._post("/bookmark", {itemId, itemType});
  deleteBookmark = (itemId, itemType) => this._delete("/bookmark", {itemId, itemType});

  // book-forum
  // business-book-forum
  getBusinessBookForum = (businessBookForumId) => this._get("/business-book-forum", {businessBookForumId});
  postBusinessBookForum = (data, selectedUserIds) => this._post("/business-book-forum", {data, selectedUserIds});
  putBusinessBookForum = (businessBookForumId, data) => this._put("/business-book-forum", {businessBookForumId, data});
  deleteBusinessBookForum = (businessBookForumId) => this._delete("/business-book-forum", {businessBookForumId});
  getBusinessBookForumMain = () => this._get("/business-book-forum/main");
  getBusinessBookForumMemberList = (businessBookForumId, businessBookForumMemberTypeId) => this._get("/business-book-forum/member/list", {businessBookForumId, businessBookForumMemberTypeId});
  postBusinessBookForumMemberInvite = (businessBookForumId, userIds) => this._post("/business-book-forum/member/invite", {businessBookForumId, userIds});
  postBusinessBookForumMemberDelete = (businessBookForumId, userIds) => this._post("/business-book-forum/member/delete", {businessBookForumId, userIds});
  postBusinessBookForumRequestCertificate = (businessBookForumId, data) => this._post('/business-book-forum/request-certificate', {businessBookForumId, data})

  deleteBusinessBookForumMemberApply = (businessBookForumMemberId) => this._delete("/business-book-forum/member/apply", {businessBookForumMemberId});
  postBusinessBookForumMemberApply = (businessBookForumId) => this._post("/business-book-forum/member/apply", {businessBookForumId});

  postBusinessBookForumMemberInviteAccept = (businessBookForumMemberId) => this._post("/business-book-forum/member/invite-accept", {businessBookForumMemberId});
  postBusinessBookForumMemberApplyAccept = (businessBookForumId) => this._post("/business-book-forum/member/apply-accept", {businessBookForumId});

  getBusinessBookForumBookList = (bookId) => this._get("/business-book-forum/book/list", {bookId});
  getBusinessBookForumUserSearch = (bookId, keyword) => this._get("/business-book-forum/user-search", {bookId, keyword});

  // business-book-request
  getBusinessBookRecommendationCollection = (businessBookRecommendationCollectionId) => this._get("/business-book-recommendation-collection", {businessBookRecommendationCollectionId});


  // business-book-request
  getBusinessBookRequest = (businessBookRequestId) => this._get("/business-book-request", {businessBookRequestId});
  deleteBusinessBookRequest = (businessBookRequestId) => this._delete("/business-book-request", {businessBookRequestId});
  postBusinessBookRequest = (data) => this._post("/business-book-request", {data});
  postBusinessBookRequestBookReceived = (businessBookRequestId) => this._post("/business-book-request/book-received", {businessBookRequestId});
  postBusinessBookRequestReturnRequest = (businessBookRequestId) => this._post("/business-book-request/return-request", {businessBookRequestId});
  deleteBusinessBookRequestReturnRequest = (businessBookRequestId) => this._delete("/business-book-request/return-request", {businessBookRequestId});
  getBusinessBookRequestListMy = (offset, limit) => this._get("/business-book-request/list/my", {offset, limit});
  getBusinessBookRequestIngMy = () => this._get("/business-book-request/ing/my", {});

  // business-book-voucher
  getBusinessBookVoucherListMy = (offset, limit) => this._get("/business-book-voucher/list/my", {offset, limit});

  // business-learning-credit
  getBusinessLearningCreditListMy = (offset, limit) => this._get("/business-learning-credit/list/my", {offset, limit});
  getBusinessLearningCreditTypeList = () => this._get("/business-learning-credit/type/list", {});

  // business-member
  postBusinessMemberInitialInfo = () => this._post("/business-member/initial-info");

  // business-notice
  getBusinessNotice = (businessNoticeId) => this._get("/business-notice", {businessNoticeId});
  getBusinessNoticeList = (offset, limit) => this._get("/business-notice/list", {offset, limit});

  // business-notification
  getBusinessNotification = (lastVisibleItemCreatedAt, limit) => this._get("/business-notification/list/my", {lastVisibleItemCreatedAt, limit});
  postBusinessNotificationReadAll = () => this._post("/business-notification/read-all");
  postBusinessNotificationClickAll = () => this._post("/business-notification/click-all");
  postBusinessNotificationClick = (businessNotificationId) => this._post("/business-notification/click", {businessNotificationId});

  // business-point
  getBusinessPointTypeList = () => this._get("/business-point/type/list");
  getBusinessPointMyList = (offset, limit) => this._get("/business-point/list/my", {offset, limit});

  // follow
  getFollowFollowingList = (userId) => this._get("/follow/following/list", {userId});
  getFollowFollowerList = (userId) => this._get("/follow/follower/list", {userId});

  postFollow = (targetUserId) => this._post("/follow", {targetUserId});
  deleteFollow = (targetUserId) => this._delete("/follow", {targetUserId});
  postFollowApprove = (targetUserId) => this._post("/follow/approve", {targetUserId});
  postFollowRemove = (targetUserId) => this._post("/follow/remove", {targetUserId});


  // feed
  getFeedFollow = (lastItemCreatedAt, perPage) => this._get("/feed/follow", {lastItemCreatedAt, perPage});
  getFeedRecommend = (lastItemCreatedAt, perPage) => this._get("/feed/recommend", {lastItemCreatedAt, perPage});

  // home
  getHome = () => this._get("/home");

  // inquiry
  getInquiry = (inquiryId) => this._get("/inquiry", {inquiryId});
  getInquiryListMy = (offset, limit) => this._get("/inquiry/list/my", {offset, limit});
  postInquiry = (data) => this._post("/inquiry", {data});

  // push-notification
  getPushNotificationList = () => this._get("/push-notification/list", {});
  putPushNotificationMy = (pushNotificationId, enabled) => this._put("/push-notification/my", {pushNotificationId, enabled});


  // recommendation
  postRecommendationBook = (bookIdsShowAlready, bookId, recommendationType, bookCategoryId) => this._post("/recommendation/book/", {bookIdsShowAlready, bookId, recommendationType, bookCategoryId});
  getRecommendationBook = (recommendationBookType, offset, limit) => this._get("/recommendation/book/", {recommendationBookType, offset, limit});
  getRecommendationBookList = () => this._get("/recommendation/book/list", {});
  getRecommendationBookListForInit = () => this._get("/recommendation/book/list/for-init", {});
  postRecommendationBookListForInit = (bookIdsShowAlready) => this._post("/recommendation/book/list/for-init", {bookIdsShowAlready});
  getRecommendationUser = (recommendationUserType, offset, limit) => this._get("/recommendation/user/", {recommendationUserType, offset, limit});
  getRecommendationUserList = () => this._get("/recommendation/user/list", {});

  // relation-user-book
  getRelationUserBook = (bookId) => this._get("/relation-user-book", {bookId});
  putRelationUserBook = (bookId, data) => this._put("/relation-user-book", {bookId, data});
  deleteRelationUserBook = (bookId) => this._delete("/relation-user-book", {bookId});
  getRelationUserBookList = (bookId) => this._get("/relation-user-book/book/list", {bookId});

  // relation-user-book-category
  postRelationUserBookCategory = (bookCategoryId) => this._post("/relation-user-book-category", {bookCategoryId});
  deleteRelationUserBookCategory = (bookCategoryId) => this._delete("/relation-user-book-category", {bookCategoryId});


  // scrap
  getScrap = (scrapId) => this._get("/scrap", {scrapId});
  putScrap = (scrapId, data) => this._put("/scrap", {scrapId, data});
  postScrap = (data) => this._post("/scrap", {data});
  deleteScrap = (scrapId) => this._delete("/scrap", {scrapId});

  postScrapLike = (scrapId) => this._post("/scrap/like", {scrapId});
  deleteScrapLike = (scrapId) => this._delete("/scrap/like", {scrapId});

  getScrapLikeUserList = (scrapId) => this._get("/scrap/like-user/list", {scrapId});
  getScrapListBookUser = (bookId, userId) => this._get("/scrap/list/book/user", {bookId, userId});
  getScrapListBook = (bookId, offset, limit, orderBy) => this._get("/scrap/list/book", {bookId, offset, limit, orderBy});
  getScrapListForCalendar = (selectedDay, userId) => this._get("/scrap/list/for-calendar", {selectedDay, userId});
  getScrapListDay = (selectedDay, userId) => this._get("/scrap/list/day", {selectedDay, userId});

  getScrapListUser = (userId, lastVisibleItemCreatedAt, limit) => this._get("/scrap/list/user", {userId, lastVisibleItemCreatedAt, limit});

  getScrapCommentList = (scrapId) => this._get("/scrap/comment/list", {scrapId});
  postScrapComment = (comment, scrapId, scrapCommentId) => this._post("/scrap/comment", {comment, scrapId, scrapCommentId});
  deleteScrapComment = (scrapCommentId) => this._delete("/scrap/comment", {scrapCommentId});

  postScrapCommentReport = (scrapCommentId) => this._post("/scrap/comment/report", {scrapCommentId});


  readScrap = (scrapId, startTime, endTime, milliseconds) => axios.post(API_BIZ + "/scrap/read", {
    scrapId,
    startTime,
    endTime,
    milliseconds,
  }, {
    headers: this.headers,
  }).then(response => response.data).catch(e => console.log(e));
  postScrapReport = (scrapId) => this._post("/scrap/report", {scrapId});

  // search
  getSearch = (params) => this._get("/search", params);
  getSearchKeywordsRecommend = () => this._get("/search/keyword/recommend");

  tempOpenEventGET = (userId) => this._get('/temp/open-event', {userId})

  // user
  getUser = (userId) => this._get("/user", {userId});
  getUserCategoryAnalysis = (userId) => this._get("/user/category-analysis", {userId});


  getUserMy = () => this._get("/user/my");
  putUserMy = (data) => this._put("/user/my", {data});
  getUserMyInit = () => this._get("/user/my/init");

  postUserPhoneVerify = (phoneNumber) => this._post("/user/phone/verify", {phoneNumber});
  putUserPhone = (phoneNumber) => this._put("/user/phone", {phoneNumber});

  putUserPassword = (passwordCurrent, password) => this._put("/user/password", {passwordCurrent, password});

  // user-address
  getUserAddress = (userAddressId) => this._get("/user-address", {userAddressId});
  postUserAddress = (userAddress) => this._post("/user-address", {userAddress});
  putUserAddress = (userAddressId, userAddress) => this._put("/user-address", {userAddressId, userAddress});
  deleteUserAddress = (userAddressId) => this._delete("/user-address", {userAddressId});

  getUserAddressList = () => this._get("/user-address/list/my");

  getUserAddressSearch = (keyword, page, perPage) => this._get("/user-address/search", {keyword, page, perPage});

  postUserToken = (token, os) => this._post("/user-token", {token, os});
  deleteUserToken = (token) => this._delete("/user-token", {token});


}

export default new Network_B2B();
