import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Modal, Popup } from "semantic-ui-react";
import Network_B2B from "../../library/Network_B2B";

const UserComponent = (props) => {

  const history = useHistory();
  const [user, setUser] = useState(props.user || {});
  const [isLoaded, setIsLoaded] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);


  const _openModal = () => {
    setModalOpen(true);
  };
  const _closeModal = () => {
    setModalOpen(false);
  };

  const _loadUser = async () => {
    const res = await Network_B2B.getUser(user.id);
    if (!res.err) {
      setUser(res.user);
      setIsLoaded(true);
    }
  };

  const _requestFollow = async () => {
    setFetching(true);
    const res = await Network_B2B.postFollow(user.id);
    if (!res.err) {
      setUser(prevState => ( { ...prevState, relationFollow: res.relationFollow } ));
    }
    setFetching(false);
  };
  const _requestUnfollow = async () => {
    const a = window.confirm("팔로우를 취소하시겠습니까?");
    if (!a) return;

    setFetching(true);
    const res = await Network_B2B.deleteFollow(user.id);
    if (!res.err) {
      setUser(prevState => ( { ...prevState, relationFollow: {} } ));
    }
    setFetching(false);
  };

  const _handleGoToDetailContainer = () => {
    history.push(`/user/${user.id}`);
  };
  useEffect(() => {

  }, [modalOpen]);
  useEffect(() => {
    setUser(props.user);
  }, [props.user?.id]);


  const _renderModal = () => {
    return (
      <Modal
        closeIcon
        onClose={_closeModal}
        open={modalOpen}>
        <Modal.Content style={{
          height: "600px",
        }}>
          <div style={{
            backgroundImage: `url(${user?.photoUrl})`,
            backgroundSize: "50px",
            width: "50px",
            height: "50px",
            borderRadius: "20px",
          }}>

          </div>
          <p>
            최근 올린 스크랩
          </p>
          <p>
            최근 읽은 책들
          </p>
          <p>
            팔로우 팔로잉
          </p>
          <Button
            onClick={_handleGoToDetailContainer}
            content={"자세히 보기"}/>
        </Modal.Content>
        {/*<Modal.Actions>*/}
        {/*<Button content={"닫기"} onClick={_closeModal}/>*/}
        {/*</Modal.Actions>*/}
      </Modal>
    );
  };
  const _renderFollowButton = () => {
    if (props.authReducer?.user?.id === user.id) {
      return null;
    } else if (!user?.relationFollow?.id) {
      return (
        <Button primary
                content={"팔로우"}
                loading={fetching}
                disabled={fetching}
                onClick={_requestFollow}
        />
      );
    } else if (!!user?.relationFollow?.isApproved) {
      return (
        <Button primary
                content={"팔로잉"}
                basic
                loading={fetching}
                disabled={fetching}
                onClick={_requestUnfollow}
        />
      );
    } else {
      return (
        <Button primary
                content={"승인대기중"}
                basic
                disabled={fetching}
                onClick={_requestUnfollow}
        />
      );
    }

  };

  switch (props.viewType) {

    case"":
      return (
        <div>
          {user.id}
        </div>
      );

    default:
      const d = 40;
      return (
        <>
          <Popup
            pinned
            on={"click"}
            position='bottom left'
            hoverable
            wide={"very"}
            style={{
              padding: "1rem",
              minWidth: "400px",
            }}
            trigger={!!props.children ? ( props.children ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "1rem",
                  cursor: "pointer",
                }}>
                <div style={{
                  width: `${d}px`,
                  height: `${d}px`,
                  backgroundImage: `url(${user?.photoUrl})`,
                  backgroundColor: "grey",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: `${d}px ${d}px`,
                  backgroundPosition: "center",
                  borderRadius: `${d / 2}px`,
                  marginRight: "1rem",
                }}/>
                <div style={{
                  // backgroundColor: "red",
                  flex: 1,
                }}>
                  <p style={{ fontWeight: "bold" }}>
                    {user?.name}
                    <p style={{ color: "grey", fontWeight: "400" }}>{user?.businessDepartment?.title}</p>
                  </p>
                </div>
                {
                  !!props.showFollowButton && _renderFollowButton()
                }

              </div>
            )}>
            <Popup.Header>
              {user?.name}
            </Popup.Header>
            <Popup.Content>
              <div style={{
                backgroundImage: `url(${user?.photoUrl})`,
                backgroundSize: "50px",
                width: "50px",
                height: "50px",
                borderRadius: "20px",
              }}>

              </div>
              <p>
                최근 올린 스크랩
              </p>
              <p>
                최근 읽은 책들
              </p>
              <p>
                팔로우 팔로잉
              </p>
              <p>
                팔로우 버튼
              </p>
              <Button
                onClick={_handleGoToDetailContainer}
                content={"자세히 보기"}/>
            </Popup.Content>
          </Popup>
        </>
      );
  }


};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(UserComponent);
