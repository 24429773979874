import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Container, Divider, Segment} from "semantic-ui-react";
import ChannelService from "../../library/ChannelService";

const HelpManualContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div style={{maxWidth: '500px', margin: 'auto'}}>
      <Container as={Segment} vertical>
        <img src={'/manual/1.png'} width={'100%'}/>
        <img src={'/manual/3.png'} width={'100%'}/>
        <img src={'/manual/4.png'} width={'100%'}/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <img src={'/manual/2.png'} width={'100%'}/>
        <img src={'/manual/5.png'} width={'100%'}/>
        <img src={'/manual/6.png'} width={'100%'}/>
        <img src={'/manual/7.png'} width={'100%'}/>
        <img src={'/manual/8.png'} width={'100%'}/>
      </Container>
    </div>

  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(HelpManualContainer);
