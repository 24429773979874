export const authActions = {
  APP_INITIALIZE: "APP_INITIALIZE",

  SIGN_IN: "SIGN_IN",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",

  SIGN_OUT: "SIGN_OUT",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",

  COMPLETE_ADDITION_INFO: "COMPLETE_ADDITION_INFO",

  CHECK_NOTIFICATION_ENABLED: "CHECK_NOTIFICATION_ENABLED",
  CHECK_NOTIFICATION_ENABLED_SUCCESS: "CHECK_NOTIFICATION_ENABLED_SUCCESS",

  REQUEST_NOTIFICATION: "REQUEST_NOTIFICATION",
  REQUEST_NOTIFICATION_SUCCESS: "REQUEST_NOTIFICATION_SUCCESS",


  FETCH_BUSINESS_NOTIFICATION: "FETCH_BUSINESS_NOTIFICATION",
  FETCH_BUSINESS_NOTIFICATION_SUCCESS: "FETCH_BUSINESS_NOTIFICATION_SUCCESS",

  FETCH_MY_INFO: "FETCH_MY_INFO",
  FETCH_MY_INFO_SUCCESS: "FETCH_MY_INFO_SUCCESS",

  FETCH_MY_INFO_INIT: "FETCH_MY_INFO_INIT",
  FETCH_MY_INFO_INIT_SUCCESS: "FETCH_MY_INFO_INIT_SUCCESS",

  FETCH_MY_FOLLOWER: "FETCH_MY_FOLLOWER",
  FETCH_MY_FOLLOWER_SUCCESS: "FETCH_MY_FOLLOWER_SUCCESS",
  FETCH_MY_FOLLOWING: "FETCH_MY_FOLLOWING",
  FETCH_MY_FOLLOWING_SUCCESS: "FETCH_MY_FOLLOWING_SUCCESS",

  FETCH_MY_BOOK: "FETCH_MY_BOOK",
  FETCH_MY_BOOK_SUCCESS: "FETCH_MY_BOOK_SUCCESS",

  FETCH_BUSINESS_BOOK_REQUEST_ING: "FETCH_BUSINESS_BOOK_REQUEST_ING",
  FETCH_BUSINESS_BOOK_REQUEST_ING_SUCCESS: "FETCH_BUSINESS_BOOK_REQUEST_ING_SUCCESS",
};

export const authActionCreators = {
  appInitialize: (cb) => ({ type: authActions.APP_INITIALIZE, cb }),

  signIn: (signInId, password, businessId, cb) => ({ type: authActions.SIGN_IN, signInId, password, businessId, cb }),
  signInSuccess: (user, cb) => ({ type: authActions.SIGN_IN_SUCCESS, user, cb }),
  signInFailure: (userMessage) => ({ type: authActions.SIGN_IN_FAILURE, userMessage }),
  signOut: (cb) => ({ type: authActions.SIGN_OUT, cb }),
  signOutSuccess: (cb) => ({ type: authActions.SIGN_OUT_SUCCESS, cb }),

  completeAdditionalInfo: (cb) => ({ type: authActions.COMPLETE_ADDITION_INFO, cb }),

  checkNotificationEnabled: (cb) => ({ type: authActions.CHECK_NOTIFICATION_ENABLED, cb }),
  checkNotificationEnabledSuccess: (notificationEnabled, cb) => ({ type: authActions.CHECK_NOTIFICATION_ENABLED_SUCCESS, notificationEnabled, cb }),

  requestNotification: (cb) => ({ type: authActions.REQUEST_NOTIFICATION, cb }),
  requestNotificationSuccess: (notificationEnabled, cb) => ({ type: authActions.REQUEST_NOTIFICATION_SUCCESS, notificationEnabled, cb }),


  fetchBusinessNotifications: (cb) => ({ type: authActions.FETCH_BUSINESS_NOTIFICATION, cb }),
  fetchBusinessNotificationsSuccess: (businessNotifications, cb) => ({ type: authActions.FETCH_BUSINESS_NOTIFICATION_SUCCESS, businessNotifications, cb }),

  fetchMyInfo: (cb) => ({ type: authActions.FETCH_MY_INFO, cb }),
  fetchMyInfoSuccess: (user) => ({ type: authActions.FETCH_MY_INFO_SUCCESS, user }),

  fetchMyInfoInit: (cb) => ({ type: authActions.FETCH_MY_INFO_INIT, cb }),
  fetchMyInfoInitSuccess: (user, additionalInfoNeeded) => ({ type: authActions.FETCH_MY_INFO_INIT_SUCCESS, user, additionalInfoNeeded }),

  fetchMyFollower: (cb) => ({ type: authActions.FETCH_MY_FOLLOWER, cb }),
  fetchMyFollowerSuccess: (followers, cb) => ({ type: authActions.FETCH_MY_FOLLOWER_SUCCESS, cb, followers }),
  fetchMyFollowing: (cb) => ({ type: authActions.FETCH_MY_FOLLOWING, cb }),
  fetchMyFollowingSuccess: (followings, cb) => ({ type: authActions.FETCH_MY_FOLLOWING_SUCCESS, cb, followings }),

  fetchMyBook: (cb) => ({ type: authActions.FETCH_MY_BOOK, cb }),
  fetchMyBookSuccess: (books, cb) => ({ type: authActions.FETCH_MY_BOOK_SUCCESS, books, cb }),

  fetchBusinessBookRequestIng: (cb) => ({ type: authActions.FETCH_BUSINESS_BOOK_REQUEST_ING, cb }),
  fetchBusinessBookRequestIngSuccess: (businessBookRequestIng, cb) => ({ type: authActions.FETCH_BUSINESS_BOOK_REQUEST_ING_SUCCESS, businessBookRequestIng, cb }),
};

const initialState = {
  isLoggedIn: false,
  additionalInfoNeeded: true,

  notificationEnabled: false,

  user: {
    imageUrl: '',
    phoneNumber: '',
    businessMember: {},
    businessDepartment: {},
    gender: "",
    birthYear: null,

    numberOfScraps: 0,
    numberOfRelationUserBooks: 0,
    numberOfBookmarks: 0,

    businessBookVoucher: {
      all: 0,
      numberOfLeft: 0,
    },
    businessPoint: {
      left: 0,
      accAll: 0,
      accMonth: 0,
      accDay: 0,
    },
    businessBookRequestStat: {
      count: 0,
    },
    businessLearningCreditStat: {
      sum: 0,
    },
  },
  businessBookRequestIng: {},

  books: [],
  isMyBookLoaded: false,

  followings: [],
  followers: [],
  isFollowerLoaded: false,
  isFollowingLoaded: false,

  businessNotifications: [],
  isNotificationLoaded: [],
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {

    case authActions.FETCH_MY_INFO_INIT_SUCCESS:
      return {
        ...state,
        user: action.user || {},
        isLoggedIn: true,
        additionalInfoNeeded: action.additionalInfoNeeded,
      };
    case authActions.CHECK_NOTIFICATION_ENABLED_SUCCESS:
      return {
        ...state,
        notificationEnabled: action.notificationEnabled,
      };
    case authActions.REQUEST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationEnabled: action.notificationEnabled,
      };

    case authActions.FETCH_BUSINESS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        businessNotifications: action.businessNotifications,
      };

    case authActions.SIGN_IN_SUCCESS:
      return {
        ...state,
        // user: action.user || {},
        // isLoggedIn: true,
      };
    case authActions.SIGN_OUT_SUCCESS :
      return initialState;

    case authActions.FETCH_MY_INFO_SUCCESS:
      return {
        ...state,
        user: action.user || {},
      };
    case authActions.COMPLETE_ADDITION_INFO:
      return {
        ...state,
        additionalInfoNeeded: false,
      };

    case authActions.FETCH_MY_FOLLOWER_SUCCESS:
      return {
        ...state,
        followers: action.followers,
        isFollowerLoaded: true,
      };

    case authActions.FETCH_MY_FOLLOWING_SUCCESS:
      return {
        ...state,
        followings: action.followings,
        isFollowingLoaded: true,
      };
    case authActions.FETCH_MY_BOOK_SUCCESS:
      return {
        ...state,
        books: action.books,
        isMyBookLoaded: true,
      };


    case authActions.FETCH_BUSINESS_BOOK_REQUEST_ING_SUCCESS:
      return {
        ...state,
        businessBookRequestIng: action.businessBookRequestIng,
      };
    case authActions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};
