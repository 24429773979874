export const mainColor = '#54AB34'
export const kyoboThemeBlue = '#003975'
export const buttonColor = "#52A2FF";


export default {
  buttonColor,
  mainColor,
  kyoboThemeBlue,
}
