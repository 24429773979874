import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from "../../component/shared/LoaderComponent";
import Network_B2B from "../../library/Network_B2B";
import moment from 'moment';
import numeral from 'numeral'
import {Button, Container, Divider, Form, Grid, Header, Image, List, Message, Modal, Rating, Segment} from "semantic-ui-react";
import {useQuery} from "../../library/useQuery";
import {useParams} from 'react-router-dom'
import {removeHtmlTag, removeHtmlTagWithLineChange, removeOnlyHtmlTag, withLineEnter} from "../../library/TextHandler";
import {authActionCreators} from "../../redux/auth/authStore";
import LinesEllipsis from "react-lines-ellipsis";
import ReadMoreComponent from "../../component/shared/ReadMoreComponent";

const BookMainContainer = (props) => {

  const {bookId} = useParams();

  const [isLoaded, setIsLoaded] = useState(false)
  const [book, setBook] = useState({})


  const [phoneNumberModalOpen, setPhoneNumberModalOpen] = useState(false);
  const [codeFetching, setCodeFetching] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [verifyCode, setVerifyCode] = useState("");
  const [code, setCode] = useState("");

  const [countDownSeconds, setCountDownSeconds] = useState(120);
  const [countingDown, setCountingDown] = useState(false);
  const [isVerifyCodeReceived, setIsVerifyCodeReceived] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if(!isLoaded){
      window.scrollTo(0, 0)
    }
    _load()

    props.fetchMyInfo();
  }, [])

  const _load = async () => {
    const res = await Network_B2B.getBookById(bookId);
    if (!res.err) {
      setIsLoaded(true)
      setBook(res.book);
    }
  }

  // 전화 번호 인증 관련

  useEffect(() => {
    let interval;
    if (isVerifyCodeReceived) {
      interval = setInterval(() => {
        setCountDownSeconds(seconds => seconds - 1);
        console.log("interval");
      }, 1000);
    } else {
    }
    return () => {
      clearInterval(interval);
    };
  }, [isVerifyCodeReceived]);

  useEffect(() => {
    let resetTime;
    if (isVerifyCodeReceived) {
      resetTime = setTimeout(() => {
        _handleCountDownStop();
        console.log("resetTimeout");
      }, 120000);
    } else {
      clearTimeout(resetTime);
    }
    return () => {
      clearTimeout(resetTime);
    };
  }, [isVerifyCodeReceived]);

  const _handleUpdate = async () => {
    setUploading(true);

    const res = await Network_B2B.putUserPhone(phoneNumber);
    props.fetchMyInfo();
    setUploading(false);
    if (!res.err) {
      setPhoneNumberModalOpen(false);
      window.alert('책을 신청해 주세요.')
    }
  };


  const _requestVerifyCode = async (e) => {
    e.preventDefault()

    if (!phoneNumber || phoneNumber.trim() === "" || phoneNumber.length < 10) {
      return window.alert("번호를 바르게 입력해 주세요");
    }
    setCodeFetching(true);
    const res = await Network_B2B.postUserPhoneVerify(phoneNumber);
    setCodeFetching(false);
    if (!res.err) {
      setVerifyCode(res.code);
      setIsVerifyCodeReceived(true);
      _handleCountDownStart();
    }
  };

  const _handleCountDownStart = () => {
    setCountDownSeconds(120);
    setCountingDown(true);
  };

  const _handleCountDownStop = () => {
    setCountDownSeconds(120);
    setCountingDown(false);
    setIsVerifyCodeReceived(false);
    setVerifyCode("");
  };


  const _handleGoToBookRequest = () => {
    if (!book.businessBookRequestAvailable) return window.alert(book.businessBookRequestUserMessage);

    if (!props.authReducer?.user?.phoneNumberVerified) {
      setPhoneNumberModalOpen(true);
    } else {
      props.history.push(`/book/for-request?bookId=${book.id}`)
    }

  }

  if (!isLoaded) return <LoaderComponent/>;

  const _renderPhoneNumberModal = () => {
    return (
      <Modal open={phoneNumberModalOpen} size={'tiny'}>
        <Modal.Header content={'전화번호 인증'}/>
        <Modal.Content style={{minHeight: '400px'}}>
          <Message info>
            <b>배송상태 알림등을 위해 전화번호가 필요합니다.</b>
            <Message.List bulleted>
              <Message.Item>최초 1회</Message.Item>
            </Message.List>

          </Message>
          <Form>
            <Form.Input
              label={'전화번호'}
              action
              placeholder={"전화번호 (숫자로만 입력 해주세요. 예, 01099991234)"}
              value={phoneNumber}
              onChange={(e, {value}) => {
                if (!/^[0-9]*$/.test(value)) {
                  return;
                }
                setPhoneNumber(value.trim());
              }}
            >
              <input/>
              <Button
                disabled={codeFetching || isVerifyCodeReceived}
                onClick={_requestVerifyCode} content={'인증번호요청'}/>
            </Form.Input>

            {
              !!isVerifyCodeReceived && (
                <>
                  <Form.Input
                    value={code}
                    placeholder={`인증번호`}
                    // onSubmitEditing={this.handleConfirm}
                    onChange={(e, {value}) => {
                      if (!/^[0-9]*$/.test(value)) {
                        return;
                      }
                      setCode(value.trim());
                    }}
                  />
                  <p>
                    {countDownSeconds}초 이내에 입력해야 합니다.
                  </p>

                  <Button fluid
                          primary
                          content={'확인'}
                          onClick={_handleUpdate}
                          disabled={((code !== verifyCode) || verifyCode === "" || uploading)}
                          loading={uploading}
                  />
                </>
              )
            }

          </Form>


        </Modal.Content>
        <Modal.Actions>
          <Button content={'취소'} basic negative onClick={() => {
            setPhoneNumberModalOpen(false)
          }}/>
        </Modal.Actions>
      </Modal>
    )
  }

  return (
    <>
      {_renderPhoneNumberModal()}
      <Container as={Segment} vertical basic text>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4} style={{}}>
              <Image src={book.coverImageUrl} size={'small'} style={{}} floated={'right'}/>
            </Grid.Column>
            <Grid.Column width={12}>
              <Header>
                {book.title}
              </Header>
              <p>
                {book.author} <br/>
                {book.publisher} {book.pubDate}
              </p>
              <p>
                {numeral(book.product?.price).format('0,0')}원
              </p>
              {/*<Rating icon={'star'} maxRating={5} size={'large'}/>*/}
              <p>
                <Button
                  primary
                  basic
                  floated={'right'}
                  onClick={_handleGoToBookRequest}
                  content={!!book.businessBookRequestAvailable ? '신청하기' : book.businessBookRequestUserMessage}/>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Container as={Segment} vertical basic style={{marginTop: '3em'}} text>
        <Divider section/>
        <Header content={'도서 정보'}/>
        <p>
          {book.description}
        </p>
        <List bulleted>
          <List.Item>
            페이지: {book.page}
          </List.Item>
          <List.Item>
            저자: {book.author}
          </List.Item>
          <List.Item>
            출판사: {book.publisher}
          </List.Item>
          <List.Item>
            출간일: {book.pubDate}
          </List.Item>
          <List.Item>
            가격: {numeral(book.price).format('0,0')}원
          </List.Item>
          <List.Item>
            카테고리: {book.categoryNameAladin}
          </List.Item>
        </List>
      </Container>
      <Container as={Segment} vertical basic style={{marginTop: '3em'}} text>
        <Divider section/>
        <Header content={'목차'}/>
        <ReadMoreComponent
          lines={5}
          // showLess={false}
          style={{ marginTop: "0.5rem" }}
          children={removeHtmlTagWithLineChange(book.toc)}
        />
        {/*<ReadMoreComponent/>*/}
        {/*  {withLineEnter(removeHtmlTagWithLineChange(book.toc))}*/}
      </Container>
      <Container as={Segment} vertical basic style={{marginTop: '3em'}} text>
        <Divider section/>
        <Header content={'상세설명'}/>
        <ReadMoreComponent
          lines={5}
          // showLess={false}
          style={{ marginTop: "0.5rem" }}
          children={removeHtmlTagWithLineChange(book.fullDescription)}
        />
        {/*<p>
          {withLineEnter(removeHtmlTagWithLineChange(book.fullDescription))}
        </p>*/}
      </Container>
      <Container as={Segment} vertical basic style={{marginTop: '3em'}} text>
        <Header content={'상세설명 by 출판사'}/>
        <ReadMoreComponent
          lines={5}
          // showLess={false}
          style={{ marginTop: "0.5rem" }}
          children={removeHtmlTagWithLineChange(book.fullDescription_2)}
        />
      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);

export default enhance(BookMainContainer);
