import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Container, Segment} from "semantic-ui-react";

const TestContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  useEffect(() => {

  })

  return (
    <Container>
      <Segment style={{
        // paddingBottom: '100%',
        height:'400px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <div style={{flex: 1,}}>
          hello
        </div>
        <div style={{flex: 1, backgroundColor: 'green',}}>

        </div>
        <div>
          hello
        </div>
      </Segment>
    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(TestContainer);
