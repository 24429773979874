import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Card, Header, Icon, Image, Label, Modal, Popup } from "semantic-ui-react";
import Network_B2B from "../../library/Network_B2B";
import _ from "lodash";
import ReadMoreComponent from "../shared/ReadMoreComponent";
import { withLineEnter } from "../../library/TextHandler";
import ReactStars from "react-rating-stars-component";
import LinesEllipsis from "react-lines-ellipsis";
import { useHistory } from "react-router-dom";
import Colors from "../../constant/Colors";


export const getReadStatusText = (status) => {
  switch (status) {
    case "READ_STATUS_BEFORE":
      return {
        text: "읽을래요",
        color: "red",
      };
    case "READ_STATUS_ING":
      return {
        text: "읽는 중",
        color: "orange",
      };
    case "READ_STATUS_DONE":
      return {
        text: "읽었어요",
        color: "blue",
      };
    default:
      return "";
  }
};

const BookComponent = (props) => {

  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [book, setBook] = useState(props.book || {});

  const [detailModalOpen, setDetailModalOpen] = useState(false);

  const { showsUserInfo, showsBookInfo, viewType } = props;

  const _changeReadStatus = async (value) => {
    if (book?.relationUserBook?.readStatus === value) {
      await _deleteRelationUserBook();
    } else {
      book.relationUserBook.readStatus = value;
      book.relationUserBook.rate = book?.relationUserBook?.readStatus === "READ_STATUS_DONE" ? book.relationUserBook?.rate : null;
      setBook(prevState => ( { ...prevState, ...book } ));
      const res = await Network_B2B.putRelationUserBook(book.id, {
        readStatus: value,
        rate: book?.relationUserBook?.readStatus === "READ_STATUS_DONE" ? book.relationUserBook?.rate : null,
      });
    }
  };
  const _handleChangeRate = async (i) => {
    book.relationUserBook.rate = i;
    book.relationUserBook.readStatus = "READ_STATUS_DONE";
    setBook(prevState => ( { ...prevState, ...book } ));
    await _updateRelationUserBook({
      rate: i,
      readStatus: "READ_STATUS_DONE",
    });
  };

  const _updateRelationUserBook = async (data) => {
    await Network_B2B.putRelationUserBook(book.id, data);
  };
  const _deleteRelationUserBook = async () => {
    const a = window.confirm("등록 되어 있는 책을 삭제하시겠습니까? 스크랩은 삭제되지 않습니다.");
    if (!a) return;
    const { err, relationUserBook } = await Network_B2B.deleteRelationUserBook(book.id);
    if (!err) setBook(prevState => ( { ...prevState, relationUserBook } ));
  };

  const _handleOpenDetailModal = () => setDetailModalOpen(true);
  const _handleCloseDetailModal = () => setDetailModalOpen(false);

  const _handleGoToDetailPage = () => {
    history.push(`/book/${book.id}`);
  };


  useEffect(() => {
    setBook(props.book);
  }, [props.book, props.book?.id]);

  const readStatusList = [
    { id: 0, text: "읽을래요", value: "READ_STATUS_BEFORE" },
    { id: 1, text: "읽는 중", value: "READ_STATUS_ING" },
    { id: 2, text: "읽었어요", value: "READ_STATUS_DONE" },
  ];

  const _renderRelationUserBook = () => {
    return (
      <div style={{}}>
        <div style={{}}>
          {
            readStatusList.map((status, i) => {
              return (
                <Button key={i}
                        onClick={() => _changeReadStatus(status.value)}
                        content={status.text}
                        color={"blue"}
                        basic={book?.relationUserBook?.readStatus !== status.value}/>
              );
            })
          }
        </div>
        {_renderRelationUserBookStarRate()}
      </div>
    );
  };
  const _renderRelationUserBookStarRate = () => {
    if (book?.relationUserBook?.readStatus !== "READ_STATUS_DONE") {
      return null;
    }
    return (
      <div style={{ paddingTop: "1rem" }}>
        <ReactStars
          count={5}
          value={book.relationUserBook?.rate || 0}
          isHalf
          a11y={false}
          size={20}
          onChange={newRate => {
            console.log(newRate);
            _handleChangeRate(newRate);
          }}
        />
      </div>
    );
  };
  const _renderTargetUserRelationUserBook = () => {
    if (!book?.targetUserRelationUserBook) return null;
    const readStatusInfo = getReadStatusText(book?.targetUserRelationUserBook.readStatus);
    switch (viewType) {
      case "CARD_GRID":
        return (
          <div>
            <div style={{ display: "flex" }}>
              <Label>
                {readStatusInfo.text}
                {
                  ( book.targetUserRelationUserBook?.readStatus === "READ_STATUS_DONE" ) && (
                    <>
                      {" "}<Icon name={"star"}/>{book.targetUserRelationUserBook?.rate || 0}
                    </>
                  )
                }
              </Label>

              {
                !!book.targetUserRelationUserBook?.review && (
                  <Popup
                    pinned
                    on={"click"}
                    position='bottom left'
                    hoverable
                    wide={"very"}
                    trigger={( <Label content={"리뷰"} icon={"comment"}/> )}>
                    <Popup.Content>
                      {withLineEnter(book.targetUserRelationUserBook?.review)}
                    </Popup.Content>
                  </Popup>
                )
              }
            </div>
          </div>
        );
      default:
        return (
          <div>
            <div style={{ display: "flex" }}>
              <Label content={readStatusInfo.text}
                // color={readStatusInfo.color}
              />{" "}
              {
                ( book.targetUserRelationUserBook?.readStatus === "READ_STATUS_DONE" ) && (
                  <Label>
                    {book.targetUser?.name}님의 평점 {" "}
                    <Icon name={"star"}/>{book.targetUserRelationUserBook?.rate || 0}
                  </Label>
                )
              }
              {
                !!book.targetUserRelationUserBook?.review && (
                  <Popup
                    pinned
                    on={"click"}
                    position='bottom left'
                    hoverable
                    wide={"very"}
                    trigger={( <Label content={"리뷰"} icon={"comment"}/> )}>
                    <Popup.Content>
                      {withLineEnter(book.targetUserRelationUserBook?.review)}
                    </Popup.Content>
                  </Popup>
                )
              }
            </div>
          </div>
        );
    }

  };

  const _renderDetailModal = () => {
    return (
      <Modal open={detailModalOpen}
             onClose={_handleCloseDetailModal}
      >
        <Modal.Content style={{ display: "flex", flexDirection: "row", height: "300px" }}>
          <div style={{ width: "100px" }}>
            <img src={book?.coverImageUrl} width={"100%"}/>
          </div>
          <div style={{ flex: 1, backgroundColor: "grey" }}>
            dfs
          </div>
        </Modal.Content>
      </Modal>
    );
  };


  switch (viewType) {
    case "CARD_GRID":
      return (
        <>
          <Popup
            pinned
            on={"click"}
            // position='bottom left'
            hoverable
            wide={"very"}
            style={{
              // padding: "1rem",
              // minWidth: "400px",
            }}
            trigger={(
              <Card style={{ cursor: "pointer" }}>
                <Card.Content style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center" }}>
                  <Image src={book.coverImageUrl} size={"tiny"}/>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <LinesEllipsis
                      maxLine={1}
                      basedOn='letters'
                      text={book.title}
                    />
                  </div>
                </Card.Content>
                <Card.Content extra>
                  {_renderTargetUserRelationUserBook()}
                </Card.Content>
              </Card>
            )}
          >
            <Popup.Content>
              {_renderRelationUserBook()}
            </Popup.Content>
          </Popup>
        </>
      );

    case "THUMBNAIL":
      return (
        <Popup
          hoverable
          position={"right center"}
          on={"click"}
          wide={"very"}
          trigger={(
            <div style={{
              // backgroundColor: "rgba(0,0,0,0.3)",
              // padding: "1rem",
              width: "100%",
              paddingTop: "150%",
              backgroundImage: `url(${book?.coverImageUrl})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "100%",
            }}>
            </div>
          )}
        >
          <Popup.Content style={{}}>
            {_renderRelationUserBook()}
            <div style={{
              color: Colors.buttonColor,
              cursor: "pointer",
              marginTop: "1rem",
            }} onClick={_handleGoToDetailPage}>
              [책상세 페이지 보기]
            </div>
          </Popup.Content>
        </Popup>
      );

    default:
      return (
        <div style={{ display: "flex", minHeight: "120px", ...props.style }}>
          <div style={{ width: "100px" }}>
            <img src={book.coverImageUrl} width={"100%"}/>
          </div>
          <div style={{ flex: 1, paddingLeft: "1rem" }}>
            <Header content={book.title}/>
            {_renderTargetUserRelationUserBook()}
            <div style={{ marginTop: "1rem" }}>
              {_renderRelationUserBook()}
            </div>
          </div>
        </div>
      );
  }


};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BookComponent);
