import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Form, Grid, Header, Image, Message, Segment} from "semantic-ui-react";
import {authActionCreators} from "../../redux/auth/authStore";

const SignInContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})
  const [fetching, setFetching] = useState(false);
  const [signInId, setSignInId] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {

  }, [])

  const _handleSignIn = async () => {
    const businessId = 1;
    setFetching(true);
    props.signIn(signInId, password, businessId, () => {
      setFetching(false);
    });
  };

  return (
    <div>
      <Grid
        columns={1}
        textAlign='center'
        style={{
          height: '100vh',
          // alignItems: 'center'
        }}
        verticalAlign={'middle'}
      >
        <Grid.Column
          // verticalAlign={'center'}
          textAlign='left'
          style={{
            alignItems: 'center',
            maxWidth: '450px'
          }}>
          <Header as='h2' textAlign='center'>
            <Image src='/logo192.png' avatar size={'medium'} />교보생명 독서공방(讀書共房)
          </Header>
          <Form>
            <Segment stacked>
              <div style={{}}>
                <Form.Input
                  icon={'user'}
                  iconPosition='left'
                  placeholder={'id: (소문자)kl + 사번'}
                  name={'id'}
                  label={'ID'}
                  onChange={(e, {value}) => {
                    setSignInId(value);
                  }}
                />
                <Form.Input
                  icon={'lock'}
                  iconPosition='left'
                  onChange={(e, {value}) => {
                    setPassword(value);
                  }}
                  placeholder={'최초 비밀번호는 kl+사번 입니다.'}
                  name={'password'}
                  type="password"
                  label={'PASSWORD'}/>
                <Form.Button

                  fluid
                  color={'blue'}
                  active={fetching}
                  content={'로그인'}
                  disabled={fetching}
                  onClick={_handleSignIn}/>
              </div>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);

export default enhance(SignInContainer);
