import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Container} from "semantic-ui-react";

const NoPageContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  useEffect(() => {

  })

  return (
    <Container text style={{paddingTop: '10em', textAlign: 'center'}}>
      유효하지 않은 주소입니다.
    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(NoPageContainer);
