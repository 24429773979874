import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import Network_B2B from "../../library/Network_B2B";
import moment from "moment";
import numeral from 'numeral';
import _ from 'lodash';
import LoaderComponent from "../../component/shared/LoaderComponent";
import {Card, Container, Grid, Header, Icon, Image, Item, Message, Segment} from "semantic-ui-react";
import MyPointComponent from "../../component/my-point/MyPointComponent";

const MyPagePointContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const [businessPoints, setBusinessPoints] = useState([]);
  const [sections, setSections] = useState([]);
  const [businessPointTypes, setBusinessPointTypes] = useState([]);

  const perPage = 50;

  const _load = async () => {
    const res = await Network_B2B.getBusinessPointMyList(0, perPage);
    if (!res.err) {
      setBusinessPoints(res.businessPoints);
      setIsLoaded(true);
      setRefreshing(false);
      setEndReached(res.businessPoints.length < perPage);

      const arrayGroupByMonth = _.toArray(_.groupBy(res.businessPoints, o => moment(o.createdAt).format("YYYY.MM.DD")));
      const sections = [];
      console.log(arrayGroupByMonth.length);
      for (let i = 0; i < arrayGroupByMonth.length; i++) {
        // console.log(arrayGroupByMonth[i]);
        sections.push({
          type: "DATA",
          title: moment(arrayGroupByMonth[i][0].createdAt).format("YYYY.MM.DD(ddd)"),
          data: arrayGroupByMonth[i],
        });
      }
      setSections(sections);
    }
  }

  const _loadMore = async () => {
    return;
    console.log(businessPoints.length);
    if (endReached || loadingMore) return;
    setLoadingMore(true);
    const res = await Network_B2B.getBusinessPointMyList(businessPoints.length, perPage);
    if (!res.err) {
      setLoadingMore(false);
      const newBusinessPoints = [...businessPoints, ...res.businessPoints];
      console.log(newBusinessPoints?.length);
      setBusinessPoints(newBusinessPoints);
      setEndReached(res.businessPoints.length < perPage);
      const arrayGroupByMonth = _.toArray(_.groupBy(res.businessPoints, o => moment(o.createdAt).format("YYYY.MM.DD")));
      const newSections = [...sections]
      for (let i = 0; i < arrayGroupByMonth.length; i++) {
        newSections.push({
          type: "DATA",
          title: moment(arrayGroupByMonth[i][0].createdAt).format("YYYY.MM.DD(ddd)"),
          data: arrayGroupByMonth[i],
        });
      }
      setSections(newSections);
    }
  }

  const _loadTypes = React.useCallback(async () => {
    const res = await Network_B2B.getBusinessPointTypeList();
    if (!res.err) {
      setBusinessPointTypes(res.businessPointTypes);
    }
  }, [isLoaded, sections]);

  const _listener = React.useCallback(() => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    // console.log(scrollHeight, scrollTop, clientHeight)
    if ((scrollTop + clientHeight >= scrollHeight) && !loadingMore) {
      console.log('끝')
      _loadMore()
    }
  }, [businessPoints.length])

  useEffect(() => {
    _load();
    _loadTypes();
    window.addEventListener('scroll', _listener)
    return () => {
      window.removeEventListener('scroll', _listener)
    }
  }, []);

  if (!isLoaded) return <LoaderComponent/>

  return (
    <>
      <Container as={Segment} vertical>
        <Grid>
          <Grid.Row>
            <Grid.Column width={11}>
              {
                businessPoints.length < 1 ? (
                  <Message warning icon style={{alignItems: 'flex-start'}}>
                    <Icon name={'info circle'}/>
                    <Message.Content>
                      <Message.Header content={'포인트 내역이 없습니다.'}/>
                      <p>
                        우측 포인티 적립 기준을 참고하셔서, 포인트를 적립하여 도서 신청에 활용해 주세요.
                      </p>
                    </Message.Content>
                  </Message>
                ) : (
                  <Message warning icon style={{alignItems: 'flex-start'}}>
                    <Icon name={'info circle'}/>
                    <Message.Content>
                      <Message.Header content={''}/>
                      <p>
                        우측 포인티 적립 기준을 참고하셔서, 포인트를 적립하여 도서 신청에 활용해 주세요.
                      </p>
                    </Message.Content>
                  </Message>
                )
              }
              {
                sections?.map((section, i) => {

                  return (
                    <Segment key={i} style={{marginTop: '1rem'}}>
                      <Header content={section.title}/>
                      <Item.Group divided>
                        {
                          businessPoints.map((item, index) => {
                            return (
                              <Item key={index}>
                                <Item.Image src={item.businessPointType?.imageUrl} size={'mini'}/>
                                <Item.Content>
                                  {moment(item.createdAt).format("HH:mm")} <br/>
                                  {item.businessPointType?.text} <br/>
                                  {numeral(item.amount).format("0,0")} P
                                </Item.Content>
                              </Item>
                            )
                          })
                        }
                      </Item.Group>
                    </Segment>
                  )
                })
              }

            </Grid.Column>
            <Grid.Column width={5}>
              <Card fluid style={{borderRadius: 0,}}>
                <Card.Content>
                  <Card.Header content={'나의 포인트 현황'}/>
                </Card.Content>
                <Card.Content>
                  <MyPointComponent/>
                </Card.Content>
              </Card>
              <Message style={{borderRadius: 0,}}>
                <Message.Header content={'포인트 적립 기준'}/>
                <Message.Content>
                  <Message.List>
                    <Message.Item content={'한달에 30,000P 까지 적립 가능합니다.'}/>
                    {
                      _.filter(businessPointTypes, o => o.unit > 0).map((type, i) => {
                        return (
                          <Message.Item>
                            <b>({numeral(type.unit).format('0,0')}P)</b> {type.text}
                          </Message.Item>
                        )
                      })
                    }
                  </Message.List>
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(MyPagePointContainer);
