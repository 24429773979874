export const feedActions = {
  FETCH_FEEDS_FOLLOW: "FETCH_FEEDS_FOLLOW",
  FETCH_FEEDS_FOLLOW_MORE: "FETCH_FEEDS_FOLLOW_MORE",
  FETCH_FEEDS_FOLLOW_SUCCESS: "FETCH_FEEDS_FOLLOW_SUCCESS",

  FETCH_FEEDS_RECOMMEND: "FETCH_FEEDS_RECOMMEND",
  FETCH_FEEDS_RECOMMEND_MORE: "FETCH_FEEDS_RECOMMEND_MORE",
  FETCH_FEEDS_RECOMMEND_SUCCESS: "FETCH_FEEDS_RECOMMEND_SUCCESS",

  FETCH_FEEDS_CLEAR: "",
};

export const feedActionCreators = {
  fetchFeedsFollow: (perPage, cb) => ( { type: feedActions.FETCH_FEEDS_FOLLOW, perPage, cb } ),
  fetchFeedsFollowMore: (lastItemCreatedAt, perPage, cb) => ( { type: feedActions.FETCH_FEEDS_FOLLOW_MORE, lastItemCreatedAt, perPage, cb } ),
  fetchFeedsFollowSuccess: (feedsFollow, cb) => ( { type: feedActions.FETCH_FEEDS_FOLLOW_SUCCESS, feedsFollow, cb } ),

  fetchFeedsRecommend: (perPage, cb) => ( { type: feedActions.FETCH_FEEDS_RECOMMEND, perPage, cb } ),
  fetchFeedsRecommendMore: (lastItemCreatedAt, perPage, cb) => ( { type: feedActions.FETCH_FEEDS_RECOMMEND_MORE, lastItemCreatedAt, perPage, cb } ),
  fetchFeedsRecommendSuccess: (feedsRecommend, cb) => ( { type: feedActions.FETCH_FEEDS_RECOMMEND_SUCCESS, feedsRecommend, cb } ),

  fetchFeedsClear: (cb) => ( { type: feedActions.FETCH_FEEDS_CLEAR, cb } ),
};

const initialState = {
  feedsFollow: [],
  isFeedsFollowLoaded: false,

  feedsRecommend: [],
  isFeedsRecommendLoaded: false,
};

export const feedReducer = (state = initialState, action) => {
  switch (action.type) {
    case feedActions.FETCH_FEEDS_FOLLOW:
      return {
        ...state,
      };
    case feedActions.FETCH_FEEDS_FOLLOW_MORE:
      return {
        ...state,
      };
    case feedActions.FETCH_FEEDS_FOLLOW_SUCCESS:
      return {
        ...state,
        feedsFollow: action.feedsFollow,
        isFeedsFollowLoaded: true,
      };
    case feedActions.FETCH_FEEDS_RECOMMEND:
      return {
        ...state,
      };
    case feedActions.FETCH_FEEDS_RECOMMEND_MORE:
      return {
        ...state,
      };
    case feedActions.FETCH_FEEDS_RECOMMEND_SUCCESS:
      return {
        ...state,
        feedsRecommend: action.feedsRecommend,
        isFeedsRecommendLoaded: true,
      };
    case feedActions.FETCH_FEEDS_CLEAR:
      return initialState;
    default:
      return state;
  }
};
