import { createLogic } from "redux-logic";
import Network_B2B from "../../library/Network_B2B";
import { homeActionCreator, homeActions } from "./homeStore";

const fetchHomeLogic = createLogic({
  type: homeActions.FETCH_HOME,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const { user } = getState().authReducer;
    const { err, businessNotices, bookCategoryGroups, businessBanners, bookCategories, businessBookRecommendationCollectionByCompany } = await Network_B2B.getHome();
    if (!err) {
      dispatch(homeActionCreator.fetchHomeSuccess(businessNotices, bookCategoryGroups, businessBanners, businessBookRecommendationCollectionByCompany, bookCategories));
    }
    action?.cb?.();
    done();
  },
});


export default [
  fetchHomeLogic,
];
