import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from "react-router-dom";
import Network_B2B from "../../library/Network_B2B";

const NoticeDetailContainer = (props) => {

  const {businessNoticeId} = useParams();
  const [isLoaded, setIsLoaded] = useState(false)
  const [businessNotice, setBusinessNotice] = useState({})

  const _loadNotice = async () => {
    const res = await Network_B2B.getBusinessNotice(businessNoticeId);
    if (!res.err) {
      setBusinessNotice(res.businessNotice);
      setIsLoaded(true);
    }

  }

  useEffect(() => {

  }, [])

  return (
    <div>
      NoticeDetailContainer
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(NoticeDetailContainer);
