import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Divider, Header, Image, Segment} from "semantic-ui-react";
import mockUpImages from "../../constant/mockUpImages";
import Network_B2B from "../../library/Network_B2B";
import ChannelService from "../../library/ChannelService";
import {useParams} from "react-router-dom";
import LoaderComponent from "../../component/shared/LoaderComponent";
import _ from 'lodash'

const EventOpenDetailContainer = (props) => {

  const {userId} = useParams();
  const [isLoaded, setIsLoaded] = useState(false)
  const [participationInfo, setParticipationInfo] = useState({})
  const [myInsiderData, setMyInsiderData] = useState({})
  const [myKnowledgeGiverData, setMyKnowledgeGiverData] = useState({})
  const [insiders, setInsiders] = useState([])
  const [knowledgeGivers, setKnowledgeGivers] = useState([])
  //participationInfo,
  //       myInsiderData,
  //       myKnowledgeGiverData,
  //       insiders,
  //       knowledgeGivers,
  useEffect(() => {
    ChannelService.shutdown();
    _load();
  }, [])

  const _load = async () => {
    const res = await Network_B2B.tempOpenEventGET(userId);
    if (!res.err) {
      setIsLoaded(true);
      setParticipationInfo(res.participationInfo)
      setMyInsiderData(res.myInsiderData)
      setMyKnowledgeGiverData(res.myKnowledgeGiverData)
      setInsiders(res.insiders)
      setKnowledgeGivers(res.knowledgeGivers)
    }
  }

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <div style={{
        backgroundColor: '#FFF3D6',
      }}>
        <div style={{
          maxWidth: '500px',
          margin: 'auto',
        }}>
          <img
            width={'100%'}
            src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/temp%2Fevent_main_top_210318_1000.png?alt=media&token=bd5dee83-9e76-4418-9c85-b74e28762ec1'}/>
        </div>

      </div>

      <div style={{
        maxWidth: '500px',
        margin: 'auto',
        marginTop: '3rem',
        // backgroundColor: 'red',
        padding: '0rem 2rem'
      }}>
        <h1 style={{fontFamily: 'bing', fontSize: '3rem'}}>
          인싸상 (상위 5명)
          <p style={{fontFamily: 'bing', fontSize: '2rem', color: 'grey'}}>
            팔로우를 가장 많이 받은 조직원
          </p>
        </h1>
        <Divider/>
        <Header>
          현재 순위
        </Header>
        {
          insiders?.map((u, i) => {
            return (
              <Segment
                style={{
                  backgroundColor: u.id.toString() === userId ? '#FFF3D6' : 'white',
                }}
                key={i}>
                <Header size={'small'} content={`${u.ranking}등`}/>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <div style={{
                    width: '60px',
                    height: '60px',
                    backgroundImage: `url(${u.photoUrl})`,
                    backgroundColor: 'grey',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '60px 60px',
                    backgroundPosition: 'center',
                    borderRadius: '30px',
                    marginRight: '1rem',
                  }}/>
                  <div style={{flex: 1}}>
                    <p style={{fontWeight: 'bold'}}>
                      {u.name}
                      <p style={{color: 'grey', fontWeight: '400'}}>{u.title}</p>
                    </p>
                  </div>
                  <div>
                    <Image size={'mini'}
                           src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/temp%2Fbiz-image%2Fevent_follow.png?alt=media'}
                    />
                    <p style={{textAlign: 'right', fontWeight: 'bold'}}>
                      {u.count}명
                    </p>
                  </div>
                </div>
              </Segment>
            )
          })
        }
        {
          !_.find(insiders, o => Number(o.id) === Number(userId)) && (
            <>
              <Divider/>
              <Segment style={{
                backgroundColor: '#FFF3D6',
              }}>
                <Header size={'small'} content={`나의 현황`}/>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <div style={{
                    width: '60px',
                    height: '60px',
                    backgroundImage: `url(${myInsiderData.photoUrl})`,
                    backgroundColor: 'grey',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '60px 60px',
                    backgroundPosition: 'center',
                    borderRadius: '30px',
                    marginRight: '1rem',
                  }}/>
                  <div style={{flex: 1}}>
                    <p style={{fontWeight: 'bold'}}>
                      {myInsiderData.name}
                      <p style={{color: 'grey', fontWeight: '400'}}>{myInsiderData.title}</p>
                    </p>
                  </div>
                  <div>
                    <Image size={'mini'}
                           src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/temp%2Fbiz-image%2Fevent_follow.png?alt=media'}
                    />
                    <p style={{textAlign: 'right', fontWeight: 'bold'}}>
                      {myInsiderData.count}명
                    </p>
                  </div>
                </div>
              </Segment>
            </>
          )
        }

      </div>

      <div style={{
        maxWidth: '500px',
        margin: 'auto',
        height: '10px', backgroundColor: '#E8E8E8',
        marginTop: '3rem',
        marginBottom: '2rem'
      }}/>

      {/* 지식 나눔상 */}
      <div style={{
        maxWidth: '500px',
        margin: 'auto',
        // backgroundColor: 'red',
        padding: '0rem 2rem'
      }}>
        <h1 style={{fontFamily: 'bing', fontSize: '3rem'}}>
          지식나눔상 (상위 5명)
          <p style={{fontFamily: 'bing', fontSize: '2rem', color: 'grey'}}>
            스크랩에 가장 많은 좋아요를 받은 조직원 (기간 내 합산)
          </p>
        </h1>
        <Divider/>
        <Header>
          현재 순위
        </Header>
        {
          knowledgeGivers?.map((u, i) => {
            return (
              <Segment
                style={{
                  backgroundColor: u.id.toString() === userId ? '#FFF3D6' : 'white',
                }}
                key={i}>
                <Header size={'small'} content={`${u.ranking}등`}/>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <div style={{
                    width: '60px',
                    height: '60px',
                    backgroundImage: `url(${u.photoUrl})`,
                    backgroundColor: 'grey',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '60px 60px',
                    backgroundPosition: 'center',
                    borderRadius: '30px',
                    marginRight: '1rem',
                  }}/>
                  <div style={{flex: 1}}>
                    <p style={{fontWeight: 'bold'}}>
                      {u.name}
                      <p style={{color: 'grey', fontWeight: '400'}}>{u.title}</p>
                    </p>
                  </div>
                  <div>
                    <Image size={'mini'}
                           src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/temp%2Fbiz-image%2Fevent_like.png?alt=media'}
                    />
                    <p style={{textAlign: 'right', fontWeight: 'bold'}}>
                      {u.count}개
                    </p>
                  </div>
                </div>
              </Segment>
            )
          })
        }

        {
          !_.find(knowledgeGivers, o => Number(o.id) === Number(userId)) && (
            <>
              <Divider/>
              <Segment style={{
                backgroundColor: '#FFF3D6',
              }}>
                <Header size={'small'} content={`나의 현황`}/>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <div style={{
                    width: '60px',
                    height: '60px',
                    backgroundImage: `url(${myKnowledgeGiverData.photoUrl})`,
                    backgroundColor: 'grey',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '60px 60px',
                    backgroundPosition: 'center',
                    borderRadius: '30px',
                    marginRight: '1rem',
                  }}/>
                  <div style={{flex: 1}}>
                    <p style={{fontWeight: 'bold'}}>
                      {myKnowledgeGiverData.name}
                      <p style={{color: 'grey', fontWeight: '400'}}>{myKnowledgeGiverData.title}</p>
                    </p>
                  </div>
                  <div>
                    <Image size={'mini'}
                           src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/temp%2Fbiz-image%2Fevent_like.png?alt=media'}
                    />
                    <p style={{textAlign: 'right', fontWeight: 'bold'}}>
                      {myKnowledgeGiverData.count} 개
                    </p>
                  </div>
                </div>
              </Segment>
            </>
          )
        }
      </div>

      <div style={{
        maxWidth: '500px',
        margin: 'auto',
        height: '10px', backgroundColor: '#E8E8E8',
        marginTop: '3rem',
        marginBottom: '2rem'
      }}/>

      {/* 참가상 */}
      <div style={{
        maxWidth: '500px',
        margin: 'auto',
        // backgroundColor: 'red',
        padding: '0rem 2rem',
      }}>
        <h1 style={{fontFamily: 'bing', fontSize: '3rem'}}>
          참가상 (추첨 100명)
          <p style={{fontFamily: 'bing', fontSize: '2rem', color: 'grey'}}>
            다음 요건 충족 인원 中 추첨 (4.23 기준)
          </p>
        </h1>
        <Divider/>
        <Header>
          나의 충족 현황
        </Header>
        <Segment>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Image avatar
                   src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/temp%2Fbiz-image%2Fevent_profile.png?alt=media'}/>
            <div style={{flex: 1}}>
              프로필 사진 등록
            </div>
            {
              participationInfo?.profileImage ? (
                <p style={{fontFamily: 'bing', fontSize: '1.5rem'}}>
                  완료!
                </p>
              ) : (
                <p style={{
                  fontFamily: 'bing', fontSize: '1.5rem',
                  color: 'lightgrey'
                }}>
                  미등록
                </p>
              )
            }

          </div>
          <Divider/>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Image avatar
                   src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/temp%2Fbiz-image%2Fevent_follow.png?alt=media'}/>
            <div style={{flex: 1}}>
              팔로우 10건 이상 신청
            </div>
            {participationInfo?.numberOfFollowings > 9 ? (
              <p style={{textAlign: 'right', fontFamily: 'bing', fontSize: '1.5rem'}}>
                완료!
                <p style={{color: 'grey', fontSize: '1rem'}}>
                  {participationInfo?.numberOfFollowings}/10 건
                </p>
              </p>
            ) : (
              <p style={{textAlign: 'right', fontFamily: 'bing', color: 'lightgrey', fontSize: '1.5rem'}}>
                진행중
                <p style={{color: 'grey', fontSize: '1rem'}}>
                  {participationInfo?.numberOfFollowings}/10 건
                </p>
              </p>
            )}
          </div>
          <Divider/>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Image avatar
                   src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/temp%2Fbiz-image%2Fevent_book.png?alt=media'}/>
            <div style={{flex: 1}}>
              읽은 책 10건 이상 등록
            </div>
            {participationInfo?.numberOfRelationUserBooks > 9 ? (
              <p style={{textAlign: 'right', fontFamily: 'bing', fontSize: '1.5rem'}}>
                완료!
                <p style={{color: 'grey', fontSize: '1rem'}}>
                  {participationInfo?.numberOfRelationUserBooks}/10 건
                </p>
              </p>
            ) : (
              <p style={{textAlign: 'right', fontFamily: 'bing', color: 'lightgrey', fontSize: '1.5rem'}}>
                진행중
                <p style={{color: 'grey', fontSize: '1rem'}}>
                  {participationInfo?.numberOfRelationUserBooks}/10 건
                </p>
              </p>
            )}
          </div>
        </Segment>
      </div>


      <div style={{
        height: '3rem',
      }}>

      </div>
    </>

  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(EventOpenDetailContainer);
