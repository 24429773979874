import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Network_B2B from "../../library/Network_B2B";
import LoaderComponent from "../../component/shared/LoaderComponent";
import ScrapComponent from "../../component/scrap/ScrapComponent";
import { Container, Segment } from "semantic-ui-react";

const ScrapDetailContainer = (props) => {

  const { scrapId } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [scrap, setScrap] = useState({});

  const _load = async () => {
    const res = await Network_B2B.getScrap(scrapId);
    if (!res.err) {
      setScrap(res.scrap);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    _load();
  }, []);


  if (!isLoaded) return <LoaderComponent/>;

  const _renderBookInfo = () => {

  };

  return (
    <>
      <Container as={Segment} vertical>
        <ScrapComponent
          scrap={scrap}/>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(ScrapDetailContainer);
