export const homeActions = {
  FETCH_HOME: "FETCH_HOME",
  FETCH_HOME_SUCCESS: "FETCH_HOME_SUCCESS",

  HOME_INITIALIZE: "HOME_INITIALIZE",
};


export const homeActionCreator = {
  fetchHome: (cb) => ({ type: homeActions.FETCH_HOME, cb }),
  fetchHomeSuccess: (businessNotices, bookCategoryGroups, businessBanners, businessBookRecommendationCollectionByCompany, bookCategories, cb) => ({
    type: homeActions.FETCH_HOME_SUCCESS,
    businessNotices,
    bookCategoryGroups,
    bookCategories,
    businessBanners,
    businessBookRecommendationCollectionByCompany,
    cb,
  }),
  initialize: (cb) => ({ type: homeActions.HOME_INITIALIZE, cb }),
};

const initialState = {
  isLoaded: false,
  businessBanners: [],
  booksRecommended: [],
  booksRecommendedByAdmin: [],
  usersRecommended: [],

  businessNotices: [],
  bookCategoryGroups: [],
  bookCategories: [],

  businessBookRecommendationCollectionByCompany: {},
};

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case homeActions.FETCH_HOME_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        businessNotices: action.businessNotices,
        bookCategoryGroups: action.bookCategoryGroups,
        bookCategories: action.bookCategories,
        businessBanners: action.businessBanners,
        businessBookRecommendationCollectionByCompany: action.businessBookRecommendationCollectionByCompany,
        refreshing: false,
      };
    case homeActions.HOME_INITIALIZE:
      return initialState;
    default:

      return state;
  }
};
