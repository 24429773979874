import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import Network_B2B from "../../library/Network_B2B";
import LoaderComponent from "../../component/shared/LoaderComponent";
import { Container, Grid, Header, Card, Segment, Image, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";

const RecommendationBookContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [sections, setSections] = useState([]);

  const _load = async () => {
    const res = await Network_B2B.getRecommendationBookList();
    if (!res.err) {
      setIsLoaded(true);
      setRefreshing(false);
      setSections(res.sections);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    _load();
  }, []);

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic>

      </Container>
      <Container>
        {
          sections?.map((section, i) => {
            return (
              <Segment key={i} vertical style={{ marginTop: "3rem" }} basic>
                <Header>
                  {section.headerTitle}
                </Header>
                <Divider/>
                <Card.Group itemsPerRow={6}>
                  {
                    section.data?.[0]?.items?.map((o, j) => {
                      return (
                        <Card
                          key={j}
                          fluid as={Link} to={`/book/${o.id}`}>
                          <Card.Content textAlign={"center"}>
                            <Image src={o.coverImageUrl} size={"tiny"}/>
                          </Card.Content>
                          <Card.Content extra>
                            <LinesEllipsis
                              maxLine={2}
                              basedOn='letters'
                              text={o.title}
                            />
                          </Card.Content>
                        </Card>
                      );
                    })
                  }
                  <Grid.Column>
                  </Grid.Column>
                </Card.Group>
              </Segment>
            );
          })
        }
      </Container>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(RecommendationBookContainer);
