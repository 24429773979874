import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import numeral from "numeral";
import {Progress} from "semantic-ui-react";

const MyPointComponent = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  useEffect(() => {

  })

  if (!props.authReducer?.isLoggedIn) return null;
  const {
    businessPointAccThisPeriod,
    businessPointMaxThisPeriod,
    businessPointLeft,
    accAll,
    accMonth,
    accDay,
    left,
  } = props.authReducer?.user?.businessPoint;


  return (
    <div>
      <Progress total={businessPointMaxThisPeriod} value={accMonth} indicating color={'blue'}>
        {numeral(accMonth).format("0,0")} P /{numeral(businessPointMaxThisPeriod).format("0,0")}P (한달 최대)
      </Progress>
      <p>
        이번달 적립 포인트: <b>{numeral(accMonth).format("0,0")} P</b>
      </p>
      <p>
        한 달에 최대 {numeral(businessPointMaxThisPeriod).format("0,0")}P 적립 가능
      </p>
      <p>
        보유 잔여 포인트: <b>{numeral(left).format("0,0")} P</b>
      </p>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(MyPointComponent);
