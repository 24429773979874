import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Button, Container, Dropdown, Form, Grid, Header, Image, Input, List, Menu, Search, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {authActionCreators} from "../../redux/auth/authStore";
import {useHistory} from 'react-router-dom'

const LayoutComponent = (props) => {

  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})
  const [keyword, setKeyword] = useState('')

  useEffect(() => {

  }, [])

  return (
    <div>
      <Menu fixed={'top'} secondary style={{backgroundColor: '#EFF0F1'}}>
        <Container>
          <Menu.Item as={Link} to={'/'} header>
            <Image size='mini' src='/logo192.png' avatar style={{marginRight: '1.5em'}}/>
            독서공방
          </Menu.Item>
          {/*<Menu.Item as={Link} to={'/book'}>도서</Menu.Item>*/}
          <Menu.Item as={Link} to={'/feed?type=follow'}>피드</Menu.Item>

          {/*<Menu.Item as={Link} to={'/user'}>유저</Menu.Item>*/}
          {/*<Menu.Item as={Link} to={'/forum'}>독서토론회</Menu.Item>*/}
          {/*<Menu.Item as={Link} to={'/test'} style={{color:'red'}}>테스트-임시</Menu.Item>*/}

          {/*<Dropdown item simple text='Dropdown'>
            <Dropdown.Menu>
              <Dropdown.Item>List Item</Dropdown.Item>
              <Dropdown.Divider/>
              <Dropdown.Header>Header Item</Dropdown.Header>
              <Dropdown.Item>
                <i className='dropdown icon'/>
                <span className='text'>Submenu</span>
                <Dropdown.Menu>
                  <Dropdown.Item>List Item</Dropdown.Item>
                  <Dropdown.Item>List Item</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown.Item>
              <Dropdown.Item>List Item</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>*/}
          <Menu.Menu position='right'>
            <Menu.Item>
              <Form>
                <Form.Input
                  onChange={(e, {value}) => setKeyword(value)}
                  placeholder='도서 검색...' action>
                  <input/>
                  <Button basic icon={'search'}
                          onClick={() => {
                            history.push(`/search?keyword=${keyword}`)
                          }}/>
                </Form.Input>
              </Form>
            </Menu.Item>
            <Menu.Item
              as={Link}
              to={'/my'}
              icon={'user'}
              name='마이페이지'
            />
          </Menu.Menu>
        </Container>
      </Menu>
      <div style={{paddingTop: '60px', minHeight: '90vh', paddingBottom: '5em'}}>
        {props.children}
      </div>
      <Segment vertical inverted style={{minHeight: '10vh', padding: '5em 0em'}}>
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>
              {/*<Grid.Column width={3}>
                <Header inverted as='h4' content='About'/>
                <List link inverted>
                  <List.Item as='a'>Sitemap</List.Item>
                  <List.Item as='a'>Contact Us</List.Item>
                  <List.Item as='a'>Religious Ceremonies</List.Item>
                  <List.Item as='a'>Gazebo Plans</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as='h4' content='Services'/>
                <List link inverted>
                  <List.Item as='a'>Banana Pre-Order</List.Item>
                  <List.Item as='a'>DNA FAQ</List.Item>
                  <List.Item as='a'>How To Access</List.Item>
                  <List.Item as='a'>Favorite X-Men</List.Item>
                </List>
              </Grid.Column>*/}
              <Grid.Column width={7}>
                <Header as='h4' inverted>
                  교보생명 독서공방(讀書共房)
                </Header>
                <p>
                  powered by <a href={'https://withreaders.com'}>READERS</a>
                </p>
                <p>
                  copyright 2019. ASSI Co.,Ltd. All rights reserved
                </p>
                <p>
                  주식회사 아씨 | 서울시 강남구 역삼로 180 MARU180 4층 4D | 02-2135-1779  <br/>
                  사업자등록번호: 376-86-01443 <br/>
                  대표: 윤영훈 | 개인정보책임자: 윤영훈 | 이메일: support@my-assi.com <br/>
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);

export default enhance(LayoutComponent);
