import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from "react-router-dom";
import Network_B2B from "../../library/Network_B2B";
import LoaderComponent from "../../component/shared/LoaderComponent";
import {Button, Container, Grid, GridRow, Image, Segment} from "semantic-ui-react";
import JSONPretty from 'react-json-prettify';

const BookForumDetailContainer = (props) => {

  const {businessBookForumId} = useParams()
  const [fetching, setFetching] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [businessBookForum, setBusinessBookForum] = useState({});

  const _load = async () => {
    if (!businessBookForumId) return window.alert('정상적인 접근이 아닙니다.')
    const res = await Network_B2B.getBusinessBookForum(businessBookForumId);
    if (!res.err) {
      setIsLoaded(true);
      setRefreshing(false);
      setFetching(false);
      setBusinessBookForum(res.businessBookForum);
    }
  };

  useEffect(() => {
    _load();
  }, [])

  if (!isLoaded) return <LoaderComponent/>;

  const _renderApplyButton = () => {
    if (businessBookForum.isCompleted) {

    } else {
      switch (businessBookForum.businessBookForumMember?.businessBookForumMemberTypeId) {
        case 1: // HOST
        case 2: // APPROVED
        case 3: // 초대
        case 4: // 승인 대기중
        default:
      }
    }
  }

  return (
    <>
      <Container as={Segment} vertical basic text>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center',}}>
              <Image src={businessBookForum?.book?.coverImageUrl} style={{alignSelf: 'center'}}/>
              <Button basic content={'책 정보 보기'} style={{marginTop: '0.5rem', border: '1px'}}/>
            </Grid.Column>
            <Grid.Column width={12}>

            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Container as={Segment} vertical>

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <p>
                {businessBookForum.title}
              </p>
              <p>
                {businessBookForum.locationDescription}
              </p>
              <p>
                {businessBookForum.description}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Container as={Segment} vertical>
        <JSONPretty json={businessBookForum}/>
      </Container>

    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BookForumDetailContainer);
