import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Network_B2B from "../../library/Network_B2B";
import {Container, Item, Loader, Message, Segment} from "semantic-ui-react";
import BookRequestListItemComponent from "../../component/book-request/BookRequestListComponent";

const BookRequestListContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const [businessBookRequests, setBusinessBookRequests] = useState([]);
  const perPage = 50;

  useEffect(() => {
    _load();
    window.scrollTo(0,0)
  }, []);

  const _load = async () => {
    const res = await Network_B2B.getBusinessBookRequestListMy(0, perPage)
    if (!res.err) {
      setIsLoaded(true);
      setBusinessBookRequests(res.businessBookRequests);
      setRefreshing(false);
    }
  }

  if (!isLoaded) return <Loader active/>;


  return (
    <>
      <Container text vertical as={Segment}>
        <Item.Group>
          {
            businessBookRequests.map((item, i) => {
              return (
                <BookRequestListItemComponent businessBookRequest={item} key={i}/>
              )
            })
          }
        </Item.Group>
        {
          businessBookRequests?.length < 1 && (
            <Message>
              <Message.Header>
                도서 신청 내역이 없습니다.
              </Message.Header>
              <Message.Content>
                도서를 신청해 주세요.
              </Message.Content>
            </Message>
          )
        }
      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BookRequestListContainer);
