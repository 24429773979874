import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Card, Container, Form, Grid, Header, Icon, Image, Loader, Menu, Message, Modal, Popup, Segment } from "semantic-ui-react";
import { authActionCreators } from "../../redux/auth/authStore";
import MyPointComponent from "../../component/my-point/MyPointComponent";
import BusinessLearningCreditComponent from "../../component/my-page/BusinessLearningCreditComponent";
import { Link } from "react-router-dom";
import numeral from "numeral";
import Colors from "../../constant/Colors";
import MyFollowerListModalComponent from "../../component/follow/MyFollowerListModalComponent";
import { useQuery } from "../../library/useQuery";
import Network_B2B from "../../library/Network_B2B";
import moment from "moment";
import _ from "lodash";
import BookComponent from "../../component/book/BookComponent";
import ScrapComponent from "../../component/scrap/ScrapComponent";
import UserComponent from "../../component/user/UserComponent";

const MyPageContainer = (props) => {

  const { selectedTabIndex } = useQuery(props);
  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});

  const [fetching, setFetching] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const [bookIsLoaded, setBookIsLoaded] = useState(false);
  const [selectedBookTabIndex, setSelectedBookTabIndex] = useState(0);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [showOnlyReviewed, setShowOnlyReviewed] = useState(false);
  const [bookshelfViewTypeId, setBookshelfViewTypeId] = useState(0);

  const [scrapIsLoaded, setScrapIsLoaded] = useState(false);
  const [scraps, setScraps] = useState([]);
  const [endReached, setEndReached] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [perPage, setPerPage] = useState(20);
  const [scrapViewTypeId, setScrapViewTypeId] = useState(0);


  const [followerModalOpen, setFollowerModalOpen] = useState(false);
  // const [followers, setFollowers] = useState([]);

  const [followingModalOpen, setFollowingModalOpen] = useState(false);
  // const [followings, setFollowings] = useState([]);

  const { user, books, followings, followers } = props.authReducer;

  const _handleOpenFollowerModal = () => {
    setFollowerModalOpen(true);
    _loadFollower();
  };
  const _handleCloseFollowerModal = () => {
    setFollowerModalOpen(false);
  };
  const _handleOpenFollowingModal = () => {
    setFollowingModalOpen(true);
    _loadFollowings();
  };
  const _handleCloseFollowingModal = () => {
    setFollowingModalOpen(false);
  };


  const _load = async () => {
    props.fetchMyInfo(() => {
      setIsLoaded(true);
    });
    // const res = await Network_B2B.getUser(userId);
    // if (!res.err) {
    //   setIsLoaded(true);
    //   setUser(res.user);
    // } else {
    //   props.history.goBack();
    // }
  };
  const _loadBook = async () => {
    props.fetchMyBook(() => {
      setBookIsLoaded(true);
    });
  };
  const _loadScrap = async () => {

    const res = await Network_B2B.getScrapListUser(user.id, moment().toISOString(), 100);
    if (!res.err) {
      setScrapIsLoaded(true);
      setScraps(res.scraps);
      setRefreshing(false);
      setEndReached(res.scraps?.length < perPage);
    }
  };

  const _loadFollower = async () => {
    props.fetchMyFollower();
    // const res = await Network_B2B.getFollowFollowerList(user.id);
    // if (!res.err) {
    //   setFollowers(res.users);
    // setIsLoaded(true);
    // }
  };
  const _loadFollowings = async () => {
    props.fetchMyFollowing();
    // const res = await Network_B2B.getFollowFollowingList(user.id);
    // if (!res.err) {
    //   setFollowings(res.users);
    //   setIsLoaded(true);
    // }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isLoaded) {
      _load();
    }
  }, []);

  useEffect(() => {
    switch (selectedTabIndex) {
      case 0 : // bookshelf
      case "0" : // bookshelf
      case undefined : // bookshelf
        _loadBook();
        break;
      case 1: // scrap
      case "1": // scrap
        if (!scrapIsLoaded) _loadScrap();
        break;
      default:
        break;
    }
  }, [selectedTabIndex]);

  const _handleSignOut = async () => {
    const a = window.confirm("로그아웃 하시겠습니까?");
    if (!a) return;
    props.signOut();
  };


  const menus = [
    { text: `책장(${user.numberOfRelationUserBooks})`, index: 0 },
    { text: `스크랩(${user.numberOfScraps})`, index: 1 },
  ];

  const subMenus = [
    { text: `전체 (${books.length})`, value: 0, status: "", key: 0 },
    { text: `읽는중 (${_.filter(books, o => o.relationUserBook?.readStatus === "READ_STATUS_ING").length})`, value: 1, status: "READ_STATUS_ING", key: 1 },
    { text: `읽었어요 (${_.filter(books, o => o.relationUserBook?.readStatus === "READ_STATUS_DONE").length})`, value: 2, status: "READ_STATUS_DONE", key: 2 },
    { text: `읽고싶은 (${_.filter(books, o => o.relationUserBook?.readStatus === "READ_STATUS_BEFORE").length})`, value: 3, status: "READ_STATUS_BEFORE", key: 3 },
  ];

  const sortTypes = [
    { text: `등록순`, value: "createdAt", key: 0 },
    { text: `별점 높은 순`, value: `relationUserBook.rate`, key: 1 },
    { text: `이름순`, value: "title", key: 2 },
  ];


  const _renderBookshelfTab = () => {
    return (
      <>
        <Form style={{}}>
          <Form.Group style={{}}>
            <Form.Dropdown
              selection
              label={"보기"}
              options={[
                { key: 0, value: 0, text: "그리드로 보기" },
                { key: 1, value: 1, text: "리스트로 보기" },
              ]}
              value={bookshelfViewTypeId}
              onChange={(e, { value }) => {
                setBookshelfViewTypeId(value);
              }}
            />
            <Form.Dropdown
              selection
              label={"읽음 상태"}
              options={subMenus}
              value={selectedBookTabIndex}
              onChange={(e, { value }) => {
                setSelectedBookTabIndex(value);
              }}
            />
            <Form.Dropdown
              selection
              label={"정렬"}
              options={sortTypes}
              value={orderBy}
              onChange={(e, { value }) => {
                setOrderBy(value);
                setOrderDirection(value === "title" ? "asc" : "desc");
              }}
            />
            <Form.Dropdown
              selection
              label={"리뷰"}
              options={[{ key: 0, value: false, text: "전체보기" }, { key: 1, value: true, text: "리뷰 작성된" }]}
              value={showOnlyReviewed}
              onChange={(e, { value }) => {
                setShowOnlyReviewed(value);
              }}
            />

          </Form.Group>
        </Form>
        {_renderBookshelves()}
      </>
    );
  };
  const _renderBookshelves = () => {
    switch (bookshelfViewTypeId) {
      case 0 :
        return (
          <Card.Group itemsPerRow={5}>
            {
              _.orderBy(showOnlyReviewed ? _.filter(books, o => !!o.relationUserBook?.review) : books, [orderBy], [orderDirection])?.map((b, i) => {
                if (selectedBookTabIndex === 0) {
                  return (
                    <BookComponent book={b}
                                   viewType={"CARD_GRID"}
                                   key={i}
                                   style={{ marginBottom: "1.5rem" }}/>
                  );
                } else {
                  if (b.relationUserBook?.readStatus === subMenus[selectedBookTabIndex].status) {
                    return (
                      <BookComponent book={b}
                                     viewType={"CARD_GRID"}
                                     key={i}
                                     style={{ marginBottom: "1rem" }}/>
                    );
                  } else {
                    return null;
                  }
                }
              })
            }
          </Card.Group>
        );
      case 1:
        return (
          <>
            {
              _.orderBy(showOnlyReviewed ? _.filter(books, o => !!o.relationUserBook?.review) : books, [orderBy], [orderDirection])?.map((b, i) => {

                if (selectedBookTabIndex === 0) {
                  return (
                    <BookComponent book={b}
                                   key={i}
                                   style={{ marginBottom: "1.5rem" }}/>
                  );
                } else {
                  if (b.relationUserBook?.readStatus === subMenus[selectedBookTabIndex].status) {
                    return (
                      <BookComponent book={b}
                                     key={i}
                                     style={{ marginBottom: "1rem" }}/>
                    );
                  } else {
                    return null;
                  }
                }
              })
            }
          </>
        );
    }
  };


  const _renderScrapTab = () => {
    if (!scrapIsLoaded) return (
      <div style={{ textAlign: "center" }}>
        <Loader active inline content={"불러오고 있습니다"}/>
      </div>
    );
    return (
      <>
        <Form>
          <Form.Group>
            <Form.Dropdown
              selection
              label={"보기"}
              options={[
                { key: 0, value: 0, text: "그리드로 보기" },
                { key: 1, value: 1, text: "리스트로 보기" },
              ]}
              value={scrapViewTypeId}
              onChange={(e, { value }) => {
                setScrapViewTypeId(value);
              }}
            />
          </Form.Group>
        </Form>
        {_renderScraps()}
      </>
    );
  };
  const _renderScraps = () => {
    switch (scrapViewTypeId) {
      case 0 : // GRID로 보기
        return (
          <Card.Group itemsPerRow={4}>
            {scraps.map((scrap, i) => {
              return (
                <ScrapComponent key={i} scrap={scrap} viewType={"CARD_GRID"}/>
              );
            })}
          </Card.Group>
        );
      case 1 :
        return (
          <>
            {scraps.map((scrap, i) => {
              return (
                <ScrapComponent key={i} scrap={scrap}/>
              );
            })}
          </>
        );
    }
  };

  const _renderFollowerModal = () => {
    return (
      <Modal
        size={"small"}
        onClose={_handleCloseFollowerModal}
        open={followerModalOpen}>
        <Modal.Content style={{ height: "600px" }} scrolling>
          {
            followers.map((u, i) => {
              return (
                <UserComponent user={u} key={i} showFollowButton/>
              );
            })
          }
        </Modal.Content>
      </Modal>
    );
  };
  const _renderFollowingModal = () => {
    return (
      <Modal
        size={"small"}
        onClose={_handleCloseFollowingModal}
        open={followingModalOpen}>
        <Modal.Content style={{ height: "600px" }} scrolling>
          {
            followings.map((u, i) => {
              return (
                <UserComponent user={u} key={i} showFollowButton/>
              );
            })
          }
        </Modal.Content>
      </Modal>
    );
  };


  return (
    <>
      {_renderFollowerModal()}
      {_renderFollowingModal()}
      <Container as={Segment} vertical style={{}}>
        {/*<Message info>
          <Message.Header content={"업데이트가 지속적으로 진행됩니다."}/>
          <Message.Content>
            PC 버전의 독서공방은 지속적으로 업데이트 될 예정입니다. <br/>
            기능에 문의사항이 있으신 경우, 우측 하단 문의하기를 통해서 알려주세요.
          </Message.Content>
        </Message>*/}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{
            backgroundImage: `url(${user?.photoUrl})`,
            backgroundSize: "150px 150px",
            width: "150px",
            height: "150px",
            borderRadius: "75px",
          }}/>
          <div style={{
            paddingLeft: "1rem",
            // backgroundColor: "red",
            flex: 1,
          }}>
            <Header>
              {user.name}
              <Header.Subheader>
                {user.businessDepartment?.title}
              </Header.Subheader>
            </Header>
            <div style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}>
              <div onClick={_handleOpenFollowerModal}
                   style={{ marginRight: "1rem", cursor: "pointer", color: Colors.buttonColor }}>
                <span style={{ color: "black" }}>팔로워</span> {user.numberOfFollowers}
              </div>
              <div
                onClick={_handleOpenFollowingModal}
                style={{ marginRight: "1rem", cursor: "pointer", color: Colors.buttonColor }}>
                <span style={{ color: "black" }}>팔로잉</span> {user.numberOfFollowings}
              </div>
            </div>
            <Popup
              pinned
              on={"click"}
              position='bottom left'
              hoverable
              wide={"very"}
              trigger={(
                <Button
                  icon={"setting"}
                />
              )}
            >
              <Popup.Content>
                <Button onClick={_handleSignOut}
                        content={"로그아웃"}
                        icon={"logout"}/>
              </Popup.Content>
            </Popup>
          </div>
        </div>
      </Container>

      <Container as={Segment} vertical basic style={{ marginTop: "3em" }}>
        <Header size={"large"}>
          현황
        </Header>
        <Grid>
          <Grid.Row columns={4}>
            <Grid.Column>
              <Card fluid style={{ borderRadius: 0 }}>
                <Card.Content>
                  <Card.Header content={"도서 신청 내역"}/>
                  {props.authReducer?.user?.businessBookRequestStat?.count} 권
                </Card.Content>
                <Card.Content extra>
                  <Button basic content={"자세히보기"} as={Link} to={"/my/book-request"}/>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid style={{ borderRadius: 0 }}>
                <Card.Content>
                  <Card.Header content={"도서 신청권"}/>
                  {props.authReducer?.user?.businessBookVoucher?.left} / {props.authReducer?.user?.businessBookVoucher?.all} 권
                </Card.Content>
                <Card.Content extra>
                  <Button basic content={"자세히보기"} as={Link} to={"/my/voucher"}/>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid style={{ borderRadius: 0 }}>
                <Card.Content>
                  <Card.Header content={"포인트 내역"}/>
                  {numeral(props.authReducer?.user?.businessPoint?.left || 0).format("0,0")} P
                </Card.Content>
                <Card.Content extra>
                  <Button basic content={"자세히보기"} as={Link} to={"/my/point"}/>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid style={{ borderRadius: 0 }}>
                <Card.Content>
                  <Card.Header content={"교육 이수시간"}/>
                  {numeral(props.authReducer?.user?.businessLearningCreditStat?.sum || 0).format("0,0")} 시간
                </Card.Content>
                <Card.Content extra>
                  <Button basic content={"자세히보기"} as={Link} to={`/my/learning-credit`}/>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>


      <Container as={Segment} vertical>
        <Menu secondary>
          {
            menus.map((menu, i) => {
              return (
                <Menu.Item key={i}
                           onClick={() => {
                             props.history.push(`/my?selectedTabIndex=${i}`);
                             // setSelectedTabIndex(i);
                           }}
                           active={( selectedTabIndex === i || selectedTabIndex === i.toString() )}>
                  {menu.text}
                </Menu.Item>
              );
            })
          }
        </Menu>
      </Container>
      <Container as={Segment} vertical>
        {( selectedTabIndex === 0 || !selectedTabIndex || selectedTabIndex === "0" ) && _renderBookshelfTab()}
        {( selectedTabIndex === 1 || selectedTabIndex === "1" ) && _renderScrapTab()}
      </Container>

    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {
    ...authActionCreators,
  },
);

export default enhance(MyPageContainer);
