import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Button, Item, Label} from "semantic-ui-react";
import {Link, useHistory, useParams} from 'react-router-dom'

const BookRequestListItemComponent = (props) => {

  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false)
  const [businessBookRequest, setBusinessBookRequest] = useState(props.businessBookRequest || {})

  useEffect(() => {

  })

  const _handleGoToBusinessBookRequestDetailScreen = () => {
    history.push(`/book-request/${businessBookRequest.id}`)
  }

  const _renderRequestTypeDescription = () => {
    let typeDescription = ''
    switch (businessBookRequest.businessBookRequestTypeId) {
      case 1:
        typeDescription = '도서 신청 바우처 사용';
        break;
      case 2:
        typeDescription = '포인트 사용';
        break;
      default:
        return null;
    }
    return (
      <p>
        신청방법: {typeDescription}
      </p>
    )
  }


  return (
    <Item>
      <Item.Image size={'tiny'} src={businessBookRequest?.book?.coverImageUrl}/>
      <Item.Content>
        <Label content={businessBookRequest?.businessBookRequestStatus?.text}/>
        <p>
          <b>
            {businessBookRequest?.book?.title}
          </b>
        </p>
        <Item.Meta>

        </Item.Meta>
        {
          businessBookRequest?.businessBookRequestStatusId !== 2 && (
            <Item.Extra>
              <Button floated={'right'} primary content={'상세 보기'} onClick={_handleGoToBusinessBookRequestDetailScreen}/>
            </Item.Extra>
          )
        }
      </Item.Content>
    </Item>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BookRequestListItemComponent);
