import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Network_B2B from "../../library/Network_B2B";
import LoaderComponent from "../../component/shared/LoaderComponent";
import { Button, Container, Form, Header, Grid, Menu, Segment, Loader, Modal, Card } from "semantic-ui-react";
import Colors from "../../constant/Colors";
import BookComponent from "../../component/book/BookComponent";
import _ from "lodash";
import moment from "moment";
import ScrapComponent from "../../component/scrap/ScrapComponent";
import { useQuery } from "../../library/useQuery";
import UserComponent from "../../component/user/UserComponent";

const UserDetailContainer = (props) => {
  const { userId } = useParams();
  const { selectedTabIndex } = useQuery(props);
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState({});
  const [fetching, setFetching] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const [bookIsLoaded, setBookIsLoaded] = useState(false);
  const [books, setBooks] = useState([]);
  const [selectedBookTabIndex, setSelectedBookTabIndex] = useState(0);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [showOnlyReviewed, setShowOnlyReviewed] = useState(false);
  const [bookshelfViewTypeId, setBookshelfViewTypeId] = useState(0);

  const [scrapIsLoaded, setScrapIsLoaded] = useState(false);
  const [scraps, setScraps] = useState([]);
  const [endReached, setEndReached] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [perPage, setPerPage] = useState(20);
  const [scrapViewTypeId, setScrapViewTypeId] = useState(0);

  const [followerModalOpen, setFollowerModalOpen] = useState(false);
  const [followers, setFollowers] = useState([]);

  const [followingModalOpen, setFollowingModalOpen] = useState(false);
  const [followings, setFollowings] = useState([]);

  const _handleOpenFollowerModal = () => {
    setFollowerModalOpen(true);
    _loadFollower();
  };
  const _handleCloseFollowerModal = () => {
    setFollowerModalOpen(false);
  };
  const _handleOpenFollowingModal = () => {
    setFollowingModalOpen(true);
    _loadFollowings();
  };
  const _handleCloseFollowingModal = () => {
    setFollowingModalOpen(false);
  };

  const _load = async () => {
    const res = await Network_B2B.getUser(userId);
    if (!res.err) {
      setIsLoaded(true);
      setUser(res.user);
    } else {
      props.history.goBack();
    }
  };
  const _loadBook = async () => {
    const res = await Network_B2B.getBookListUser(userId);
    if (!res.err) {
      setBookIsLoaded(true);
      setBooks(res.books);
    }
  };
  const _loadScrap = async () => {
    const res = await Network_B2B.getScrapListUser(userId, moment().toISOString(), 100);
    if (!res.err) {
      setScrapIsLoaded(true);
      setScraps(res.scraps);
      setRefreshing(false);
      setEndReached(res.scraps?.length < perPage);
    }
  };

  const _loadFollower = async () => {
    const res = await Network_B2B.getFollowFollowerList(userId);
    if (!res.err) {
      setFollowers(res.users);
      // setIsLoaded(true);
    }
  };
  const _loadFollowings = async () => {
    const res = await Network_B2B.getFollowFollowingList(userId);
    if (!res.err) {
      setFollowings(res.users);
      // setIsLoaded(true);
    }
  };

  const _requestFollow = async () => {
    setFetching(true);
    const res = await Network_B2B.postFollow(userId);
    if (!res.err) {
      setUser(prevState => ( { ...prevState, relationFollow: res.relationFollow } ));
    }
    setFetching(false);
  };
  const _requestUnfollow = async () => {
    const a = window.confirm("팔로우를 취소하시겠습니까?");
    if (!a) return;

    setFetching(true);
    const res = await Network_B2B.deleteFollow(userId);
    if (!res.err) {
      setUser(prevState => ( { ...prevState, relationFollow: {} } ));
    }
    setFetching(false);
  };


  useEffect(() => {
    setIsLoaded(false);
    setUser({});
    setBooks([]);
    setBookIsLoaded(false);
    setScrapIsLoaded(false);
    setScraps([]);
    setEndReached(false);

    setFollowingModalOpen(false);
    setFollowerModalOpen(false);

    setFollowers([]);
    setFollowings([]);

    //   const [fetching, setFetching] = useState(false);
    //   const [updating, setUpdating] = useState(false);
    //   const [refreshing, setRefreshing] = useState(false);
    //
    //   const [bookIsLoaded, setBookIsLoaded] = useState(false);
    //   const [books, setBooks] = useState([]);
    //   const [selectedBookTabIndex, setSelectedBookTabIndex] = useState(0);
    //   const [starRange, setStarRange] = useState([0, 5]);
    //   const [orderBy, setOrderBy] = useState("createdAt");
    //   const [orderDirection, setOrderDirection] = useState("desc");
    //
    //   const [scrapIsLoaded, setScrapIsLoaded] = useState(false);
    //   const [scraps, setScraps] = useState([]);
    //   const [endReached, setEndReached] = useState(false);
    //   const [loadingMore, setLoadingMore] = useState(false);
    //   const [perPage, setPerPage] = useState(20);
    //
    //   const [followerModalOpen, setFollowerModalOpen] = useState(false);
    //   const [followers, setFollowers] = useState([]);
    //
    //   const [followingModalOpen, setFollowingModalOpen] = useState(false);
    //   const [followings, setFollowings] = useState([]);


    _load();
    window.scrollTo(0, 0);
  }, [userId]);
  useEffect(() => {
    console.log(1, selectedTabIndex);
    switch (selectedTabIndex) {
      case 0 : // bookshelf
      case "0" : // bookshelf
      case undefined : // bookshelf
        _loadBook();
        break;
      case 1: // scrap
      case "1": // scrap
        if (!scrapIsLoaded) _loadScrap();
        break;
      default:
        break;
    }
  }, [selectedTabIndex]);

  if (!isLoaded) return <LoaderComponent/>;

  const _renderFollowButton = () => {
    if (!!user.relationFollow?.id) {
      if (user.relationFollow.isApproved) {
        return (
          <Button content={"팔로잉"}
                  style={{ marginTop: "1rem" }}
                  loading={fetching}
                  primary
                  basic
                  onClick={_requestUnfollow}
          />
        );
      } else {
        return (
          <Button content={"승인 대기중"}
                  style={{ marginTop: "1rem" }}
                  loading={fetching}
                  primary
                  basic
                  onClick={_requestUnfollow}
          />
        );
      }
    } else {
      return (
        <Button content={"팔로우"}
                style={{ marginTop: "1rem" }}
                loading={fetching}
                primary
                onClick={_requestFollow}
        />
      );
    }
  };

  const _renderFollowerModal = () => {
    return (
      <Modal
        onClose={_handleCloseFollowerModal}
        closeIcon
        size={"small"}
        open={followerModalOpen}>
        <Modal.Content style={{ height: "600px" }} scrolling>
          {
            followers.map((u, i) => {
              return (
                <UserComponent user={u} key={i} showFollowButton/>
              );
            })
          }
        </Modal.Content>
      </Modal>
    );
  };
  const _renderFollowingModal = () => {
    return (
      <Modal
        onClose={_handleCloseFollowingModal}
        closeIcon
        size={"small"}
        open={followingModalOpen}>
        <Modal.Content style={{ height: "50vh" }} scrolling>
          {
            followings.map((u, i) => {
              return (
                <UserComponent user={u} key={i} showFollowButton/>
              );
            })
          }
        </Modal.Content>
      </Modal>
    );
  };

  const subMenus = [
    { text: `전체 (${books.length})`, value: 0, status: "", key: 0 },
    { text: `읽는중 (${_.filter(books, o => o.targetUserRelationUserBook?.readStatus === "READ_STATUS_ING").length})`, value: 1, status: "READ_STATUS_ING", key: 1 },
    { text: `읽었어요 (${_.filter(books, o => o.targetUserRelationUserBook?.readStatus === "READ_STATUS_DONE").length})`, value: 2, status: "READ_STATUS_DONE", key: 2 },
    { text: `읽고싶은 (${_.filter(books, o => o.targetUserRelationUserBook?.readStatus === "READ_STATUS_BEFORE").length})`, value: 3, status: "READ_STATUS_BEFORE", key: 3 },
  ];

  const sortTypes = [
    { text: `등록순`, value: "createdAt", key: 0 },
    { text: `별점 높은 순`, value: `targetUserRelationUserBook.rate`, key: 1 },
    { text: `이름순`, value: "title", key: 2 },
  ];
  const _renderBookshelfTab = () => {

    return (
      <>
        <Form style={{}}>
          <Form.Group style={{}}>
            <Form.Dropdown
              selection
              label={"보기"}
              options={[
                { key: 0, value: 0, text: "그리드로 보기" },
                { key: 1, value: 1, text: "리스트로 보기" },
              ]}
              value={bookshelfViewTypeId}
              onChange={(e, { value }) => {
                setBookshelfViewTypeId(value);
              }}
            />
            <Form.Dropdown
              selection
              label={"읽음 상태"}
              options={subMenus}
              value={selectedBookTabIndex}
              onChange={(e, { value }) => {
                setSelectedBookTabIndex(value);
              }}
            />
            <Form.Dropdown
              selection
              label={"정렬"}
              options={sortTypes}
              value={orderBy}
              onChange={(e, { value }) => {
                setOrderBy(value);
                setOrderDirection(value === "title" ? "asc" : "desc");
              }}
            />
            <Form.Dropdown
              selection
              label={"리뷰"}
              options={[{ key: 0, value: false, text: "전체보기" }, { key: 1, value: true, text: "리뷰 작성된" }]}
              value={showOnlyReviewed}
              onChange={(e, { value }) => {
                setShowOnlyReviewed(value);
              }}
            />

          </Form.Group>
        </Form>
        {_renderBookshelves()}
      </>
    );
  };
  const _renderBookshelves = () => {
    switch (bookshelfViewTypeId) {
      case 0 :
        return (
          <Card.Group itemsPerRow={5}>
            {
              _.orderBy(showOnlyReviewed ? _.filter(books, o => !!o.targetUserRelationUserBook?.review) : books, [orderBy], [orderDirection])?.map((b, i) => {
                if (selectedBookTabIndex === 0) {
                  return (
                    <BookComponent book={b}
                                   viewType={"CARD_GRID"}
                                   key={i}
                                   style={{ marginBottom: "1.5rem" }}/>
                  );
                } else {
                  if (b.targetUserRelationUserBook?.readStatus === subMenus[selectedBookTabIndex].status) {
                    return (
                      <BookComponent book={b}
                                     viewType={"CARD_GRID"}
                                     key={i}
                                     style={{ marginBottom: "1rem" }}/>
                    );
                  } else {
                    return null;
                  }
                }
              })
            }
          </Card.Group>
        );
      case 1:
        return (
          <>
            {
              _.orderBy(showOnlyReviewed ? _.filter(books, o => !!o.targetUserRelationUserBook?.review) : books, [orderBy], [orderDirection])?.map((b, i) => {

                if (selectedBookTabIndex === 0) {
                  return (
                    <BookComponent book={b}
                                   key={i}
                                   style={{ marginBottom: "1.5rem" }}/>
                  );
                } else {
                  if (b.targetUserRelationUserBook?.readStatus === subMenus[selectedBookTabIndex].status) {
                    return (
                      <BookComponent book={b}
                                     key={i}
                                     style={{ marginBottom: "1rem" }}/>
                    );
                  } else {
                    return null;
                  }
                }
              })
            }
          </>
        );
    }
  };

  const _renderScrapTab = () => {
    if (!scrapIsLoaded) return (
      <div style={{ textAlign: "center" }}>
        <Loader active inline content={"불러오고 있습니다"}/>
      </div>
    );
    return (
      <>
        <Form>
          <Form.Group>
            <Form.Dropdown
              selection
              label={"보기"}
              options={[
                { key: 0, value: 0, text: "그리드로 보기" },
                { key: 1, value: 1, text: "리스트로 보기" },
              ]}
              value={scrapViewTypeId}
              onChange={(e, { value }) => {
                setScrapViewTypeId(value);
              }}
            />
          </Form.Group>
        </Form>
        {_renderScraps()}
      </>
    );
  };
  const _renderScraps = () => {
    switch (scrapViewTypeId) {
      case 0 : // GRID로 보기
        return (
          <Card.Group itemsPerRow={4}>
            {scraps.map((scrap, i) => {
              return (
                <ScrapComponent key={i} scrap={scrap} viewType={"CARD_GRID"}/>
              );
            })}
          </Card.Group>
        );
      case 1 :
        return (
          <>
            {scraps.map((scrap, i) => {
              return (
                <ScrapComponent key={i} scrap={scrap}/>
              );
            })}
          </>
        );
    }
  };

  const _renderCalendar = () => {

  };
  const _renderCategoryAnalysis = () => {
  };


  const menus = [
    { text: `책장(${user.numberOfRelationUserBooks})`, index: 0 },
    { text: `스크랩(${user.numberOfScraps})`, index: 1 },
    // { text: `독서달력`, index: 2 },
    // { text: `카테고리분석`, index: 3 },
  ];

  return (
    <>
      {_renderFollowerModal()}
      {_renderFollowingModal()}
      <Container as={Segment} vertical style={{ marginTop: "3rem" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{
            backgroundImage: `url(${user?.photoUrl})`,
            backgroundSize: "150px 150px",
            width: "150px",
            height: "150px",
            borderRadius: "75px",
          }}/>
          <div style={{ paddingLeft: "1rem" }}>
            <Header>
              {user.name}
              <Header.Subheader>
                {user.businessDepartment?.title}
              </Header.Subheader>
            </Header>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div onClick={_handleOpenFollowerModal}
                   style={{ marginRight: "1rem", cursor: "pointer", color: Colors.buttonColor }}>
                <span style={{ color: "black" }}>팔로워</span> {user.numberOfFollowers}
              </div>
              <div
                onClick={_handleOpenFollowingModal}
                style={{ marginRight: "1rem", cursor: "pointer", color: Colors.buttonColor }}>
                <span style={{ color: "black" }}>팔로잉</span> {user.numberOfFollowings}
              </div>
            </div>
            {_renderFollowButton()}
          </div>
        </div>
      </Container>
      <Container as={Segment} vertical>
        <Menu secondary>
          {
            menus.map((menu, i) => {
              return (
                <Menu.Item key={i}
                           onClick={() => {
                             props.history.push(`/user/${userId}?selectedTabIndex=${i}`);
                             // setSelectedTabIndex(i);
                           }}
                           active={( selectedTabIndex === i || selectedTabIndex === i.toString() )}>
                  {menu.text}
                </Menu.Item>
              );
            })
          }
        </Menu>
      </Container>
      <Container as={Segment} vertical>
        {( selectedTabIndex === 0 || !selectedTabIndex || selectedTabIndex === "0" ) && _renderBookshelfTab()}
        {( selectedTabIndex === 1 || selectedTabIndex === "1" ) && _renderScrapTab()}
      </Container>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(UserDetailContainer);
