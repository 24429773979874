import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useQuery} from "../../library/useQuery";
import Network_B2B from "../../library/Network_B2B";
import LoaderComponent from "../../component/shared/LoaderComponent";
import {Button, Container, Item, Message, Pagination, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";

const SearchMainContainer = (props) => {

  const {keyword} = useQuery(props);
  const tabs = [
    {id: 0, text: "책", value: "BOOK"},
    {id: 1, text: "유저", value: "USER"},
    {id: 2, text: "토론회", value: "BUSINESS_BOOK_FORUM"},
  ];
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchedList, setSearchedList] = useState([]);
  const [searching, setSearching] = useState(false);
  const [books, setBooks] = useState([]);
  const [users, setUsers] = useState([]);
  const [businessBookForums, setBusinessBookForums] = useState([]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [keywordsRecommended, setKeywordsRecommended] = useState([]);
  const [keywordsRecent, setKeywordsRecent] = useState([]);

  const [reachEndedBook, setReachEndedBook] = useState(false);
  const [reachEndedUser, setReachEndedUser] = useState(false);
  const [reachEndedForum, setReachEndedForum] = useState(false);

  const searchingMoreBook = React.useRef(false);
  const searchingMoreUser = React.useRef(false);
  const searchingMoreForum = React.useRef(false);

  const perPage = 50;

  useEffect(() => {
    _load();
  }, [keyword])

  const _load = async () => {
    if (!keyword || keyword.trim() === '') {
      setIsLoaded(true);
    } else if (keyword.length < 1) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
      switch (selectedTabIndex) {
        case 0:
          await _searchBook();
          break;
        case 1:
          await _searchUser();
          break;
        case 2:
          await _searchBusinessBookForum();
          break;
        default:
      }
      setIsLoaded(true);
    }
  }

  const _searchBook = async () => {
    if (!keyword || keyword.trim() === "") return;
    if (keyword.length < 2) return window.alert("검색어를 두자 이상 입력해 주세요");
    setSearching(true);
    const res = await Network_B2B.getSearch({keyword, offset: 0, limit: perPage, itemType: "BOOK"});
    if (!res.err) {
      setBooks(res.searchedList);
      setReachEndedBook(res.searchedList?.length < perPage - 5);

    }
    setSearching(false);
  };

  const _searchBookMore = async () => {
    if (searchingMoreBook.current || books.length > 190 || reachEndedBook) return;
    searchingMoreBook.current = true;
    const res = await Network_B2B.getSearch({keyword, offset: books.length, limit: perPage, itemType: "BOOK"});
    if (!res.err) {
      setBooks(prevState => ([...prevState, ...res.searchedList]));
      searchingMoreBook.current = false;
      setReachEndedBook(res.searchedList?.length < perPage - 5);
    }
  };

  const _searchUser = async () => {
    if (!keyword || keyword.trim() === "") return;
    if (keyword.length < 2) return window.alert("검색어를 두자 이상 입력해 주세요");
    setSearching(true);
    const res = await Network_B2B.getSearch({keyword, offset: 0, limit: perPage, itemType: "USER"});
    if (!res.err) {
      setUsers(res.searchedList);
      setReachEndedUser(res.searchedList?.length < perPage - 5);
    }
    setSearching(false);

  };

  const _searchUserMore = async () => {
    if (searchingMoreUser.current || users.length > 190 || reachEndedUser) return;
    searchingMoreUser.current = true;
    const res = await Network_B2B.getSearch({keyword, offset: users.length, limit: perPage, itemType: "USER"});
    if (!res.err) {
      setUsers(prevState => ([...prevState, ...res.searchedList]));
      searchingMoreUser.current = false;
      setReachEndedUser(res.searchedList?.length < perPage - 5);
    }
  };

  const _searchBusinessBookForum = async () => {
    if (!keyword || keyword.trim() === "") return;
    if (keyword.length < 2) return window.alert("검색어를 두자 이상 입력해 주세요");
    setSearching(true);
    const res = await Network_B2B.getSearch({keyword, offset: 0, limit: perPage, itemType: "BUSINESS_BOOK_FORUM"});
    if (!res.err) {
      setBusinessBookForums(res.searchedList);
      setReachEndedForum(res.searchedList?.length < perPage - 5);
    }
    setSearching(false);
  };

  const _searchBusinessBookForumMore = async () => {
    if (searchingMoreForum.current || businessBookForums.length > 190 || reachEndedForum) return;
    searchingMoreBook.current = true;
    const res = await Network_B2B.getSearch({keyword, offset: businessBookForums.length, limit: perPage, itemType: "BUSINESS_BOOK_FORUM"});
    if (!res.err) {
      setBusinessBookForums(prevState => ([...prevState, ...res.searchedList]));
      searchingMoreForum.current = false;
      setReachEndedForum(res.searchedList?.length < perPage - 5);
    }
  };

  if (!isLoaded) return <LoaderComponent/>

  switch (selectedTabIndex) {
    case 0: // BOOK
      return (
        <>
          <div style={{maxWidth: '700px', margin: 'auto'}}>
            <Container as={Segment} vertical>
              <Message>
                <Message.Content>
                  [{keyword}]로 검색한 결과입니다.
                </Message.Content>
              </Message>
              <Item.Group relaxed>
                {
                  books.map((b, i) => {
                    return (
                      <Item key={i}>
                        <Item.Image src={b.coverImageUrl} size={'tiny'}/>
                        <Item.Content>
                          <Item.Header>{b.title}</Item.Header>
                          <Item.Meta>{b.author}</Item.Meta>
                          <Item.Meta>{b.publisher} ({b.pubDate})</Item.Meta>
                          <Item.Extra>
                            <Button floated={'right'} content={'보기'} primary basic as={Link} to={`/book/${b.id}`}/>
                          </Item.Extra>
                        </Item.Content>
                      </Item>
                    )
                  })
                }
              </Item.Group>
            </Container>
          </div>
        </>
      )
    case 1: // 유저
      return (
        <div>
          <Container>
          </Container>
        </div>
      )
    case 2: // 독서 토론회
      return (
        <div>
          <Container>
          </Container>
        </div>
      )
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(SearchMainContainer);
