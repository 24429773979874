import { createLogic } from "redux-logic";
import { authActionCreators, authActions } from "./authStore";
import Network_B2B from "../../library/Network_B2B";
import { auth } from "../../constant/firebase";

// 현재 안쓰고 있음
const appInitializeLogic = createLogic({
  type: authActions.APP_INITIALIZE,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    done();

  },
});

const fetchMyInfoInitLogic = createLogic({
  type: authActions.FETCH_MY_INFO_INIT,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const res = await Network_B2B.getUserMyInit();
    if (!res.err) {
      dispatch(authActionCreators.fetchMyInfoInitSuccess(res.user, res.additionalInfoNeeded));
      dispatch(authActionCreators.fetchBusinessBookRequestIngSuccess(res.businessBookRequestIng));
      action?.cb?.(res.user);
    }
    done();
  },
});
const fetchMyInfoLogic = createLogic({
  type: authActions.FETCH_MY_INFO,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const res = await Network_B2B.getUserMy();
    if (!res.err) {
      console.log(res);
      dispatch(authActionCreators.fetchMyInfoSuccess(res.user));
    }
    action?.cb?.();
    done();
  },
});
const completeAdditionalInfoLogic = createLogic({
  type: authActions.COMPLETE_ADDITION_INFO,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const res = await Network_B2B.postBusinessMemberInitialInfo();
    done();
  },
});

const signInLogic = createLogic({
  type: authActions.SIGN_IN,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {

    const res = await Network_B2B.signInWithSignInIdAndPassword(action.signInId, action.password, action.businessId);
    // TODO 로그인 처리 하고,
    if (!res.err) {
      // TODO 성공하였을 때,
      auth.signInWithCustomToken(res.firebaseToken)
        .then(user => {
          console.log({ user });
        })
        .catch(e => {
          console.log({ e });
        });

      dispatch(authActionCreators.signInSuccess(res.user));
    } else {
      // TODO 로그인 실패하였을 때, 처리
      dispatch(authActionCreators.signInFailure("비밀번호가 잘못되었습니다."));
    }
    action?.cb?.();
    done();

  },
});
const signOutLogic = createLogic({
  type: authActions.SIGN_OUT,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {

    auth.signOut()
      .then(() => {
        Network_B2B.setFirebaseUid(null);
        // Network_READERS.setFirebaseUid(null);
        done();
      })
      .catch(() => {
        done();
      });

  },
});

const fetchBusinessNotificationsLogic = createLogic({
  type: authActions.FETCH_BUSINESS_NOTIFICATION,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const res = await Network_B2B.getBusinessNotification();
    if (!res.err) {
      dispatch(authActionCreators.fetchBusinessNotificationsSuccess(res.businessNotifications));
      action?.cb?.();
    }
    done();
  },
});
const fetchBusinessBookRequestIngLogic = createLogic({
  type: authActions.FETCH_BUSINESS_BOOK_REQUEST_ING,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const res = await Network_B2B.getBusinessBookRequestIngMy();
    if (!res.err) {
      dispatch(authActionCreators.fetchBusinessBookRequestIngSuccess(res.businessBookRequestIng));
    }
    action?.cb?.();
    done();
  },
});
const fetchMyFollowerLogic = createLogic({
  type: authActions.FETCH_MY_FOLLOWER,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const { user } = getState().authReducer;
    const { err, users } = await Network_B2B.getFollowFollowerList(user.id);
    if (!err) {
      dispatch(authActionCreators.fetchMyFollowerSuccess(users));
    }
    action?.cb?.();
    done();

  },
});
const fetchMyFollowingLogic = createLogic({
  type: authActions.FETCH_MY_FOLLOWING,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const { user } = getState().authReducer;
    const { err, users } = await Network_B2B.getFollowFollowingList(user.id);
    if (!err) {
      dispatch(authActionCreators.fetchMyFollowingSuccess(users));
    }
    action?.cb?.();
    done();
  },
});


const fetchMyBookLogic = createLogic({
  type: authActions.FETCH_MY_BOOK,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const { user } = getState().authReducer;
    const { err, books } = await Network_B2B.getBookListMy();
    if (!err) {
      dispatch(authActionCreators.fetchMyBookSuccess(books));
    }
    action?.cb?.();
    done();
  },
});


export default [
  appInitializeLogic,
  completeAdditionalInfoLogic,
  signInLogic,
  signOutLogic,
  fetchBusinessNotificationsLogic,
  fetchMyInfoLogic,
  fetchMyInfoInitLogic,
  fetchMyFollowerLogic,
  fetchMyFollowingLogic,
  fetchMyBookLogic,
  fetchBusinessBookRequestIngLogic,
];
