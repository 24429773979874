import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Card, Container, Grid, Message, Segment} from "semantic-ui-react";
import MyPointComponent from "../../component/my-point/MyPointComponent";
import numeral from "numeral";
import Network_B2B from "../../library/Network_B2B";
import LoaderComponent from "../../component/shared/LoaderComponent";

const MyPageLearningCreditContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [businessLearningCredits, setBusinessLearningCredits] = useState([]);
  const [endReached, setEndReached] = useState(false);
  const perPage = 50;

  const [businessLearningCreditTypes, setBusinessLearningCreditTypes] = useState([]);


  const _loadTypes = async () => {
    const res = await Network_B2B.getBusinessLearningCreditTypeList();
    if (!res.err) {
      setBusinessLearningCreditTypes(res.businessLearningCreditTypes);
    }
  };

  const _load = async () => {
    const res = await Network_B2B.getBusinessLearningCreditListMy(0, perPage);
    if (!res.err) {
      setRefreshing(false);
      setIsLoaded(true);
      setBusinessLearningCredits(res.businessLearningCredits);
      setEndReached(res.businessLearningCredits.length < perPage);
    }
  };

  const _loadMore = async () => {
    if (loadingMore || endReached || businessLearningCredits.length < perPage) return;

    setLoadingMore(true);
    const res = await Network_B2B.getBusinessLearningCreditListMy(businessLearningCredits.length, perPage);
    if (!res.err) {
      setLoadingMore(true);
      setBusinessLearningCredits(prevState => [...prevState, ...res.businessLearningCredits]);
      setEndReached(res.businessLearningCredits.length < perPage);
    }
  };

  useEffect(() => {
    _load();
    _loadTypes();
  }, [])

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} veritcal>
        <Grid>
          <Grid.Row>
            <Grid.Column width={11}>

            </Grid.Column>
            <Grid.Column width={5}>
              <Card fluid style={{borderRadius: 0,}}>
                <Card.Content>
                  <Card.Header content={'나의 교육 이수'}/>
                </Card.Content>
                <Card.Content extra>
                  {numeral(props.authReducer?.user?.businessLearningCreditStat?.sum || 0).format("0,0")} 시간
                </Card.Content>
              </Card>
              <Message style={{borderRadius: 0,}}>
                <Message.Header content={'교육시간 이수 기준'}/>
                <Message.Content>
                  <Message.List>
                    {
                      businessLearningCreditTypes.map((item, i) => {
                        return (
                          <Message.Item key={i}>
                            ({item.unit} 시간)
                          </Message.Item>
                        )
                      })
                    }
                  </Message.List>
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(MyPageLearningCreditContainer);
