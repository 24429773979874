import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useHistory} from "react-router-dom";
import {Button, Card, Icon, Item, Label} from "semantic-ui-react";
import moment from "moment";

const BusinessBookForumComponent = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [businessBookForum, setBusinessBookForum] = useState(props.businessBookForum || {});

  const history = useHistory();

  useEffect(() => {
    setBusinessBookForum(props.businessBookForum);
  }, [props.businessBookForum?.id]);

  const _handleGoToDetailScreen = () => {
    history.push(`/forum/${businessBookForum?.id}`)
    // navigation.push("BusinessBookForumDetailScreen", {
    //   businessBookForumId: businessBookForum.id,
    // });
  };

  return (
    <Item>
      <Item.Image size={'tiny'} src={businessBookForum?.book?.coverImageUrl}/>
      <Item.Content>
        <Item.Header>
          {businessBookForum.title}
        </Item.Header>
        <Item.Description>
          일시: {moment(businessBookForum.datetime).format("YYYY.MM.DD(ddd) HH:mm")} <br/>
          장소: {businessBookForum.locationDescription} <br/>
          개최자: {businessBookForum?.hostUser?.name} ({businessBookForum?.hostUser?.businessDepartment?.title}) <br/>
          인원: {businessBookForum?.statOfBusinessBookForumMember?.count} 명
        </Item.Description>
        <Item.Extra>
          <Label content={'참여중'} style={{}}/>
          <Button
            floated={'right'}
            primary
            basic
            onClick={_handleGoToDetailScreen}>
            자세히 보기 <Icon name={'chevron right'}/>
          </Button>
        </Item.Extra>

      </Item.Content>
    </Item>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BusinessBookForumComponent);
