import BookDetailContainers from "../container/book/BookDetailContainers";
import BookForumMainContainer from "../container/book-forum/BookForumMainContainer";
import FeedMainContainer from "../container/feed/FeedMainContainer";
import HomeContainer from "../container/home/HomeContainer";
import SearchMainContainer from "../container/search/SearchMainContainer";
import UserMainContainer from "../container/user/UserMainContainer";
import BookRequestContainer from "../container/book-request/BookRequestContainer";
import MyPageContainer from "../container/my-page/MyPageContainer";
import BookForRequestContainer from "../container/book/BookForRequestContainer";
import BookRequestListContainer from "../container/book-request/BookRequestListContainer";
import BookMainContainer from "../container/book/BookMainContainer";
import HelpManualContainer from "../container/help/HelpManualContainer";
import BookCategoryGroupDetailContainer from "../container/book-category/BookCategoryGroupDetailContainer";
import EventOpenDetailContainer from "../container/event/EventOpenDetailContainer";
import UserDetailContainer from "../container/user/UserDetailContainer";
import TestContainer from "../container/0-test/TestContainer";
import MyPagePointContainer from "../container/my-page/MyPagePointContainer";
import MyPageLearningCreditContainer from "../container/my-page/MyPageLearningCreditContainer";
import ScrapDetailContainer from "../container/scrap/ScrapDetailContainer";
import MyPageVoucherContainer from "../container/my-page/MyPageVoucherContainer";
import BookForumDetailContainer from "../container/book-forum/BookForumDetailContainer";
import BookForumMakeContainer from "../container/book-forum/BookForumMakeContainer";
import NoticeMainContainer from "../container/notice/NoticeMainContainer";
import RecommendationUserContainer from "../container/recommendation/RecommendationUserContainer";
import RecommendationBookContainer from "../container/recommendation/RecommendationBookContainer";
import NoticeDetailContainer from "../container/notice/NoticeDetailContainer";
import TestContainer2 from "../container/0-test/TestContainer2";
import EventHashTagDetailContainer from "../container/event/EventHashTagDetailContainer";


export const privateRouterList = [
  {path: '/', component: HomeContainer},
  {path: '/book-category/group/:bookCategoryGroupId', component: BookCategoryGroupDetailContainer},
  {path: '/book-request/:bookRequestId', component: BookRequestContainer},
  {path: '/book-request', component: BookRequestListContainer},
  {path: '/book', component: BookMainContainer},
  {path: '/book/for-request', component: BookForRequestContainer},
  {path: '/book/:bookId', component: BookDetailContainers},

  {path: '/event/hash-tag', component: EventHashTagDetailContainer},
  {path: '/event/:eventId/:userId', component: EventOpenDetailContainer},

  {path: '/forum/make', component: BookForumMakeContainer},
  {path: '/forum/:businessBookForumId', component: BookForumDetailContainer},
  {path: '/forum', component: BookForumMainContainer},

  {path: '/help/manual', component: HelpManualContainer},

  {path: '/feed', component: FeedMainContainer},

  // {path: '/my/book', component: MyPageBookContainer},
  // {path: '/my/bookmark', component: MyPageBookmarkContainer},
  // {path: '/my/scrap', component: MyPageScrapContainer},

  {path: '/my/learning-credit', component: MyPageLearningCreditContainer},
  {path: '/my/point', component: MyPagePointContainer},
  {path: '/my/voucher', component: MyPageVoucherContainer},
  {path: '/my/book-request', component: BookRequestListContainer},
  {path: '/my', component: MyPageContainer},

  {path: '/notice/:noticeId', component: NoticeDetailContainer},
  {path: '/notice', component: NoticeMainContainer},

  {path: '/recommendation/book', component: RecommendationBookContainer},
  {path: '/recommendation/user', component: RecommendationUserContainer},

  {path: '/scrap/:scrapId', component: ScrapDetailContainer},

  {path: '/search', component: SearchMainContainer},

  // {path: '/test', component: TestContainer},
  {path: '/test', component: TestContainer2},

  {path: '/user', component: UserMainContainer},
  {path: '/user/:userId', component: UserDetailContainer},

]
