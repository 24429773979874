import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Statistic } from "semantic-ui-react";

const BusinessLearningCreditComponent = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});

  useEffect(() => {

  });


  if (!props.authReducer?.isLoggedIn) return null;
  const {
    sum,
  } = props.authReducer?.user?.businessLearningCreditStat;

  return (
    <div>
      <p>
        사내 교육 시간 {sum||0} 시간
      </p>
    </div>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessLearningCreditComponent);
