import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from "../../component/shared/LoaderComponent";
import Network_B2B from "../../library/Network_B2B";
import {Button, Card, Container, Grid, Header, Icon, Image, Item, Menu, Message, Segment} from "semantic-ui-react";
import moment from "moment";
import {Link} from "react-router-dom";
import BusinessBookForumComponent from "../../component/business-book-forum/BusinessBookForumComponent";

const BookForumMainContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [banners, setBanners] = useState([]);
  const [businessBookForumsAvailable, setBusinessBookForumsAvailable] = useState([]);
  const [businessBookForumsMy, setBusinessBookForumsMy] = useState([]);
  const [meta, setMeta] = useState({});


  const _load = async () => {
    const res = await Network_B2B.getBusinessBookForumMain();
    if (!res.err) {
      setIsLoaded(true);
      setBanners([{}, {}]);
      setBusinessBookForumsAvailable(res.businessBookForumsAvailable || []);
      setBusinessBookForumsMy(res.businessBookForumsMy || []);
      setMeta(res.meta || {});
      setRefreshing(false);
    }
  };

  const _handleGoToDetail = (businessBookForumId) => {
    props.history.push(`/book-forum/${businessBookForumId}`)
  }

  useEffect(() => {
    _load();
  }, [])


  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic text>
        {/*<Header content={'독서 토론회'} size={'large'}/>*/}
        <Button content={'토론회 만들기'} as={Link} to={'/forum/make'} basic icon={'plus'} primary/>
      </Container>
      <Container as={Segment} vertical basic text>
        <Menu secondary>
          <Menu.Item
            active={selectedTabIndex === 0}
            onClick={() => setSelectedTabIndex(0)}
          >
            내 토론회
          </Menu.Item>
          <Menu.Item
            active={selectedTabIndex === 1}
            onClick={() => setSelectedTabIndex(1)}
          >
            전체 토론회
          </Menu.Item>
        </Menu>
      </Container>
      <Container as={Segment} vertical text>
        <Item.Group>
          {businessBookForumsMy?.map((item, index) => {
            return (
              <BusinessBookForumComponent businessBookForum={item} key={index}/>
            )
          })}
        </Item.Group>
      </Container>

    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BookForumMainContainer);
