import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Button, Card, Container, Divider, Grid, GridRow, Header, Icon, Image, Segment} from "semantic-ui-react";
import {authActionCreators} from "../../redux/auth/authStore";
import {Link} from "react-router-dom";
import _ from 'lodash'
import mockUpImages from "../../constant/mockUpImages";
import Network_B2B from "../../library/Network_B2B";
import Truncate from 'react-truncate';
import LinesEllipsis from 'react-lines-ellipsis';
import BookRequestIngComponent from "../../component/book-request/BookRequestIngComponent";
import {homeActionCreator} from "../../redux/home/homeStore";
import MyPointComponent from "../../component/my-point/MyPointComponent";

const HomeContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  useEffect(() => {
    props.fetchBusinessBookRequestIng();
    props.fetchHome();
    window.scrollTo(0, 0)
  }, [])


  const {businessNotices, bookCategoryGroups, businessBanners, businessBookRecommendationCollectionByCompany} = props.homeReducer;

  return (
    <>
      <Segment vertical style={{minHeight: '300px', backgroundColor: '#363636', paddingTop: '100px'}} basic>
        <Container>
          <Header style={{fontWeight: '900',}} size={'huge'} inverted>
            교보생명 독서공방 (讀書共房)
            <Header.Subheader>
              독서를 통해 얻은 지식 및 인사이트 등의 공유, <br/>
              토론을 통한 상호 학습의 기회를 제공하고, <br/>
              새로운 아이디어를 만들어 내는 지식 공간 입니다. <br/>
            </Header.Subheader>
          </Header>

          <Button content={'사용 안내 보기'} inverted as={Link} to={'/help/manual'}/>

          {/*<Button content={'이벤트 보기'} inverted as={Link} to={`/event/1/${props.authReducer.user?.id}`}/>*/}
        </Container>
      </Segment>
      <Container as={Segment} vertical basic style={{marginTop: '3em'}}>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header size={'large'}>
                나의 도서 신청 내역
              </Header>
              <Card fluid style={{borderRadius: 0}}>
                <Card.Content>
                  <BookRequestIngComponent/>
                </Card.Content>
                <Card.Content extra>
                  <Button basic as={Link} to={'/book-request'}>
                    전체 보기 <Icon name={'chevron right'}/>
                  </Button>
                </Card.Content>
              </Card>
              <Divider/>
              <Card fluid style={{borderRadius: 0}}>
                <Card.Content>
                  <Card.Header content={'포인트'}/>
                  <MyPointComponent/>
                </Card.Content>
                <Card.Content>
                  <Card.Header content={'도서신청권'}/>
                  <Card.Meta>
                    사용가능: {props.authReducer?.user?.businessBookVoucher?.left}권 <br/>
                    총: {props.authReducer?.user?.businessBookVoucher?.all}권
                  </Card.Meta>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Container as={Segment} vertical basic style={{marginTop: '3em'}}>
        <Header content={businessBookRecommendationCollectionByCompany?.title} size={'large'}/>
        <Grid stackable doubling>
          <Grid.Row columns={5}>
            {
              businessBookRecommendationCollectionByCompany?.books?.slice(0, 5).map((o, i) => {
                return (
                  <Grid.Column key={i}>
                    <Card fluid as={Link} to={`/book/${o.id}`}>
                      <Card.Content textAlign={'center'}>
                        <Image src={o.coverImageUrl} size={'tiny'}/>
                      </Card.Content>
                      <Card.Content>
                        <LinesEllipsis
                          maxLine={2}
                          basedOn='letters'
                          text={o.title}
                        />
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                )
              })
            }
          </Grid.Row>
          <Grid.Row columns={5}>
            {
              businessBookRecommendationCollectionByCompany?.books?.slice(5, 10).map((o, i) => {
                return (
                  <Grid.Column key={i}>
                    <Card fluid as={Link} to={`/book/${o.id}`}>
                      <Card.Content textAlign={'center'}>
                        <Image src={o.coverImageUrl} size={'tiny'}/>
                      </Card.Content>
                      <Card.Content>
                        <LinesEllipsis
                          maxLine={2}
                          basedOn='letters'
                          text={o.title}
                        />
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                )
              })
            }
          </Grid.Row>
        </Grid>
      </Container>

      <Container as={Segment} vertical basic style={{marginTop: '3em'}}>
        <Header size={'large'}>
          추천
          <Header.Subheader>

          </Header.Subheader>
        </Header>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid style={{borderRadius:0}} as={Link} to={'/recommendation/book'}>
                <Card.Content>
                  <Card.Header content={'추천 도서'}/>
                  <Card.Description>
                    {props.authReducer?.user?.name}님께 맞는 책을 찾아볼까요?
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid style={{borderRadius:0}} as={Link} to={'/recommendation/user'}>
                <Card.Content>
                  <Card.Header content={'추천 유저'}/>
                  <Card.Description>
                    {props.authReducer?.user?.name}님,다른 사람들은 어떤 책을 볼까요?
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Container as={Segment} vertical basic style={{marginTop: '3em'}}>
        <Header size={'large'}>
          카테고리
          <Header.Subheader>
            카테고리별 베스트셀러를 소개합니다.
          </Header.Subheader>
        </Header>
        <Grid stackable doubling>
          <Grid.Row columns={7}>
            {
              bookCategoryGroups?.map((o, i) => {
                return (
                  <Grid.Column key={i}>
                    <Card fluid as={Link} to={`/book-category/group/${o.id}`}>
                      <Card.Content textAlign={'center'} style={{backgroundColor: o.color}}>
                        <Image src={o.characterProfileImageUrl} size={'tiny'}/>
                      </Card.Content>
                      <Card.Content style={{textAlign: 'center'}}>
                        {o.name}
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                )
              })
            }
          </Grid.Row>
        </Grid>
      </Container>
      {/*<Container as={Segment} vertical basic style={{marginTop: '3em'}}>
        <Header content={'추천 유저'} size={'large'}/>
      </Container>*/}
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
    ...homeActionCreator,
  }
);

export default enhance(HomeContainer);
