import { combineReducers } from "redux";
import { authReducer } from "./auth/authStore";
import { homeReducer } from "./home/homeStore";
import { feedReducer } from "./feed/feedStore";

const reducers = combineReducers({
  authReducer: authReducer,
  homeReducer: homeReducer,
  feedReducer: feedReducer,
});

const rootReducer = (state, action) => {
  console.log(action.type);
  return reducers(state, action);
};

export default rootReducer;
